import { Card } from "antd";
import { AspectRatio, Box, Center, HStack, Image } from "native-base";
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import GalleryAntdCard from "./antd_card";

const GalleryContent = (props) => {
  const { groupValues, cms_key } = props;
  useEffect(() => {}, [cms_key]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div style={{ position: "relative" }}>
      <Carousel responsive={responsive}>
        {groupValues?.[cms_key]?.["items"]?.map((item, index) => {
          return (
            <div>
              <GalleryAntdCard item={item}  groupValues={groupValues}/>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
export default GalleryContent;
