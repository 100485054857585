import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_system_cms_field_query } from "./graphql";
const initialState = {
    items: [],
    system_cms_id:null,
};
const slice = createSlice({
    name: "systemCMSFieldUserList",
    initialState,
    reducers: {
        _get_system_cms_user_field: (state, { payload }) => {
            // Add or Update Key
            let index = state.items.findIndex(
                (e) => e.key === payload?.json?.system_cms_key
            );
            if (index != -1) {
                state.items[index].loading = true;
                state.items[index].status = null;
            } else {
                state.items.push({
                    key: payload?.json?.system_cms_key,
                    loading: true,
                    status: null,
                });
            }
        },
        _get_system_cms_user_field_success: (state, { payload }) => {
            // Add or Update Field Value for Key
            let index = state.items.findIndex(
                (e) => e.key === payload?.system_cms_key
            );
            if (index != -1) {
                state.items[index].loading = false;
                state.items[index].field_items = payload.items;
                state.items[index].error = null;
            }
            state.system_cms_id = payload.items?.[0]?.system_cms?.id;

        },
        _get_system_cms_user_field_failure: (state, { payload }) => {
            let index = state.items.findIndex(
                (e) => e.key === payload?.system_cms_key
            );
            if (index != -1) {
                state.items[index].loading = false;
                state.items[index].error = payload.message;
            }
        },
    },
});
const {
    _get_system_cms_user_field,
    _get_system_cms_user_field_success,
    _get_system_cms_user_field_failure,
} = slice.actions;
export const getSystemCMSFieldUserListSelector = (state) =>
    state.systemCMSFieldUserList;
export const getSystemCMSFieldUserListReducer = slice.reducer;
export function get_system_cms_user_field_request(variables) {
    return async (dispatch) => {
        dispatch(_get_system_cms_user_field(variables));
        try {
            const response = await QueryRequest(
                get_system_cms_field_query,
                variables,
                dispatch
            );
            if (
                response?.data?.getSystemCmsFields &&
                !response?.data?.getSystemCmsFields.error
            ) {
                dispatch(
                    _get_system_cms_user_field_success({
                        ...response?.data?.getSystemCmsFields,
                        system_cms_key: variables.json.system_cms_key,
                    })
                );
            } else if (response?.data?.getSystemCmsFields?.error) {
                dispatch(
                    _get_system_cms_user_field_failure({
                        ...response?.data?.getSystemCmsFields.error,
                        system_cms_key: variables.json.system_cms_key,
                    })
                );
            }
        } catch (error) {
            dispatch(_get_system_cms_user_field_failure(error));
        }
    };
}
