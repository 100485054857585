import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, VStack, HStack, Center, Pressable, Text} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import {useTranslation} from "react-i18next";
import {
  getSystemCMSFieldUserListSelector,
  get_system_cms_user_field_request,
} from "@services/redux";

const FunctioningPatternOfOtherUnitsTable = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    let current_language_id = localStorage.getItem("current_language_id");
    const [data,setData]=useState([]);
    const [query,setQuery]=useState({});
    const [loading,setLoading]=useState(false);
    const { items } = useSelector(getSystemCMSFieldUserListSelector);
   const columns=[{
    header: t("table:units"),
    dataIndex: "units.value",
    key: "units",
    align: "left",
    type: "label",
    fleX: 1,
},
{
    header: t("table:headed_by"),
    dataIndex: "headed_by.value",
    key: "headed_by",
    align: "left",
    type: "label",
    fleX: 1,
},
{
    header: t("table:controlling_authority"),
    dataIndex: "controlling_authority.value",
    key: "controlling_authority",
    align: "left",
    type: "label",
    fleX: 1,
},
]
   useEffect(()=>{
    if(items.length){
        let cms = items.find((e) => e.key ===  " Functioning_pattern_of_other_units");
        if(cms?.field_items?.length)
       {
        let items=cms.field_items

         let highest_value = 0;
      let object_keys = items.map((field_item) => {
        highest_value =
          field_item.system_cms_values?.length > highest_value
            ? field_item.system_cms_values?.length
            : highest_value;
        return field_item.key;
      });
      let final_list = [];
      for (let index = 0; index < highest_value; index++) {
        let object = {};
        for (let key in object_keys) {
          let value = items
            .find((filter_item) => filter_item.key === object_keys[key])
            .system_cms_values.find(
              (find_item) => find_item.row_number === index
            );
          object[object_keys[key]] = value;
        }
        final_list.push(object);
      }
      setData(final_list);}
      if(cms?.loading)
      setLoading(cms.loading)
    }
   },[items])
   useEffect(()=>{
    setQuery({
        json: {
            system_cms_key: " Functioning_pattern_of_other_units",
        },
    })
   },[])
    return (<>
    <Box>
    <Wrapper>
        <Table
            width="100%"
            rowKey="id"
            group="functioning_pattern_of_other_units"
            loading={loading}
            data={data}
            columns={columns}
            hasPagination={false}
            totalItems={null}
            fetch={get_system_cms_user_field_request}
            query={query}
            headerActions={[]}
            emptyMessage={t("table:empty_page")}
            headerColor={"white"}
        />
    </Wrapper>
    </Box>
    </>);
};
export default FunctioningPatternOfOtherUnitsTable;

