import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  Card,
} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Loader from "@views/components/ui/loader";
import NoData from "@views/components/ui/no_data";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const RecentGOsCardItem = (props) => {
  const dispatch = useDispatch();
  const { item } = props;
  const { t } = useTranslation();
  return (
    <>
      <Box>
        <Card height={["auto", "auto", "150px"]} justifyContent={"center"}>
          <HStack space="20">
            <Box width="70%">
              <VStack>
                <Box>
                  <Text bold> {item.tittle?.value}</Text>
                </Box>
                <Box>
                  <Text>{item.description?.value}</Text>
                </Box>
              </VStack>
            </Box>
            <Box justifyContent={"flex-end"}>
              <a href={item?.file?.value} target={"_blank"}>
                <AiOutlineFilePdf size="30" color="red" />
              </a>
            </Box>
          </HStack>
        </Card>
      </Box>
    </>
  );
};
export default RecentGOsCardItem;
