import React, { useState } from "react";
import { ROUTES } from "@views/routes/my_routes";
import { Box, Center, Divider, Image, Text, VStack } from "native-base";
import MenuSiderImage from "@assets/images/menu/secondary-menu.png";
import SecondarySiderMenuItem from "./secondary_sider_menu_item";
const Publicinformationmenu = () => {
  const [selected_index, setSelectedIndex] = useState(0);

  let menu_list = [
    { name: "Important G.Os", route: ROUTES.G_OS_FOR_TANII },
    { name: "List Of Biofertilizer Centres", route: ROUTES.LIST_OF_BIOFERTILIZER_CENTRES },
    { name: "List Of Central Nurseries In The State", route: ROUTES.LIST_OF_CENTRAL_NURSERIES_IN_THE_STATE },
    { name: "Manuals For Online Submissions", route: ROUTES.MANUALS_FOR_ONLINE_SUBMISSIONS },
    { name: "Guidelines For Sawmills", route: ROUTES.GUIDELINES_FOR_SAWMILLS },
    { name: "Departmental Books", route: ROUTES.DEPARTMENTAL_BOOKS },
    { name: "Forest Fire Alert", route: ROUTES.ACTS },
    { name: "Sale of Seedings,Seeds etc.", route: ROUTES.SALE_OF_SEEDLINGS },
    { name: "Eco-tourism Sites", route: ROUTES.ECO_TOURISM_SITES },
  ];
  const RenderSecondaryMenu = () => {
    let menu = menu_list.map((item, index) => {
      return (
        <SecondarySiderMenuItem
          item={item}
          index={index}
          selected_index={selected_index}
          setSelectedIndex={setSelectedIndex}
        />
      );
    });
    return menu;
  };

  return (
    <Box
      w="60%"
      h="11%"
      bg="#f9faf2"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
      }}
    >
      <Box h="100%">
        <img src={MenuSiderImage} alt="Menu Image" height="450px" />
        <VStack position={"absolute"} width="100%" h="100%">
          <Box p="3">
            <Text fontSize="20px" fontWeight="bolder" color={"#3c5649"}>
              {"Public Information"}
            </Text>
          </Box>
          <Center>
            <Divider width={"90%"} bg={"#d1e1c7"} />
          </Center>
          {RenderSecondaryMenu()}
        </VStack>
      </Box>
    </Box>
  );
};

export default Publicinformationmenu;
