import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  PresenceTransition,
} from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import MisHTML from "./components/mis";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import HeaderSection from "@views/components/ui/header_section";
import computer from "@src/assets/images/it/techsupport-green-2x.png";
import Webdev from "@src/assets/images/it/webdev-green-2x.png";
import Software from "@src/assets/images/it/software-green-2x.png";
import video from "@src/assets/images/it/videocall-green-2x.png";
import VideoConferencing from "../video_conferencing/video_conferencing";
import SoftwareDevelopmentAndMaintenance from "../software_development_and_maintenance/software_development_and_maintenance";
import WebsiteDevelopmentAndMaintenance from "../website_development_and_maintenance/website_development_and_maintenance";
import ComputerizationAndMaintenance from "../computerization_and_maintenance/computerization_and_maintenance";
const Mis = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showcm, setshowcm] = useState(false);
  const [showweb, setshowweb] = useState(false);
  const [showsoft, setshowsoft] = useState(false);
  const [showvideo, setshowvideo] = useState(false);
  const showcardcm = () => {
    setshowcm(true);
    setshowweb(false);
    setshowsoft(false);
    setshowvideo(false);
  };
  const showcardweb = () => {
    setshowcm(false);
    setshowweb(true);
    setshowsoft(false);
    setshowvideo(false);
  };
  const showcardsoft = () => {
    setshowcm(false);
    setshowweb(false);
    setshowsoft(true);
    setshowvideo(false);
  };
  const showcardvideo = () => {
    setshowcm(false);
    setshowweb(false);
    setshowsoft(false);
    setshowvideo(true);
  };
  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection
            imageUrl={ForestImageCover}
            title={t("management_information_system")}
          />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="organization" />
                </Box>
              </VStack>
            </Box>
            <Box w={["100%", "100%", "65%"]} h="100%">
              <Box>
                <MisHTML />
              </Box>
              <HStack
                justifyContent={"center"}
                w="100%"
                flexDirection={{
                  base: "column",
                  md: "row",
                }}
              >
                <Pressable w={["100%","100%","15%"]} onPress={showcardcm}>
                  <Box w="100%" alignItems={"center"}>
                    <Box h="60%">
                      <img src={computer} width="60px" height="60px"></img>
                    </Box>
                    <Box h="40%">
                      <Text textAlign={"center"}>
                        Computerised
                        <br />
                        Maintenance
                      </Text>
                    </Box>
                  </Box>
                </Pressable>
                <Pressable w={["100%","100%","15%"]}  onPress={showcardweb}>
                  <Box w="100%" alignItems={"center"}>
                    <Box h="60%">
                      <img src={Webdev} width="60px" height="60px"></img>
                    </Box>
                    <Box h="40%">
                      <Text textAlign={"center"}>
                        Website Dev and <br />
                        Maintenance
                      </Text>
                    </Box>
                  </Box>
                </Pressable>
                <Pressable w={["100%","100%","15%"]}  onPress={showcardsoft}>
                  <Box w="100%" alignItems={"center"}>
                    <Box h="60%">
                      <img src={Software} width="60px" height="60px"></img>
                    </Box>
                    <Box h="40%">
                      <Text textAlign={"center"}>
                        Software Dev and <br />
                        Maintenance
                      </Text>
                    </Box>
                  </Box>
                </Pressable>
                <Pressable w={["100%","100%","15%"]}  onPress={showcardvideo}>
                  <Box w="100%" alignItems={"center"}>
                    <Box h="60%">
                      <img src={video} width="60px" height="60px"></img>
                    </Box>
                    <Box h="40%">
                      <Text textAlign={"center"}>
                        Video
                        <br />
                        Conferencing
                      </Text>
                    </Box>
                  </Box>
                </Pressable>
              </HStack>
              <br />
              {showcm && (
                <PresenceTransition
                  visible={showcm}
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 250,
                    },
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#F9FAF2",
                      borderWidth: "2px",
                      borderRadius: "10px",
                      borderColor: "rgb(187,214,100)",
                      justifyContent: "center",
                      padding: "10px",
                      marginRight: "20px",
                      left: "10px",
                    }}
                  >
                    <ComputerizationAndMaintenance />
                  </Box>
                </PresenceTransition>
              )}
              {showweb && (
                <PresenceTransition
                  visible={showweb}
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 250,
                    },
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#F9FAF2",
                      borderWidth: "2px",
                      borderRadius: "10px",
                      borderColor: "rgb(187,214,100)",
                      justifyContent: "center",
                      padding: "10px",
                      marginRight: "20px",
                      left: "10px",
                    }}
                  >
                    <WebsiteDevelopmentAndMaintenance />
                  </Box>
                </PresenceTransition>
              )}
              {showsoft && (
                <PresenceTransition
                  visible={showsoft}
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 250,
                    },
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#F9FAF2",
                      borderWidth: "2px",
                      borderRadius: "10px",
                      borderColor: "rgb(187,214,100)",
                      justifyContent: "center",
                      padding: "10px",
                      marginRight: "20px",
                      left: "10px",
                    }}
                  >
                    <SoftwareDevelopmentAndMaintenance />
                  </Box>
                </PresenceTransition>
              )}
              {showvideo && (
                <PresenceTransition
                  visible={showvideo}
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 250,
                    },
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#F9FAF2",
                      borderWidth: "2px",
                      borderRadius: "10px",
                      borderColor: "rgb(187,214,100)",
                      justifyContent: "center",
                      padding: "10px",
                      marginRight: "20px",
                      left: "10px",
                    }}
                  >
                    <VideoConferencing />
                  </Box>
                </PresenceTransition>
              )}
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default Mis;
