import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  ZStack,
  Hidden,
} from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import EdafTable from "./components/edaf";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import Butterfly from "@views/components/butterfly_animation";
import DateSelector from "./components/date_selector";

const Edaf = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  return (
    <Box>
      <Hidden only={["sm", "xs"]}>
        <Box zIndex={1000} position={"absolute"}>
          {/* <Butterfly /> */}
        </Box>
      </Hidden>
      <VStack>
        <Box>
          <HeaderSection
            imageUrl={ForestImageCover}
            title={t("elephant_death_audit_framework")}
          />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <DateSelector
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    setSelectedYear={setSelectedYear}
                    setSelectedMonth={setSelectedMonth}
                  />
                </Box>
              </VStack>
            </Box>
            <Box w={["100%", "100%", "65%"]} h="100%">
              <EdafTable
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
              />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default Edaf;
