import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Collapse } from "antd";

const { Panel } = Collapse;

const DateSelector = ({
  selectedYear,
  setSelectedYear,
  selectedMonth,
  setSelectedMonth,
}) => {
  const [expandedYears, setExpandedYears] = useState([]);
  let month_names = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = Array.from(
    { length: new Date().getFullYear() - 2019 },
    (_, index) => 2020 + index
  ).reverse();
  const months = Array.from({ length: 12 }, (_, index) => index + 1);

  const handleYearToggle = (year) => {
    setExpandedYears(year);
    setSelectedYear(Number(year));
    setSelectedMonth(null);
  };

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
  };

  return (
    <div className="container">
      <div className="scrollableContainer">
        <Scrollbars className="scrollbars">
          <Collapse
            accordion
            activeKey={expandedYears}
            onChange={handleYearToggle}
            className="collapseContainer"
          >
            {years.map((year) => (
              <Panel
                key={year}
                header={year}
                className={`panelItem ${
                  selectedYear === year ? "panelItemSelected" : ""
                }`}
                showArrow={false}
              >
                <ul className="dateList nestedMonths">
                  {months.map((month) => (
                    <li
                      key={month}
                      onClick={() => handleMonthSelect(month)}
                      className={`listItem ${
                        selectedMonth === month ? "selected" : ""
                      }`}
                    >
                      {month_names[month]}
                    </li>
                  ))}
                </ul>
              </Panel>
            ))}
          </Collapse>
        </Scrollbars>
      </div>
    </div>
  );
};

export default DateSelector;
