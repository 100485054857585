import { useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import HeaderSection from "@views/components/ui/header_section";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import { useTranslation } from "react-i18next";
import Biodiversity from "../biodiversity";
import Wildlife from "@views/pages/wildlife";

// Import TabView and TabBar from react-native-tab-view
import { TabView, TabBar } from "react-native-tab-view";

const BiodiversityTab = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0); // Default active tab index

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const tabItems = [
    {
      id: "biodiversity",
      label: t("Biodiversity"),
      key: "biodiversity",
      component: Biodiversity,
    },
    {
      id: "wildlife",
      label: t("Wildlife"),
      key: "wildlife",
      component: Wildlife,
    },
  ];

  return (
    <Box style={{ overflow: "hidden" }}>
      <VStack>
        <HeaderSection imageUrl={ForestImageCover} title={t("biodiversity_wildlife")} />

        <Box my="10">
          <HStack flexDirection={{ base: "column", md: "row" }}>
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="organization" />
                </Box>
              </VStack>
            </Box>

            <VStack w={["100%", "100%", "65%"]}>
              <TabView
                navigationState={{ index: activeTab, routes: tabItems }}
                onIndexChange={handleTabChange}
                renderScene={({ route }) => (
                  <route.component />
                )}
                renderTabBar={(props) => (
                  <TabBar
                    {...props}
                    indicatorStyle={{ backgroundColor: "green" }}
                    labelStyle={{ color: "black" }}
                    style={{ backgroundColor: "white" }}
                    renderLabel={({ route }) => (
                      <Text>{route.label}</Text>
                    )}
                  />
                )}
              />
            </VStack>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default BiodiversityTab;
