import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  AspectRatio,
  Stack,
  Heading,
  Image,
  Button,
} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";
import FlowerImage from "@assets/images/menu/plant.png";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { BASE_URL_ASSETS } from "@helpers/constants";

const OnlineServiceCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <VStack space={10}>
        {" "}
        <HStack
          space="10"
          flexDirection={{
            base: "column",
            md: "row",
          }}
          padding={["10px", "10px", "0px"]}
        >
          <Box
            maxW="400"
            rounded="lg"
            overflow="hidden"
            borderColor="coolGray.200"
            borderWidth="1"
            _dark={{
              borderColor: "coolGray.600",
              backgroundColor: "gray.700",
            }}
            _web={{
              shadow: 2,
              borderWidth: 0,
            }}
            _light={{
              backgroundColor: "gray.50",
            }}
          >
            <Box>
              <AspectRatio w="100%" ratio={16 / 9}>
                <Image
                  source={{
                    uri: BASE_URL_ASSETS + "dolphin.png",
                  }}
                  alt="image"
                />
              </AspectRatio>
            </Box>
            <Stack p="4" space={3} height={["350px", "350px", "300px"]}>
              <Stack space={2}>
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  // textAlign={"center"}
                  style={{
                    color: "green.500",
                  }}
                  color="rgb(17 114 47)"
                  dark={{
                    color: "success.600",
                  }}
                >
                  Save Dugong
                </Text>

                <Text fontSize="xs" mt="4">
                  It is developed for Android and Apple mobile phones under
                  Tamil Nadu Biodiversity Conservation and Greening Project
                  (TBGP) to protect dugong, the endangered marine mammal,
                  protected under Schedule I of the Wildlife Protection Act,
                  1972. Fishermen who set out for fishing could take pictures
                  and videos of Dugong using this app. Data such as the date,
                  time and Geo-coordinates would be processed automatically when
                  the fishermen take a picture or video of dugongs.
                </Text>
              </Stack>

              <HStack alignItems="center" space={4} justifyContent="center">
                <a
                  href="https://play.google.com/store/apps/details?id=com.save.dugong&hl=en_IN&gl=US"
                  target={"_blank"}
                >
                  <Button
                    variant={"outline"}
                    height="40px"
                    width="150px"
                    colorScheme="success"
                    borderColor={"success.600"}
                    color="success.600"
                  >
                    <HStack space="2">
                      <Box alignSelf={"center"}>
                        <IoLogoGooglePlaystore />
                      </Box>
                      <Box>
                        <Text color="success.600">Downlaod App</Text>
                      </Box>
                    </HStack>
                  </Button>
                </a>
              </HStack>
            </Stack>
          </Box>
          <Box
            mt={["30px", "30px", "0px"]}
            maxW="400"
            rounded="lg"
            overflow="hidden"
            borderColor="coolGray.200"
            borderWidth="1"
            _dark={{
              borderColor: "coolGray.600",
              backgroundColor: "gray.700",
            }}
            _web={{
              shadow: 2,
              borderWidth: 0,
            }}
            _light={{
              backgroundColor: "gray.50",
            }}
          >
            <Box>
              <AspectRatio w="100%" ratio={16 / 9}>
                <Image
                  source={{
                    uri: BASE_URL_ASSETS + "zoo.png",
                  }}
                  width="400px"
                  alt="image"
                />
              </AspectRatio>
            </Box>
            <Stack p="4" space={3} height={["360px", "360px", "320px"]}>
              <Stack space={2}>
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  style={{
                    color: "green.500",
                  }}
                  color="rgb(17 114 47)"
                  dark={{
                    color: "success.600",
                  }}
                >
                  Vandalur zoo Online Ticket Booking
                </Text>
                <Text fontSize="xs" mt="5">
                  <bold>Online Ticket booking for Zoo visit</bold> to help
                  visitor to easily cruise through the Zoo. <br />
                  <bold>Zoo map</bold> -an interactive map, which helps as a
                  virtual tour guide while inside the zooand built
                  comprehensively to show directions right from parking to every
                  animal to rest room to coffee shops.
                </Text>
              </Stack>
              <HStack alignItems="center" space={4} justifyContent="center">
                <a href="https://tickets.aazp.in/" target={"_blank"}>
                  <Button
                    variant={"outline"}
                    height="40px"
                    width="150px"
                    borderColor={"success.600"}
                    color="success.600"
                    colorScheme="success"
                  >
                    <HStack space="2">
                      <Box alignSelf={"center"}>
                        <BsBoxArrowUpRight />
                      </Box>
                      <Box>
                        <Text color="success.600">View Website</Text>
                      </Box>
                    </HStack>
                  </Button>
                </a>
              </HStack>
            </Stack>
          </Box>
        </HStack>
        <HStack>
          <Box
            mt={["30px", "30px", "0px"]}
            maxW="400"
            rounded="lg"
            overflow="hidden"
            borderColor="coolGray.200"
            borderWidth="1"
            _dark={{
              borderColor: "coolGray.600",
              backgroundColor: "gray.700",
            }}
            _web={{
              shadow: 2,
              borderWidth: 0,
            }}
            _light={{
              backgroundColor: "gray.50",
            }}
          >
            <Box>
              <AspectRatio w="100%" ratio={16 / 9}>
                <Image
                  source={{
                    uri: "https://forests.tn.gov.in/storage/tnfd/07a6e344-fdd9-49f4-9f2b-3a62f25644eb.png",
                  }}
                  width="400px"
                  alt="image"
                />
              </AspectRatio>
            </Box>
            <Stack p="4" space={3} height={["360px", "360px", "auto"]}>
              <Stack space={2}>
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  style={{
                    color: "green.500",
                  }}
                  color="rgb(17 114 47)"
                  dark={{
                    color: "success.600",
                  }}
                >
                  Distance to Forest portal
                </Text>
                {/* <Text fontSize="xs" mt="5">
                  <bold>Online Ticket booking for Zoo visit</bold> to help
                  visitor to easily cruise through the Zoo. <br />
                  <bold>Zoo map</bold> -an interactive map, which helps as a
                  virtual tour guide while inside the zooand built
                  comprehensively to show directions right from parking to every
                  animal to rest room to coffee shops.
                </Text> */}
              </Stack>
              <HStack alignItems="center" space={4} justifyContent="center">
                <a href="https://distance.forests.tn.gov.in/" target={"_blank"}>
                  <Button
                    variant={"outline"}
                    height="40px"
                    width="150px"
                    borderColor={"success.600"}
                    color="success.600"
                    colorScheme="success"
                  >
                    <HStack space="2">
                      <Box alignSelf={"center"}>
                        <BsBoxArrowUpRight />
                      </Box>
                      <Box>
                        <Text color="success.600">View Website</Text>
                      </Box>
                    </HStack>
                  </Button>
                </a>
              </HStack>
            </Stack>
          </Box>
        </HStack>
      </VStack>
    </>
  );
};
export default OnlineServiceCard;
