import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";

const ThrustAreaHTML = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <VStack>
          <HStack>
            <Box flex={1} variant="thrust_area_left">
              <Text>{"Biodiversity and Genetic Resource Conservation"}</Text>
            </Box>
            <Box flex={1}></Box>
          </HStack>
          <HStack>
            <Box flex={1}></Box>
            <Box flex={1} variant="thrust_area_right">
              <Text>
                {
                  "Establishment of permanent water storage structures in the forest and adjacent non-forest areas"
                }
              </Text>
            </Box>
          </HStack>
          <HStack>
            <Box flex={1} variant="thrust_area_left">
              <Text>
                {
                  "Enhancing Climatic Resilience in moderately dense and open forests"
                }
              </Text>
            </Box>
            <Box flex={1}></Box>
          </HStack>
          <HStack>
            <Box flex={1}></Box>
            <Box flex={1} variant="thrust_area_right">
              <Text>{"Forest protection"}</Text>
            </Box>
          </HStack>{" "}
          <HStack>
            <Box flex={1} variant="thrust_area_left">
              <Text textAlign="justify">
                {
                  "Increasing Tree Cover Outside Forests Socio-Economic Development of Forestfringe villages"
                }
              </Text>
            </Box>
            <Box flex={1}></Box>
          </HStack>{" "}
          <HStack>
            <Box flex={1}></Box>
            <Box flex={1} variant="thrust_area_right">
              <Text>{"Tribal Development"}</Text>
            </Box>
          </HStack>
          <HStack>
            <Box flex={1} variant="thrust_area_left">
              <Text>{"Eco-tourism"}</Text>
            </Box>
            <Box flex={1}></Box>
          </HStack>{" "}
          <HStack>
            <Box flex={1}></Box>
            <Box flex={1} variant="thrust_area_right">
              <Text>{"Infrastructure Development"}</Text>
            </Box>
          </HStack>
          <HStack>
            <Box flex={1} variant="thrust_area_left">
              <Text>{"Research, Transfer of technology and Extension"}</Text>
            </Box>
            <Box flex={1}></Box>
          </HStack>{" "}
          <HStack>
            <Box flex={1}></Box>
            <Box flex={1} variant="thrust_area_right">
              <Text>
                {"Application and Development of GIS, MIS &amp; e-Governance"}
              </Text>
            </Box>
          </HStack>
          <HStack>
            <Box flex={1} variant="thrust_area_left">
              <Text>{"Human Resource Development"}</Text>
            </Box>
            <Box flex={1}></Box>
          </HStack>{" "}
        </VStack>
      </Box>
    </>
  );
};
export default ThrustAreaHTML;
