import { Collapse } from "antd";
import React from "react";
import BiodiversityTheFLoraOfTheNilgirisAndPulnetHillTops from "./biodiversity_flora_of_the_nilgiris_and_pulney_hill_tops";
import PioneersInSearchOfThePresidencyFloraHTML from "./pioneers_in_search_of_the_presidency_flora";
import BiodiversityTheStatesFloralDiversity from "./biodiversity_the_states_floral_diversity";
import BiodiversityForestTypesOfTn from "./biodiversity_forest_types_of_tn";
import BiodiversityVegetationAndItsDistribution from "./biodiversity_vegetation_and_its_distribution";

const { Panel } = Collapse;
const BioDiversityCollapsible = () => {
  return (
    <Collapse defaultActiveKey={["1"]} expandIconPosition={"end"} ghost >
      <Panel header="Pioneers in Search of the Presidency Flora" key="1">
        <PioneersInSearchOfThePresidencyFloraHTML />
      </Panel>
      <Panel header="Flora of the Nilgiris and Pulney Hilltops" key="2">
        <BiodiversityTheFLoraOfTheNilgirisAndPulnetHillTops />
      </Panel>
      <Panel header="States Floral Diversity" key="3">
        <BiodiversityTheStatesFloralDiversity />
      </Panel>
      <Panel header="Forest Types of Tamil Nadu" key="4">
        <BiodiversityForestTypesOfTn />
      </Panel>
      <Panel header="Vegetation and its Distribution" key="5">
        <BiodiversityVegetationAndItsDistribution />
      </Panel>
    </Collapse>
  );
};
export default BioDiversityCollapsible;
