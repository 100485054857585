import React, { useEffect, useState } from "react";
import { Button, Menu } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import { Box, useBreakpointValue } from "native-base";

// import "antd/dist/antd.css";
const HomeMenu = (props) => {
  const { collapsed } = props;
  const history = useHistory();
  const onChange = (route_name, value) => {
    console.log(value);
    if (route_name === "green_tn_mission")
      window.open(
        "https://www.greentnmission.com/",
        "_blank",
        "noopener,noreferrer"
      );
    else if (route_name === "EDAF")
      window.open(
        "https://edaf.forests.tn.gov.in/users/dashboardview",
        "_blank",
        "noopener,noreferrer"
      );
    else if (route_name === "EDAF_LOGIN")
      window.open(
        "https://edaf.forests.tn.gov.in/users/login",
        "_blank",
        "noopener,noreferrer"
      );
    else if (value?.cmstype === "list" && value.cms)
      history.push({
        pathname: `${ROUTES["DYNAMIC_LIST_PAGE"]}`,
        state: { cms: value?.cms },
      });
    else if (value?.cmstype === "html" && value.cms)
      history.push({
        pathname: `${ROUTES["DYNAMIC_HTML_PAGE"]}`,
        state: { cms: value?.cms },
      });
    else
      history.push({
        pathname: `${ROUTES[route_name]}`,
        state: { cms: value?.cms },
      });

  };

  const { t } = useTranslation();
  const items = [
    { key: "HOME", icon: <HomeOutlined />, isRoute: true },
    {
      label: t("organization"),
      key: "organization",
      children: [
        {
          label: t("administration"),
          key: "ADMINISTRATION",
          isRoute: true,
        },
        {
          label: t("Circles_Divisions"),
          key: "CIRCLES",
        },
        {
          label: "CAMPA",
          key: "CAMPA",
          isRoute: true,
        },
        {
          label: t("forest_conservation_act"),
          key: "FOREST_CONSERVATION_ACT",
          isRoute: true,
        },
        {
          label: t("information_technology"),
          key: "INFORMATION_TECHNOLOGY_IT",
          // children: [
          //   {
          //     label: "management infor.system",
          //     key: "MIS",
          //     isRoute: true,
          //   },
          //   {
          //     label: "Geomatic center",
          //     key: "Geomatic center",
          //     isRoute: true,
          //   },
          // ],
        },
        {
          label: t("Planning_budgetting"),
          key: "Plan & Budget",
          children: [
            {
              label: t("shared_schemes"),
              key: "SHARED_SCHEMES",
              isRoute: true,
            },
            {
              label: t("externally_aided_project"),
              key: "EXTERNALLY_AIDED_PROJECT",
              isRoute: true,
            },
            {
              label: t("state_schemes"),
              key: "STATE_SCHEMES",
              isRoute: true,
            },
          ],
        },
        {
          label: t("protection_vigilance"),
          key: "PROTECTION_VIGILANCE_P_V",
          isRoute: true,
        },
        {
          label: t("research_education"),
          key: "RESEARCH",

          children: [
            {
              label: t("research"),
              key: "RESEARCH",
            },
            {
              label: t("education"),
              key: "Education",
              children: [
                {
                  label: t("tamilnadu_forest_academy"),
                  key: "TAMILNADU_FOREST_ACADEMY",
                  isRoute: true,
                },
                {
                  label: t("tn_forestry_training_college"),
                  key: "TN_FORESTRY_TRAINING_COLLEGE",
                  isRoute: true,
                },
              ],
            },
          ],
        },
        {
          label: t("Social_forestry_extension"),
          key: "SOCIAL_FORESTRY",
        },
        {
          label: t("bio_diversity_wildlife"),
          key: "bio",
          children: [
            { label: t("introduction"), key: "BIODIVERSITY_TAB" },
            {
              label: t("sacred_groves_of_tN"),
              key: "SACRED_GROVES_OF_TN",
              isRoute: true,
            },
            {
              label: t("ramsar_site_of_TN"),
              key: "RAMSAR_SITE_OF_TN",
              isRoute: true,
            },
            { label: t("zoo_Authority_of_TN"), key: "ZOO_AUTHORITY_OF_TN" },
            {
              label: t("important_authority_trust"),
              key: "Important Authority & Trust",
              children: [
                {
                  label: t("tamil_nadu_state_wetland_authority"),
                  key: "TAMIL_NADU_STATE_WETLAND_AUTHORITY",
                  isRoute: true,
                },
                {
                  label: t("conservation_authority_Of_Pallikaranai"),
                  key: "CONSERVATION_AUTHORITY_OF_PALLIKARANAI",
                  isRoute: true,
                },
                {
                  label: t("GOMBRT"),
                  key: "GULF_OF_MANNAR_BIOSPHERE_RESERVE_TRUST_GOMBRT",
                  isRoute: true,
                },
                {
                  label: t("tamil_nadu_state_biodiversity_board"),
                  key: "TAMIL_NADU_STATE_BIODIVERSITY_BOARD",
                  isRoute: true,
                },
              ],
            },
            // {
            //   label: "Wildlife Conservation Centres",
            //   key: "Wildlife Conservation Centres",
            // },
            { label: t("eco_tourism_sites"), key: "ECO_TOURISM_SITES" },
            {
              label: t(
                "Tiger Reserves / Import Authority And Trust Tiger Reserves"
              ),
              key: "TRIA",
            },

            // {
            //   label: "Critical Wildlife Habitats",
            //   key: "Critical Wildlife Habitats",
            // },
            // {
            //   label: "Corporation,Boards & Tiger",
            //   key: "Corporation,Boards & Tiger",
            // },
            // { label: "Reserves", key: "Reserves" },
          ],
        },
        { label: t("working_plan"), key: "WORKING_PLAN" },
        // { label: t("Wetlands"), key: "TAMIL_NADU_STATE_WETLAND_AUTHORITY" },
        // {
        //   label: t("Tamilnadu_Biodiversity_board"),
        //   key: "TAMIL_NADU_BIODIVERSITY_BOARD",
        // },
      ],
    },
    {
      label: t("projects_programmes"),
      key: "Projects_programmes",
      children: [
        {
          label: t("past_decadal_programs"),
          key: "past_decadal_programs",
          children: [
            {
              label: t("part-II_schemes"),
              key: "PART_II_SCHEMES",
              isRoute: true,
            },
            {
              label: t("coastal_area_management"),
              key: "COASTAL_AREA_MANAGEMENT",
              isRoute: true,
            },
            {
              label: t("green_india_mission_(GIM)"),
              key: "GREEN_INDIA_MISSION",
              isRoute: true,
            },
            {
              label: t("hill_area_development_programme"),
              key: "HILL_AREA_DEVELOPMENT_PROGRAMME",
              isRoute: true,
            },
            {
              label: t("national_aforestation_programme"),
              key: "NATIONAL_AFFORESTATION_PROGRAMME",
              isRoute: true,
            },
            {
              label: t("tank_foreshore_plantations"),
              key: "TANK_FORESHORE_PLANTATIONS",
              isRoute: true,
            },
            {
              label: t("TAP_(Phase-I)"),
              key: "TAP_PHASE_I",
              isRoute: true,
            },
            {
              label: t("TAP_(Phase-II)"),
              key: "TAP_PHASE_II",
              isRoute: true,
            },
            {
              label: t("tree_cultivation_in_private_lands_tcpl"),
              key: "TREE_CULTIVATION_IN_PRIVATE_LANDS_TCPL",
              isRoute: true,
            },
            {
              label: t("western_ghats_development_programme-(WDGP)"),
              key: "WESTERN_GHATS_DEVELOPMENT_PROGRAMME_WDGP",
              isRoute: true,
            },
            {
              label: t("five_year_plans"),
              key: "FIVE_YEAR_PLANS",
              isRoute: true,
            },
          ],
        },
        { label: t("policy_note"), key: "POLICY_NOTE", isRoute: true },
        { label: t("announcements"), key: "ANNOUNCEMENTS", isRoute: true },
        // { label: t("citizen_charter"), key: "CITIZEN_CHARTER" },
        {
          label: t("schemes_implementation"),
          key: "SCHEMES_IMPLEMENTATION",
          isRoute: true,
        },
        {
          label: t("green_tn_mission"),
          key: "green_tn_mission",
          isRoute: true,
        },
        {
          label: t("ongoing_programmes"),
          key: "ongoing_programmes",
          children: [
            {
              label: t("vision"),
              key: "VISION",
              isRoute: true,
            },
            {
              label: t("trust_area"),
              key: "THRUST_AREA",
              isRoute: true,
            },
            {
              label: t("policy_initiatives_policy"),
              key: "POLICY_INITIATIVES",
              isRoute: true,
            },
            {
              label: t("Strategies"),
              key: "STRATEGIES",
              isRoute: true,
            },
            {
              label: t("state_schemes"),
              key: "STATE_SCHEMES",
              isRoute: true,
            },
            {
              label: t("central_schemes"),
              key: "CENTRAL_SCHEMES",
              isRoute: true,
            },
            {
              label: t("externally_aided_project_eap_schemes"),
              key: "EXTERNALLY_AIDED_PROJECT_EAP_SCHEMES",
              isRoute: true,
            },
            {
              label: t("tribal_development"),
              key: "TRIBAL_DEVELOPMENT",
              isRoute: true,
            },
          ],
        },
      ],
    },
    {
      label: t("Legislations"),
      key: "Legislations",
      children: [
        {
          label: t("Policies"),
          key: "POLICIES",
        },
        {
          label: t("Codes"),
          key: "CODES",
        },
        {
          label: t("Rules"),
          key: "RULES",
        },
        { label: t("notifications"), key: "NOTIFICATIONS" },
        { label: t("Guidelines"), key: "GUIDELINES" },
        {
          label: t("acts"),
          key: "ACTS",
        },
      ],
    },
    {
      label: t("public_information"),
      key: "public_information",
      children: [
        {
          label: t("Important_G.Os"),
          key: "Important G.Os",
          children: [
            {
              label: t("G.Os_FOR_TANII"),
              key: "G_OS_FOR_TANII",
              isRoute: true,
            },
            {
              label: t("G.Os_for_wildLife_schemes"),
              key: "G_OS_FOR_WILDLIFE_SCHEMES",
              isRoute: true,
            },
            {
              label: t("G.Os_for_Other_Schemes"),
              key: "G_O_S_FOR_OTHER_SCHEMES",
              isRoute: true,
            },
            {
              label: t("Other_General_G.Os"),
              key: "OTHER_GENERAL_G_O_S",
              isRoute: true,
            },
          ],
        },
        {
          label: t("List_Of_Biofertilizer_Centres"),
          key: "LIST_OF_BIOFERTILIZER_CENTRES",
          isRoute: true,
        },
        {
          label: t("List_Of_Central_Nurseries_In_The_State"),
          key: "LIST_OF_CENTRAL_NURSERIES_IN_THE_STATE",
          isRoute: true,
        },
        {
          label: t("Manuals_For_Online_Submissions"),
          key: "MANUALS_FOR_ONLINE_SUBMISSIONS",
          isRoute: true,
        },
        {
          label: t("Guidelines_For_Sawmills"),
          key: "GUIDELINES_FOR_SAWMILLS",
        },
        { label: t("Departmental_Books"), key: "DEPARTMENTAL_BOOKS" },
        // { label: t("Forest_Fire_Alert"), key: "Forest Fire Alert" },
        {
          label: "Sale of Seedings,Seeds etc.",
          key: "SALE_OF_SEEDLING",
        },
        {
          label: t("Eco_tourism_Sites"),
          key: "ECO_TOURISM_SITES",
          cursor: "pointer !important",
        },
        // {
        //   label: t("PDAR"),
        //   key: "PDAR",
        //   cursor: "pointer !important",
        // },
        // {
        //   label: t("elephant_death_audit_framework"),
        //   key: "EDAF",
        //   cursor: "pointer !important",
        // },
        {
          label: t("Wildlife Census"),
          key: "WILDLIFECENSUS",
          isRoute: true,
        },
        {
          label: t("wildlife_management_plan"),
          key: "WILDLIFE_MANAGEMENT_PLAN",
          isRoute: true,
        },
        {
          label: t("Forest Reading Books"),
          key: "FOREST_READING_BOOKS",
          isRoute: true,
        },
      ],
    },
    {
      label: t("Tenders_Ads"),
      key: "TENDERS_ADVERTISEMENTS",
      children:[
        {
          label:t("tender"),
          key:"TENDERS_ADVERTISEMENTS"
        },
        {
          label:t("advertisements"),
          key:"ADVERTISEMENTS"
        }
      ]
    },
    { label: t("Online_Services"), key: "ONLINE_SERVICES" },
    { label: t("elephant_death_audit_framework"), key: "EDAF" },
    {
      label: t("More"),
      key: "More",
      children: [
        { label: t("Paper_Clippings"), key: "PAPER_CLIPPINGS" },
        { label: t("Recruitments"), key: "RECRUITMENT" },
        {
          label: t("Innovation_&_best_Practices"),
          key: "Innovation & best Practices",
          children: [
            { label: t("Best_Practices"), key: "BEST_PRACTICES" },
            {
              label: t("Innovation"),
              key: "Innovation",
              children: [
                { label: t("Innovation_Madurai"), key: "INNOVATION_MADURAI" },
                {
                  label: t("Innovation_MTR"),
                  key: "INNOVATION_MUDUMALAI_TIGER_RESERVE",
                },
                {
                  label: t("Sathyamangalam_Tiger_Reserve"),
                  key: "INNOVATION_STR",
                },
                {
                  label: t("Innovation_Dharmapuri"),
                  key: "INNOVATION_DHARMAPURI",
                },
                { label: t("Innovation_TNFUSRC"), key: "INNOVATION_TNFUSRC" },
              ],
            },
            {
              label: t("Success_Story"),
              key: "Success Story",
              children: [
                {
                  label: t("Success_Story_Coimbatore"),
                  key: "SUCCESS_STORY_COIMBATORE",
                },
                { label: t("Success_Story_ATR"), key: "SUCCESS_STORY_ATR" },
                {
                  label: t("Success_Story_Srivilliputhur"),
                  key: "SUCCESS_STORY_SRIVILLIPUTHUR",
                },
                {
                  label: t("Success_Story_TNFUSRC"),
                  key: "SUCCESS_STORY_TNFUSRC",
                },
              ],
            },
          ],
        },
        { label: t("Recent_GO.s"), key: "RECENT_G_OS" },
        // { label: t("RTI"), key: "RTI" },
        {
          label: t("Publications"),
          key: "Publications",
          children: [
            {
              label: t("Govt_Of_Tamil_Nadu_Publications"),
              key: "GOVT_OF_TAMIL_NADU_PUBLICATIONS",
            },
            {
              label: t("International_Publications"),
              key: "INTERNATIONAL_PUBLICATIONS",
            },
            {
              label: t("Govt_Of_India_Publications"),
              key: "GOVT_OF_INDIA_PUBLICATIONS",
            },
            {
              label: t("TN_Forest_Dept_Publications"),
              key: "TN_FOREST_DEPT_PUBLICATIONS",
            },
            { label: t("General"), key: "GENERAL" },
            { label: t("Calendar 2024"), key: "CALENDAR" },
          ],
        },
        {
          label: t("Corporation_Boards"),
          key: "Corporation,Boards & Tiger Reserves",
          children: [
            {
              label: t(
                "TamilNadu_Forest_Plantation_Corporation_Tafcorn_Limited_Trichy"
              ),
              key: "TAMIL_NADU_FOREST_PLANTATION_CORPORATION_TAFCORN_LIMITED_TRICHY",
            },
            {
              label: t(
                "Tamil Nadu Tea Plantation Corporation (TANTEA) Limited, Coonoor"
              ),
              key: "TAMIL_NADU_TEA_PLANTATION_CORPORATION_TANTEA_LIMITED_COONOOR",
            },
            {
              label: t("Arasu Rubber Corporation Arc Limited Nagercoil"),
              key: "ARASU_RUBBER_CORPORATION_ARC_LIMITED_NAGERCOIL",
            },
            {
              label: t("Tamil Nadu Biodiversity Board"),
              key: "TAMIL_NADU_BIODIVERSITY_BOARD",
            },
          ],
        },
        {
          label: t("More Menu"),
          key: "More_Menu",
          children: [
            {
              label: t("About Us"),
              key: "ABOUTPRESENTCONTENT",
            },
            {
              label: t("Citizen Charter"),
              key: "CITIZEN_CHARTER",
            },
            {
              label: t("List of IFS Officers"),
              key: "LISTOFIFSOFFICERS",
            },
            {
              label: t("RTI"),
              key: "RTI",
            },
            {
              label: t("FAQs"),
              key: "FAQ",
            },
            {
              label: t("Contact Us"),
              key: "CONTACTUS",
            },

            {
              label: t("EDAF Login"),
              key: "EDAF_LOGIN",
            },
          ],
        },
        // {
        //   label: t("DYNAMIC LIST PAGE"),
        //   key: " ",
        //   children: [
        //     {
        //       label: t("Test_list"),
        //       key: "DYNAMIC",
        //       cms: "test_list_dynamic",
        //       cmstype: "list"
        //     },
        //     {
        //       label: t("Test_HTML"),
        //       key: "DYNAMIC_LIST_PAGE",
        //       cms: "test_html_dynamic",
        //       cmstype: "html"
        //     },
        //   ],
        // },
      ],
    },
  ];
  const HeaderView = useBreakpointValue({
    base: "horizontal",
    sm: "inline",
    xs: "inline",
    md: "horizontal",
    lg: "horizontal",
    xl: "horizontal",
  });

  const findItemByKey = (items, key) => {
    for (const item of items) {
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        const foundInChildren = findItemByKey(item.children, key);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null;
  };

  return (
    <Box>
      {!collapsed && (
        <Menu
          items={items}
          mode={HeaderView}
          inlineCollapsed={collapsed}
          onClick={({ key }) => {
            const foundObject = findItemByKey(items, key);
            onChange(key, foundObject);
          }}
        />
      )}
    </Box>
  );
};
export default HomeMenu;
