import { gql } from "@apollo/client";

export const get_page_query = gql`
    query getPages($page_number: Int, $page_limit: Int) {
        getPages(page_number: $page_number, page_limit: $page_limit) {
            items {
                id
                name
                html_components {
                    id
                    key
                }
                table_components {
                    id
                    key
                }
                card_components {
                    id
                    key
                }
                error {
                    status_code
                    message
                }
            }
            pagination {
                page_number
                page_limit
                total_count
            }
        }
    }
`;

export const get_one_page_query = gql`
    query getPage($id: String!) {
        getPage(id: $id) {
            id
            name
            html_components {
                id
                key
            }
            table_components {
                id
                key
            }
            card_components {
                id
                key
            }
            error {
                status_code
                message
            }
        }
    }
`;

export const deletePageMutation = gql`
    mutation deletePage($id: String!) {
        deletePage(id: $id) {
            id
            status
            error {
                status_code
                message
            }
        }
    }
`;

export const createPageMutation = gql`
    mutation createPage($data: page_input) {
        createPage(data: $data) {
            id
            status
            error {
                status_code
                message
            }
        }
    }
`;

export const updatePageMutation = gql`
    mutation updatePage($id: String!, $data: page_input) {
        updatePage(id: $id, data: $data) {
            id
            status
            error {
                status_code
                message
            }
        }
    }
`;

export const generatePageMutation = gql`
    mutation generatePage($id: string) {
        generatePage(id: $id) {
            id
            status
            error {
                status_code
                message
            }
        }
    }
`;

export const generateAllPageMutation = gql`
    mutation generateAllPage {
        generateAllPage {
            id
            status
            error {
                status_code
                message
            }
        }
    }
`;
