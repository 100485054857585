import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import SharedSchemesHTML from "./components/shared_schemes";

const Acts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection
            imageUrl={ForestImageCover}
            title={t("shared_schemes")}
          />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="Plan_budget" />
                </Box>
              </VStack>
            </Box>
            <Box
              w={["100%", "100%", "65%"]}
              h="100%"
              marginTop={["20px", "20px"]}
            >
              <SharedSchemesHTML />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default Acts;
