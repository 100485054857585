import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, VStack, HStack, Center, Pressable, Text,Card} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Loader from "@views/components/ui/loader";
import NoData from "@views/components/ui/no_data";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import {useTranslation} from "react-i18next";

const AdministrativeSetupCardItem = (props) => {
    const dispatch = useDispatch();
    const {item}=props
    const {t} = useTranslation();
   
    return (<>
    
   <Box> <Card>
    <VStack>
        <Text>{item.serial_no?.value}</Text>

<Text>{item.name?.value}</Text>

<Text>{item.pdf_url?.value}</Text>


    </VStack>
    </Card></Box>

    </>);
};
export default AdministrativeSetupCardItem;

