import { useParams } from "react-router-dom";
import {
  getSystemCMSFieldUserListSelector,
  get_system_cms_user_field_request,
} from "@services/redux";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Aboutcms from "./aboutcms";

const ProtectedAreaCms = (props) => {
  const dispatch = useDispatch();
  const { items } = useSelector(getSystemCMSFieldUserListSelector);
  const { key } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(
      get_system_cms_user_field_request({
        json: {
          system_cms_key: "About_Us",
        },
      })
    );
  }, []);

  useEffect(() => {
    if (items.length) {
      let cms = items.find((e) => e.key === "About_Us");
      if (cms?.field_items?.length) {
        let items = cms.field_items;

        let highest_value = 0;
        let object_keys = items.map((field_item) => {
          highest_value =
            field_item.system_cms_values?.length > highest_value
              ? field_item.system_cms_values?.length
              : highest_value;
          return field_item.key;
        });
        let final_list = [];
        for (let index = 0; index < highest_value; index++) {
          let object = {};
          for (let key in object_keys) {
            let value = items
              .find((filter_item) => filter_item.key === object_keys[key])
              .system_cms_values.find(
                (find_item) => find_item.row_number === index
              );
            object[object_keys[key]] = value;
          }
          final_list.push(object);
        }

        setData(final_list?.filter((x) => x.name));
      }
    }
  }, [items]);

  return (
    <>
      <Aboutcms cms_key={key} items={data} />
    </>
  );
};
export default ProtectedAreaCms;
