import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, VStack, HStack, Center, Pressable, Text} from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import {useTranslation} from "react-i18next";
import ExtensionHTML from "./components/extension"


const Extension = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    return (<>
        <ScrollView>
    <Box><ExtensionHTML/></Box>
    
    
    </ScrollView>
    </>);
};
export default Extension;

