import { gql } from "@apollo/client";

export const get_edaf_query = gql`
  query getEdafs(
    $circle_id: String
    $division_id: String
    $range_id: String
    $month: Int
    $year: Int
  ) {
    getEdafs(
      circle_id: $circle_id
      division_id: $division_id
      range_id: $range_id
      month: $month
      year: $year
    ) {
      items {
        id
        edaf_date
        pdf
        gender
        circle_id
        division_id
        range_id
        circle {
          id
          name
        }
        division {
          id
          name
        }
        range {
          id
          name
        }
      }
    }
  }
`;
