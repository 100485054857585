import React from "react";
//  import './index.css';
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Card, Col, Row } from "antd";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { VStack, HStack, Pressable, Box } from "native-base";

const AlbumPhotos = (props) => {
  const { Meta } = Card;

  const history = useHistory();
  const moveToPage = (routes) => {
    history.push({
      pathname: `${routes}`,
    });
  };
  const { item } = props;
  let transforms_name = [];
  let transforms_photo = [];
  for (let index = 0; index < item?.field_items?.length; index++) {
    item.field_items[index].system_cms_values.map((value, inner_index) => {
      if (item?.field_items[index].key === "name") {
        transforms_name.push(value.value);
      }
      if (item?.field_items[index].key === "photo") {
        transforms_photo.push(value.value);
      }
    });
  }
  let transforms = [];
  for (let index = 0; index < transforms_name.length; index++) {
    transforms.push({
      name: transforms_name[index],
      photo: transforms_photo[index],
    });
  }
  return (
    <Row gutter={16} style={{ width: "90vw" }}>
      {transforms.map((x) => {
        return (
          <Col xs={24} sm={24} md={12} lg={8}>
             
            <Card
              style={{ borderRadius: "10px", marginBottom: "10px" }}
              cover={
                <img
                  style={{
                    width: "100%",
                    padding: "10px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                  alt={x.name}
                  src={x.photo}
                />
              }
            >
              <Meta title={x.name} />
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default AlbumPhotos;
