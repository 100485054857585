import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import InformationTechnologyItHTML from "./components/information_technology_it";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import HeaderSection from "@views/components/ui/header_section";
import { fontSize, justifyContent, width } from "styled-system";
import { IoIosArrowForward } from "react-icons/io";
import { BiWorld } from "react-icons/bi";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";

const InformationTechnologyIt = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const handleRedirect = (values) => {
    history.push(ROUTES.MIS);
  };
  const handleRedirect1 = (values) => {
    history.push(ROUTES.GEOMATIC_CENTER);
  };

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection
            imageUrl={ForestImageCover}
            title={t("information_technology")}
          />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="organization" />
                </Box>
              </VStack>
            </Box>
            <Box
              w={["100%", "100%", "65%"]}
              h="100%"
              marginTop={["20px", "25px", "0px"]}
            >
              <InformationTechnologyItHTML />
              <Box variants="subtext">
                <h3>Branches Of the IT wing</h3>
              </Box>
              <HStack
                flexDirection={{
                  base: "column",
                  md: "row",
                }}
              >
                <Pressable onPress={handleRedirect}>
                  <Box
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderWidth: "2px",
                      width: "300px",
                      height: "100px",
                      borderRadius: "10px",
                      borderColor: "rgb(187,214,100)",
                      justifyContent: "center",
                      padding: "10px",
                      marginRight: "20px",
                      marginBottom: "20px",
                    }}
                    marginLeft={["20px", "25px", "0px"]}
                  >
                    <HStack>
                      <Box>
                        <Text
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            paddingLeft: "30px",
                          }}
                        >
                          Management Information <br /> System (MIS)
                        </Text>
                      </Box>
                      <Box
                        style={{
                          paddingLeft: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <IoIosArrowForward
                          onPress={handleRedirect}
                          cursor="pointer"
                        />
                      </Box>
                    </HStack>
                  </Box>
                </Pressable>
                <Pressable onPress={handleRedirect1}>
                  <Box
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderWidth: "2px",
                      width: "300px",
                      height: "100px",
                      borderRadius: "10px",
                      borderColor: "rgb(187,214,100)",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                    marginLeft={["20px", "25px", "0px"]}
                  >
                    <HStack>
                      <Box>
                        <Text
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            paddingLeft: "30px",
                          }}
                        >
                          Geomatic center
                        </Text>
                      </Box>
                      <Box
                        style={{
                          paddingLeft: "90px",
                          justifyContent: "center",
                        }}
                      >
                        <IoIosArrowForward onPress={handleRedirect1} />
                      </Box>
                    </HStack>
                  </Box>
                </Pressable>
              </HStack>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default InformationTechnologyIt;
