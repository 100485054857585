import { Box, Text } from "native-base";
import React from "react";
import TextBox from "../text_box";

const HeaderSection = (props) => {
  const { imageUrl, title, breadcrumbs } = props;
  return (
    <Box
      // bgImage={`url(${imageUrl})`}
      // width="100vw"
      // height="200px"
      // backgroundSize="100vw 200px"
      // backgroundRepeat="no-repeat"
      w="100%"
    >
      <img src={imageUrl} alt={title} height="170px" />
      <Box
        position="absolute"
        bgColor="#111111b3"
        h="100%"
        w="100%"
        justifyContent="center"
        p="10"
      >
        <TextBox fontSize={25} bold color="white" text={title}></TextBox>
        {breadcrumbs?.length && (
          <ol className="breadcrumb breadcrumb-padding">
            {breadcrumbs.map((x) => (
              <li className="breadcrumb-item breadcrumb-link">
                {x.url ? (
                  <a className="breadcrumb-link" href={x.url}>
                    {x.name}
                  </a>
                ) : (
                  x.name
                )}
              </li>
            ))}
          </ol>
        )}
      </Box>
    </Box>
  );
};

export default HeaderSection;
