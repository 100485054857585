import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  Card,
} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Loader from "@views/components/ui/loader";
import NoData from "@views/components/ui/no_data";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";
import { AiOutlineFilePdf } from "react-icons/ai";

const PaperClippingsCardItem = (props) => {
  const dispatch = useDispatch();
  const { item } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Card height="150px" width={"100%"} justifyContent={"center"}>
          <HStack >
            <Box width={"60%"}>
              <VStack>
                <Box>
                  <Text bold>{item.name?.value}</Text>
                </Box>
                <Box width={"50%"}>
                  <Text>{item.date?.value}</Text>
                </Box>
              </VStack>
            </Box>
            <Box>
              <a href={item?.link_url?.value} target={"_blank"}>
                <AiOutlineFilePdf size="30" color="red" />
              </a>
            </Box>
          </HStack>
        </Card>
      </Box>
    </>
  );
};
export default PaperClippingsCardItem;
