import { getsystemCMSFieldListReducer } from "./system_cms_field_list";
import { getSystemCMSFieldUserListReducer } from "./system_cms_field_user_list";

export const systemCMSFieldReducers = {
    systemCMSFieldList: getsystemCMSFieldListReducer,
    systemCMSFieldUserList: getSystemCMSFieldUserListReducer,
};

export * from "./system_cms_field_list";
export * from "./system_cms_field_user_list";
