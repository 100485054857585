import { gql } from "@apollo/client";

export const login_query = gql`
  query login($username: String, $password: String) {
    login(username: $username, password: $password) {
      id
      name
      mobile
      token
      roles {
        id
        name
      }
      error {
        message
        status_code
      }
    }
  }
`;
