import { Card } from "antd";
import { AspectRatio, Box, Center, HStack, Image } from "native-base";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from "react-router-dom";
import AlbumPhotos from "./album_photos_schemes";
import {
  getSystemCMSFieldUserListSelector,
  get_system_cms_user_field_request,
} from "@services/redux";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Albums = (props) => {
  const item_name = useLocation();
  const dispatch = useDispatch();
  const { items } = useSelector(getSystemCMSFieldUserListSelector);
  const [cms, setCms] = useState([]);


  useEffect(() => {
    dispatch(
      get_system_cms_user_field_request({
        json: {
          system_cms_key: item_name.state.detail,
          // system_cms_key: "nabard_scheme"
        },
      })
    );
  }, [item_name]);
  useEffect(() => {
    if (items?.length) {
      let cms = items.filter((e) => e.key ===  item_name.state.detail);
      setCms(cms);
    }
  }, [items]);
  return (
    <div>
      {cms?.map((item, index) => {
        
        return <AlbumPhotos item={item} />;
      })}
    </div>
  );
};
export default Albums;
