import { BASE_URL_ASSETS } from "@helpers/constants";
import {
  Box,
  HStack,
  Hidden,
  Image,
  Pressable,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Software from "@src/assets/images/it/Animation.gif";
import { ROUTES } from "@views/routes/my_routes";
const HomeReactPlayer = (props) => {
  const videoEl = useRef(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const history = useHistory();
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };
  const moveToPage = (routes) => {
    history.push({
      pathname: `${routes}`,
    });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  useEffect(() => {
    // Toggle the animation every 1.5 seconds (adjust as needed)
    const interval = setInterval(() => {
      setShowAnimation((prev) => !prev);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const blinkingStyle = {
    animation: showAnimation ? "blinking 1.5s infinite" : "initial", // Adjust the animation duration as needed
  };

  const colorChangeStyle = {
    animation: showAnimation ? "colorChange 1.5s infinite" : "initial", // Adjust the animation duration as needed
    color: showAnimation ? "red" : "green", // Change to the desired colors
  };
  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column", // Stack children vertically
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
     
      <Hidden Hidden only={["sm", "xs"]}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50px",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          {/* <Box width={"50%"}>
            <HStack alignItems="center" justifyContent={"center"}>
              <Box>
                <Image
                  src={Software}
                  width="100px"
                  height="50px"
                  alt="Software Logo"
                />
              </Box>
              <Box>
                <Pressable
                  _hover={{}}
                  onPress={() => {
                    moveToPage(ROUTES.TENDERS_ADVERTISEMENTS);
                  }}
                >
                  <Text fontWeight="bold" fontSize={["10px", "10px", "15px"]}>
                    <span style={blinkingStyle}>
                      <span style={colorChangeStyle}>
                        New advertisement posted
                      </span>
                    </span>
                  </Text>
                </Pressable>
              </Box>
            </HStack>
          </Box> */}
          <Box width={"50%"} justifyContent={"center"}>
            <HStack alignItems="center" justifyContent={"center"}>
              {/* <Box>
              <Image
                src={Software}
                width="100px"
                height="50px"
                alt="Software Logo"
              />
            </Box> */}
              <Box>
                <Pressable
                  _hover={{}}
                  onPress={() => {
                    moveToPage(ROUTES.CALENDAR);
                  }}
                >
                  <Text fontWeight="bold" fontSize={["10px", "10px", "15px"]}>
                    <span style={blinkingStyle}>
                      <span style={colorChangeStyle}>
                        Calendar 2024 - Elephant Whisperers
                      </span>
                    </span>
                  </Text>
                </Pressable>
              </Box>
            </HStack>
          </Box>
        </div>
      </Hidden>
      <Hidden Hidden only={["xl", "md", "lg"]}>
        <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "60px",
          backgroundColor: "white",
          width: "100%",
          padding: "5px",
        }}
        >
          <VStack space={3}>
            <Box marginRight={"30px"}>
              {/* <HStack alignItems="center" justifyContent={"center"}>
                <Box >
                  <Image
                    src={Software}
                    width="100px"
                    height="20px"
                    alt="Software Logo"
                  />
                </Box>
                <Box>
                  <Pressable
                    _hover={{}}
                    onPress={() => {
                      moveToPage(ROUTES.TENDERS_ADVERTISEMENTS);
                    }}
                  >
                    <Text fontWeight="bold">
                      <span style={blinkingStyle}>
                        <span style={colorChangeStyle}>
                          New advertisement posted
                        </span>
                      </span>
                    </Text>
                  </Pressable>
                </Box>
              </HStack> */}
            </Box>
            <Box justifyContent={"center"}>
              <HStack alignItems="center" justifyContent={"center"}>
                {/* <Box>
          <Image
            src={Software}
            width="100px"
            height="50px"
            alt="Software Logo"
          />
        </Box> */}
                <Box>
                  <Pressable
                    _hover={{}}
                    onPress={() => {
                      moveToPage(ROUTES.CALENDAR);
                    }}
                  >
                    <Text fontWeight="bold">
                      <span style={blinkingStyle}>
                        <span style={colorChangeStyle}>
                          Calendar 2024 - Elephant Whisperers
                        </span>
                      </span>
                    </Text>
                  </Pressable>
                </Box>
              </HStack>
            </Box>
          </VStack>
        </div>
      </Hidden>
    </div>
  );
};
export default HomeReactPlayer;
