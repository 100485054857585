import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { FaFileWord } from "react-icons/fa";
import { AiOutlineFilePdf } from "react-icons/ai";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@views/routes/my_routes";
import { useStorageItem } from "@helpers/storage";
import { AiFillEdit } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import {
  getSystemCMSFieldUserListSelector,
  get_system_cms_user_field_request,
} from "@services/redux";

const ActsTable = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Photo, roles, name } = useStorageItem("user");
  const { system_cms_id} = useSelector(getSystemCMSFieldUserListSelector);
  const history = useHistory();
  let current_language_id = localStorage.getItem("current_language_id");
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({});
  const [loading, setLoading] = useState(false);
  const { items } = useSelector(getSystemCMSFieldUserListSelector);
  const handlePdf = (item, key) => {
    var a = document.createElement("a");
    var filePath = eval(key);
    a.href = filePath;
    a.target = "_blank";
    a.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const columns = [
    {
      header: t("table:name"),
      dataIndex: "Name.value",
      key: "Name",
      align: "left",
      type: "label",
      fleX: 1,
      width: 800,
    },
    {
      header: t("table:pdf"),
      dataIndex: "record?.['pdf']?.['value']",
      key: "actions",
      type: "actions",
      align: "center",
      type: "actions",
      width: 100,
      actions: [
        {
          icon: <AiOutlineFilePdf />,
          iconSize: 30,
          iconColor: "red",
          onPress: (item) => {
            handlePdf(item, "item['pdf']['value']");
          },
        },
      ],
    },
    {
      header: t("table:pdf"),
      dataIndex: "record?.['pdf']?.['value']",
      key: "actions",
      type: "actions",
      align: "center",
      type: "actions",
      width: 100,
      actions: [
        {
          icon: <FaFileWord />,
          iconSize: 30,
          iconColor: "#3EA0E6",
          onPress: (item) => {
            handlePdf(item, "item['pdf']['value']");
          },
        },
      ],
    },
  ];
  useEffect(() => {
    if (items.length) {
      let cms = items.find((e) => e.key === "acts");
      if (cms?.field_items?.length) {
        let items = cms.field_items;

        let highest_value = 0;
        let object_keys = items.map((field_item) => {
          highest_value =
            field_item.system_cms_values?.length > highest_value
              ? field_item.system_cms_values?.length
              : highest_value;
          return field_item.key;
        });
        let final_list = [];
        for (let index = 0; index < highest_value; index++) {
          let object = {};
          for (let key in object_keys) {
            let value = items
              .find((filter_item) => filter_item.key === object_keys[key])
              .system_cms_values.find(
                (find_item) => find_item.row_number === index
              );
            object[object_keys[key]] = value;
          }
          final_list.push(object);
        }
        setData(final_list);
      }
      if (cms?.loading) setLoading(cms.loading);
    }
  }, [items]);
  useEffect(() => {
    setQuery({
      json: {
        system_cms_key: "acts",
      },
    });
  }, []);
  return (
    <>
     <Box>
      {name ? (
        <Pressable
          _hover={{
            borderColor: "red.500",
            borderWidth: "6px",
          }}
        >
          <Box
            alignItems={"flex-end"}
            onClick={(e) => {
              history.push({
                pathname: `${ROUTES.SYSTEM_CMS_VALUE}/${system_cms_id}`,
                state: { cms: props.cmsKey },
              });
            }}
          >
            <AiFillEdit size={30} color="#409d06" />
          </Box>
          <Wrapper>
          <Table
            width="100%"
            rowKey="id"
            group="acts"
            loading={loading}
            data={data}
            columns={columns}
            hasPagination={false}
            totalItems={null}
            fetch={get_system_cms_user_field_request}
            query={query}
            headerActions={[]}
            emptyMessage={t("table:empty_page")}
            headerColor={"white"}
          />
          </Wrapper>
        </Pressable>
      ) : (
        <Wrapper>
          <Table
            width="100%"
            rowKey="id"
            group="acts"
            loading={loading}
            data={data}
            columns={columns}
            hasPagination={false}
            totalItems={null}
            fetch={get_system_cms_user_field_request}
            query={query}
            headerActions={[]}
            emptyMessage={t("table:empty_page")}
            headerColor={"white"}
          />
        </Wrapper>
      )}
    </Box>
    </>
  );
};
export default ActsTable;
