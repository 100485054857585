import {
  CloseCircleOutlined,
  DeleteOutlined,
  FormOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { showToast } from "@helpers/toast";
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Upload,
} from "antd";
import JoditEditor from "jodit-react";
import React, { useState } from "react";
import produce from "immer";
import axios from "axios";
import { create_system_cms_value } from "@services/redux";
import { useDispatch } from "react-redux";
import { findIndex } from "lodash";
import { useEffect } from "react";
import moment from "moment";
import { QueryRequest } from "@services/apollo/api_service";
import { fileQuery } from "@services/redux/slices/cms/graphql";

const DynamicForm = (props) => {
  const {
    Form,
    cms_field: fields,
    setData,
    data,
    normFile,
    setIsEditing,
    isEditing,
    fileList,
    setFileList,
    headers,
    setHeaders,
    setIndex,
    index: rowIndex,
    handleSubmit,
    language,
    onDelete,
    onSubmit,
  } = props;
  const { TextArea } = Input;
  const { Option } = Select;
  let children = [];

  const getRender = (item, rowIndex, isEditing, field_index) => {
    if (item?.type === "number") {
      return (text, record, index) => {
        if (isEditing && index === rowIndex) {
          return (
            <Col>
              <Form.Item name={[`record.${item.key}`, index]}>
                <InputNumber
                  value={Number(record[item.key]?.value)}
                  defaultValue={Number(record[item.key]?.value)}
                  onChange={(e) => {
                    let row_data = data;
                    row_data[item.key] = {
                      system_cms_field_id: item.id,
                      system_cms_language_id: language,
                      value: e,
                      row_number: index,
                      id: row_data?.[rowIndex]?.[item.key]?.id || undefined,
                    };
                    setData(row_data);
                  }}
                />
              </Form.Item>
            </Col>
          );
        } else {
          return <Col>{Number(record[item.key]?.value)}</Col>;
        }
      };
    } else if (item.type === "text") {
      return (text, record, index) => {
        if (isEditing && index === rowIndex) {
          return (
            <Col>
              <Form.Item name={[`record.${item.key}`, index]}>
                <Input
                  value={record[item.key]?.value}
                  defaultValue={record[item.key]?.value}
                  onChange={(e) => {
                    let row_data = data;
                    row_data[rowIndex][item.key] = {
                      system_cms_field_id: item.id,
                      system_cms_language_id: language,
                      value: e.target.value,
                      row_number: index,
                      id: row_data?.[rowIndex]?.[item.key]?.id || undefined,
                    };
                    setData(row_data);
                  }}
                />
              </Form.Item>
            </Col>
          );
        } else {
          return <Col>{record[item.key]?.value}</Col>;
        }
      };
    } else if (item.type === "textarea") {
      return (text, record, index) => {
        if (isEditing && index === rowIndex) {
          return (
            <Col>
              <Form.Item name={[`record.${item.key}`, index]}>
                <TextArea
                  defaultValue={record[item.key]?.value}
                  onChange={(e) => {
                    let row_data = data;
                    row_data[rowIndex][item.key] = {
                      system_cms_field_id: item.id,
                      system_cms_language_id: language,
                      value: e.target.value,
                      row_number: index,
                      id: row_data?.[rowIndex]?.[item.key]?.id || undefined,
                    };
                    setData(row_data);
                  }}
                />
              </Form.Item>
            </Col>
          );
        } else {
          return <Col>{record[item.key]?.value}</Col>;
        }
      };
    } else if (item.type === "file") {
      let final_value;
      let fileUploadresponse = "";
      const handleBeforeUpload = async ({ name, type }) => {
        let fileupload = {
          type: "photo",
          fileName: name,
        };
        fileUploadresponse = await QueryRequest(fileQuery, fileupload);
      };
      const handleFileChanged = ({ file }) => {
        if (file.status === "removed") {
          setFileList([]);
        } else if (file.status === "uploading") {
          setFileList([file]);
        } else if (file.status === "done") {
          const newFile = {
            ...file,
            url: fileUploadresponse?.data?.getUploadUrl?.url,
          };
          setFileList([newFile]);
          showToast({
            type: "success",
            message: "Image updated successfully",
          });
          return file.url;
        }
      };
      const updateData = (url, index, item) => {
        let row_data = data;
        row_data[rowIndex][item.key] = {
          system_cms_field_id: item.id,
          system_cms_language_id: language,
          value: url,
          row_number: index,
          id: row_data?.[rowIndex]?.[item.key]?.id || undefined,
        };
        setData(row_data);
      };
      const handleUpload = ({ onSuccess, onError, file }, index, item) => {
        axios
          .put(fileUploadresponse?.data?.getUploadUrl?.url, file, {
            headers: { "Content-Type": file?.type },
          })
          .then((res) => {
            if (res.status.toString() === "200") {
              file.url =
                fileUploadresponse?.data?.getUploadUrl?.url.split("?")[0];
              final_value = file.url;
              updateData(file.url, index, item);
              onSuccess(null, file);
            } else {
              onError(err, err, file);
            }
          })
          .catch((err) => {
            onError(err, err, file);
          });
      };
      const action = fileUploadresponse?.data?.getUploadUrl;

      return (text, record, index) => {
        if (isEditing && index === rowIndex) {
          return (
            <Col>
              <Form.Item
                name={[`record.${item.key}`, index]}
                initialValue={[
                  {
                    uid: "-3",
                    name: record[item.key]?.value,
                    status: "done",
                    url: record[item.key]?.value,
                  },
                ]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra=""
              >
                <Upload
                  maxCount={1}
                  action={action}
                  value={record[item.key]?.value}
                  defaultValue={record[item.key]?.value}
                  headers={headers}
                  fileList={fileList}
                  customRequest={(e) => handleUpload(e, index, item)}
                  beforeUpload={(args) => handleBeforeUpload(args)}
                  onChange={(e) => {
                    handleFileChanged(e);
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          );
        } else {
          return (
            <Col>
              <Upload
                disabled
                maxCount={1}
                action={action}
                headers={headers}
                fileList={[
                  {
                    uid: "-3",
                    name: record[item.key]?.value,
                    status: "done",
                    url: record[item.key]?.value,
                  },
                ]}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Col>
          );
        }
      };
    } else if (item.type === "dropdown") {
      console.table("Tables", item.system_cms_field_options);
      return (text, record, index) => {
        if (isEditing && index === rowIndex) {
          return (
            <Col>
              <Form.Item name={[`record.${item.key}`, index]}>
                <Select
                  value={record[item.key]?.value}
                  defaultValue={record[item.key]?.value}
                  onChange={(e) => {
                    let row_data = data;
                    row_data[rowIndex][item.key] = {
                      system_cms_field_id: item.id,
                      system_cms_language_id: language,
                      system_cms_field_option_value_id: e,
                      value: e,
                      row_number: index,
                      id: row_data?.[rowIndex]?.[item.key]?.id || undefined,
                    };
                    setData(row_data);
                  }}
                >
                  {item.system_cms_field_options?.map((item) => {
                    let option = item.system_cms_field_option_values.find(
                      (option_item) =>
                        option_item.system_cms_language.id === language
                    );
                    return <Option value={option.id}>{option.value}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
          );
        } else {
          return <Col>{record[item.key]?.value}</Col>;
        }
      };
    } else if (item.type === "datetime") {
      return (text, record, index) => {
        if (isEditing && index === rowIndex) {
          return (
            <Col>
              <Form.Item name={[`record.${item.key}`, index]}>
                <DatePicker
                  defaultValue={
                    record[item.key]?.value
                      ? moment(new Date(record[item.key]?.value), "DD/MM/YYYY")
                      : moment(new Date(), "DD/MM/YYYY")
                  }
                  format={"DD/MM/YYYY"}
                  onChange={(e) => {
                    let row_data = data;
                    row_data[rowIndex][item.key] = {
                      system_cms_field_id: item.id,
                      system_cms_language_id: language,
                      value: e,
                      row_number: index,
                      id: row_data?.[rowIndex]?.[item.key]?.id || undefined,
                    };
                    setData(row_data);
                  }}
                />
              </Form.Item>
            </Col>
          );
        } else {
          return (
            <Col>
              <DatePicker
                disabled
                format={"DD/MM/YYYY"}
                defaultValue={
                  record[item.key]?.value
                    ? moment(new Date(record[item.key]?.value), "DD/MM/YYYY")
                    : moment(new Date(), "DD/MM/YYYY")
                }
              />
            </Col>
          );
        }
      };
    }
  };
  fields?.map((item) => {
    let rchilder = getRender(item, rowIndex, isEditing);
    children.push({
      title: item?.system_cms_field_names?.find(
        (item) =>
          item?.system_cms_language_id ===
          "e0d9e2a1-4d8a-4e1c-a623-5a558954893d"
      )?.name,
      dataIndex: item?.key,
      sorter: false,
      // width: 150,
      editable: true,
      render: rchilder,
    });
  });
  children.push({
    title: "Action",
    width: 100,
    fixed: "right",
    render: (text, record, index) => {
      if (!isEditing) {
        return (
          <Row>
            <Col span={12}>
              <Button
                shape="circle"
                icon={<FormOutlined />}
                size={"large"}
                onClick={() => {
                  setIndex(index);
                  setIsEditing(!isEditing);
                }}
              />
            </Col>
            <Col span={12}>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => {
                  onDelete(index, record);
                }}
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size={"large"}
                />
              </Popconfirm>
            </Col>
          </Row>
        );
      } else if (isEditing && rowIndex === index) {
        return (
          <Row>
            <Col span={12}>
              <Button
                shape="circle"
                icon={<SaveOutlined spin={false} />}
                size={"large"}
                onClick={() => {
                  handleSubmit(index, record);
                }}
              />
            </Col>
            <Col span={12}>
              <Button
                shape="circle"
                icon={<CloseCircleOutlined />}
                size={"large"}
                onClick={() => {
                  setIsEditing(!isEditing);
                }}
              />
            </Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col span={12}>
              <Button
                shape="circle"
                icon={<FormOutlined />}
                size={"large"}
                onClick={() => {
                  setIndex(index);
                  setIsEditing(!isEditing);
                }}
              />
            </Col>
            <Col span={12}>
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                size={"large"}
                // onClick={() => {}}
              />
            </Col>
          </Row>
        );
      }
    },
  });
  return children;
};
export default DynamicForm;
