import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, VStack, HStack, Center, Pressable, Text} from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import {useTranslation} from "react-i18next";
import TnStateProfileHTML from "./components/tn_state_profile"
import FunctioningPatternOfOtherUnitsTable from "./components/functioning_pattern_of_other_units"
import AbstractTable from "./components/abstract"
import AdministrativeSetupCardList from "./components/administrative_setup_card_list"
import AnnualPlanOfOperationCardList from "./components/annual_plan_of_operation_card_list"


const Test = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    return (<>
        <ScrollView>
    <Box><TnStateProfileHTML/></Box>
    <Box><FunctioningPatternOfOtherUnitsTable/></Box><Box><AbstractTable/></Box>
    <Box><AdministrativeSetupCardList/></Box><Box><AnnualPlanOfOperationCardList/></Box>
    </ScrollView>
    </>);
};
export default Test;

