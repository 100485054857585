import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  Card,
} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Loader from "@views/components/ui/loader";
import NoData from "@views/components/ui/no_data";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";
import {
  getSystemCMSFieldUserListSelector,
  get_system_cms_user_field_request,
} from "@services/redux";
import TriaCardItem from "./tria_card_item";

const Tria = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let current_language_id = localStorage.getItem("current_language_id");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { items } = useSelector(getSystemCMSFieldUserListSelector);
  useEffect(() => {
    if (items.length) {
      let cms = items.find(
        (e) => e.key === "tiger_reserves_important_authority_and_trust"
      );
      if (cms?.field_items?.length) {
        let items = cms.field_items;

        let highest_value = 0;
        let object_keys = items.map((field_item) => {
          highest_value =
            field_item.system_cms_values?.length > highest_value
              ? field_item.system_cms_values?.length
              : highest_value;
          return field_item.key;
        });
        let final_list = [];
        for (let index = 0; index < highest_value; index++) {
          let object = {};
          for (let key in object_keys) {
            let value = items
              .find((filter_item) => filter_item.key === object_keys[key])
              .system_cms_values.find(
                (find_item) => find_item.row_number === index
              );
            object[object_keys[key]] = value;
          }
          final_list.push(object);
        }
        setData(final_list);
      }
      if (cms?.loading) setLoading(cms.loading);
    }
  }, [items]);
  useEffect(() => {
    dispatch(
      get_system_cms_user_field_request({
        json: {
          system_cms_key: "tiger_reserves_important_authority_and_trust",
        },
      })
    );
  }, []);

  return (
    <Box>
      <VStack space={10}>
        {data.map((item) => (
          <Box>
            <TriaCardItem item={item} />
          </Box>
        ))}
      </VStack>
    </Box>
  );
};
export default Tria;
