import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, useBreakpointValue } from "native-base";
import { Form, Col, Row } from "antd";
import VForm from "@views/components/ui/antd_form";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  getCirclesQuery,
  getEdafListSelector,
  get_edaf,
  useDynamicSelector,
} from "@services/redux";
import moment from "moment";

const EdafTable = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const { selectedYear, selectedMonth } = props;
  const [query, setQuery] = useState({});
  const [divisions, setDivisions] = useState([]);
  const [ranges, setRanges] = useState([]);
  const { items, loading } = useSelector(getEdafListSelector);
  const { items: circles } = useDynamicSelector("get_all_circle");
  const is_mobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  });
  const handlePdf = (item, key) => {
    var a = document.createElement("a");
    var filePath = eval(key);
    a.href = filePath;
    a.target = "_blank";
    a.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const formatDisplay = (date) => {
    return moment(new Date(Number(date))).format("DD/MM/YYYY");
  };
  const getAllCircles = () => {
    let keys = [{ key: "get_all_circle" }];
    dispatch(dynamicRequest(keys, getCirclesQuery));
  };
  const formatCircle = (value) => {
    return (
      <>
        {value.circle.name},
        <br />
        {value.division.name},
        <br />
        {value.range.name}
      </>
    );
  };
  const columns = [
    {
      header: t("table:slno"),
      dataIndex: "slno",
      key: "slno",
      align: "left",
      type: "label",
      flex: 1,
    },
    {
      header: t("table:circle"),
      dataIndex: "circle.name",
      key: "circle",
      align: "left",
      type: "label",
      flex: 1,
    },
    {
      header: t("table:division"),
      dataIndex: "division.name",
      key: "circle",
      align: "left",
      type: "label",
      flex: 1,
    },
    {
      header: t("table:range"),
      dataIndex: "range.name",
      key: "range",
      align: "left",
      type: "label",
      flex: 1,
    },
    {
      header: t("table:date"),
      dataIndex: "edaf_date",
      key: "edaf_date",
      align: "left",
      type: "custom",
      formatDisplay: formatDisplay,
      flex: 1,
    },
    {
      header: t("table:pdf"),
      dataIndex: "record['pdf']",
      key: "actions",
      type: "actions",
      align: "center",
      type: "actions",
      width: 100,
      actions: [
        {
          icon: "pdf.value" && <AiOutlineFilePdf />,
          iconSize: 30,
          iconColor: "red",
          onPress: (item) => {
            handlePdf(item, "item['pdf']");
          },
        },
      ],
    },
  ];
  const mobile_columns = [
    {
      header: t("table:circle_division_range"),
      dataIndex: "",
      key: "circle",
      align: "left",
      type: "custom",
      formatDisplay: formatCircle,
      width: "50%",
    },
    {
      header: t("table:date"),
      dataIndex: "edaf_date",
      key: "edaf_date",
      align: "left",
      type: "custom",
      formatDisplay: formatDisplay,
      flex: 1,
    },
    {
      header: t("table:pdf"),
      dataIndex: "record['pdf']",
      key: "actions",
      type: "actions",
      align: "center",
      type: "actions",
      width: "20%",
      actions: [
        {
          icon: "pdf.value" && <AiOutlineFilePdf />,
          iconSize: 30,
          iconColor: "red",
          onPress: (item) => {
            handlePdf(item, "item['pdf']");
          },
        },
      ],
    },
  ];
  useEffect(() => {
    getAllCircles();
  }, []);
  useEffect(() => {
    if (items) {
      setData(items.map((x, i) => ({ ...x, slno: i + 1 })));
    }
  }, [items]);
  useEffect(() => {
    if (selectedYear) {
      let temp = { year: selectedYear };
      if (selectedMonth) temp.month = selectedMonth;
      setQuery(temp);
    }
  }, [selectedYear, selectedMonth]);

  const onValueChange = (value) => {
    if (value.circle_id) {
      setDivisions(circles.find((x) => x.id === value.circle_id)?.areas);
      setRanges([]);
      form.setFieldsValue({ division_id: "", range_id: "" });
      setQuery({
        ...query,
        circle_id: value.circle_id,
        division_id: "",
        range_id: "",
      });
    }
    if (value.division_id) {
      setRanges(divisions.find((x) => x.id === value.division_id)?.areas);
      form.setFieldsValue({ range_id: "" });
      setQuery({
        ...query,
        division_id: value.division_id,
        range_id: "",
      });
    }
    if (value.range_id) {
      setQuery({
        ...query,
        range_id: value.range_id,
      });
    }
  };
  return (
    <>
      <Box>
        <Form
          form={form}
          name={t("edit_edaf")}
          layout={"vertical"}
          onValuesChange={onValueChange}
        >
          <Box p="10px">
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <VForm.Select
                  label={t("form:circle")}
                  field={"circle_id"}
                  options={circles}
                />
              </Col>
              <Col xs={24} md={8}>
                <VForm.Select
                  label={t("form:division")}
                  field={"division_id"}
                  options={divisions}
                />
              </Col>
              <Col xs={24} md={8}>
                <VForm.Select
                  label={t("form:range")}
                  field={"range_id"}
                  options={ranges}
                />
              </Col>
            </Row>
          </Box>
        </Form>
        <Wrapper>
          <Table
            width="100%"
            rowKey="id"
            group="edaf"
            loading={loading}
            data={data}
            columns={is_mobile ? mobile_columns : columns}
            hasPagination={false}
            totalItems={null}
            fetch={query.circle_id || query.year ? get_edaf : ""}
            query={query}
            headerActions={[]}
            emptyMessage={
              query.circle_id || query.year
                ? t("table:no_data")
                : t("table:please_select_year_or_circle")
            }
            headerColor={"white"}
          />
        </Wrapper>
      </Box>
    </>
  );
};
export default EdafTable;
