import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  ZStack,
} from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import GovernmentOrdersRelatedToStateCampaTable from "./components/government_orders_related_to_state_campa";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";

const Circles = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Box>
      <Text>Government Orders related to STATE CAMPA</Text>
      <GovernmentOrdersRelatedToStateCampaTable />
    </Box>
  );
};
export default Circles;
