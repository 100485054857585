import produce from "immer";
import { Button, Form, Input, Select, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Center, HStack, Text, VStack } from "native-base";
import DynamicForm from "./dynamic_form";
import { useDispatch, useSelector } from "react-redux";
import {
  createsystemCMSValueSelector,
  create_one_system_cms_value_clear,
  create_system_cms_value,
  deletesystemCMSValueSelector,
  delete_one_system_cms_value_modal,
  delete_system_cms_value,
  getcmsSelector,
  getsystemCMSFieldListSelector,
  get_one_cms,
  get_system_cms_field,
} from "@services/redux";
import { startCase } from "lodash";
import HTMLForm from "./html_form";
import { useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
const SystemCMSContentValue = () => {
  const { id } = useParams();
  const { item } = useSelector(getcmsSelector);
  const { Option } = Select;
  const [columnChildren, setColumnChildren] = useState([]);
  const { status: cmsValueCreate, error: cmsValueErrorCreate } = useSelector(
    createsystemCMSValueSelector
  );
  const { status: cmsValueDelete, error: cmsValueErrorDelete } = useSelector(
    deletesystemCMSValueSelector
  );
  const dispatch = useDispatch();
  let cms_field = item?.system_cms_fields;
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const [htmlForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [isAdded, setIsAdded] = useState(false);
  const [dnata, setDnata] = useState([]);
  const [htmlValue, setHtmlValue] = useState();
  const [index, setIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [headers, setHeaders] = useState();
  const [language, setLanguage] = useState(
    "e0d9e2a1-4d8a-4e1c-a623-5a558954893d"
  );
  const language_options = [
    {
      id: "e0d9e2a1-4d8a-4e1c-a623-5a558954893d",
      key: "EN",
    },
    {
      id: "ab5a2300-a29c-442f-84d0-8b1bd4174500",
      key: "TN",
    },
  ];
  let system_cms_field_id = item?.system_cms_fields?.find(
    (field) => field.key === "code"
  )?.id;
  const { items } = useSelector(getsystemCMSFieldListSelector);
  useEffect(() => {
    dispatch(get_one_cms({ json: { id: id } }));
  }, [id]);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const onFinish = (formData) => {
    let obj = {};
    cms_field.map((item) => {
      obj[item.key] = "";
    });
    let raw_data = [...data];
    raw_data[data.length] = obj;
    setData(raw_data);
    setIsAdded(true);
    form.resetFields();
  };
  const onSubmit = (value) => {
    tableForm.validateFields().then(() => {});
  };
  const handleSubmit = (ind, _records) => {
    let record = data.find((_item, index) => index === ind);
    setIsEditing(!isEditing);
    let final_record = Object.values(record);
    final_record = final_record.map((x) => {
      return { ...x, value: x?.value?.toString() || "" };
    });
    dispatch(create_system_cms_value({ json: { data: final_record } }));
  };
  const onDelete = (index, record) => {
    dispatch(
      delete_system_cms_value({
        json: { system_cms_id: item.id, row_number: index },
      })
    );
  };
  function callDynamicalForm() {
    let children = DynamicForm({
      Form,
      cms_field,
      setData,
      data: data,
      normFile,
      setIsEditing,
      isEditing: isEditing,
      fileList: fileList,
      setFileList,
      headers: headers,
      setHeaders,
      setIndex,
      index: index,
      handleSubmit,
      language: language,
      onDelete,
    });
    setColumnChildren(children);
  }
  const onFinishHtml = (values) => {
    let html_data =
      items
        ?.find((field) => field.key === "code")
        ?.system_cms_values.find(
          (system_cms_item) =>
            system_cms_item.system_cms_language_id === language
        ) || {};
    dispatch(
      create_system_cms_value({
        json: {
          data: {
            system_cms_field_id: system_cms_field_id,
            system_cms_language_id: language,
            value: values.value,
            row_number: 1,
            id: html_data.id || undefined,
          },
        },
      })
    );
  };
  useEffect(() => {
    if (item) {
      callDynamicalForm();
      dispatch(get_system_cms_field({ json: { system_cms_id: item?.id } }));
    }
  }, [item, isEditing, fileList, headers, index, language]);
  useEffect(() => {
    if (cmsValueCreate === "Success") {
      setIsAdded(false);
      dispatch(create_one_system_cms_value_clear());
      showToast({
        type: "success",
        message: "CMS value added successfully",
      });
      dispatch(get_system_cms_field({ json: { system_cms_id: item?.id } }));
    } else if (cmsValueCreate === "Failure") {
      setIsAdded(false);
      dispatch(create_one_system_cms_value_clear());
      showToast({
        type: "error",
        message: cmsValueErrorCreate,
      });
    } else if (cmsValueDelete === "Success") {
      setIsAdded(false);
      dispatch(delete_one_system_cms_value_modal());
      showToast({
        type: "success",
        message: "CMS value deleted successfully",
      });
      dispatch(get_system_cms_field({ json: { system_cms_id: item?.id } }));
    } else if (cmsValueDelete === "Failure") {
      setIsAdded(false);
      dispatch(create_one_system_cms_value_clear());
      showToast({
        type: "error",
        message: cmsValueErrorDelete,
      });
    }
  }, [cmsValueCreate, cmsValueDelete]);
  useEffect(() => {
    if (!isAdded && items) {
      let filtered_items = items.map((item) => {
        return {
          ...item,
          system_cms_values: item.system_cms_values.filter(
            (field_item_value) =>
              field_item_value.system_cms_language_id === language
          ),
        };
      });
      let highest_value = 0;
      let object_keys = filtered_items.map((field_item) => {
        highest_value =
          field_item.system_cms_values?.length > highest_value
            ? field_item.system_cms_values?.length
            : highest_value;
        return field_item.key;
      });
      let final_list = [];
      for (let index = 0; index < highest_value; index++) {
        let object = {};
        for (let key in object_keys) {
          let value = items
            .find((filter_item) => filter_item.key === object_keys[key])
            .system_cms_values.find(
              (find_item) =>
                find_item.row_number === index &&
                find_item.system_cms_language_id === language
            );
          if (!value) {
            let empty_value = items.find(
              (filter_item) => filter_item.key === object_keys[key]
            );
            value = {
              value: "",
              row_number: index,
              system_cms_field_id: empty_value.id,
              system_cms_language_id: language,
            };
          }
          object[object_keys[key]] = value;
        }
        final_list.push(object);
      }
      setData(final_list);
    }
  }, [items]);
  useEffect(() => {
    htmlFormValue();
  }, [dnata]);
  useEffect(() => {
    if (item) {
      listFormValue();
    }
  }, [data]);

  const htmlFormValue = () => {
    return (
      <HTMLForm
        htmlForm={htmlForm}
        items={items}
        onFinishHtml={onFinishHtml}
        language={language}
      />
    );
  };
  const listFormValue = () => {
    return (
      <>
        <Form name={`${item.key}-add`} form={form} onFinish={onFinish}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right", margin: "10px" }}
          >
            Add
          </Button>
        </Form>
        <Form
          name={item.key}
          form={tableForm}
          initialValues={data}
          onFinish={onSubmit}
        >
          <Table
            key={item.key}
            dataSource={data}
            columns={columnChildren}
            bordered
            childrenColumnName="antdChildren"
            pagination={false}
            scroll={{
              x: 1500,
            }}
          />
        </Form>
      </>
    );
  };
  return (
    <VStack space={item?.type === "list" ? 0 : 20}>
      <HStack space={10}>
        <Box>
          <Center>
            <Text bold fontSize={28}>
              {`${startCase(
                item?.system_cms_names.find(
                  (field_item) => field_item.system_cms_language_id === language
                )?.name
              )} ${startCase(item?.type)}`}
            </Text>
          </Center>
        </Box>
        <Box width={200}>
          <Center height={"100%"} width={200}>
            <Select
              style={{ width: "100%" }}
              onChange={(e) => {
                setLanguage(e);
              }}
              defaultValue="e0d9e2a1-4d8a-4e1c-a623-5a558954893d"
            >
              {language_options?.map((item, index) => {
                return (
                  <Option key={index} value={item.id}>
                    {item.key}
                  </Option>
                );
              })}
            </Select>
          </Center>
        </Box>
      </HStack>
      {item?.type === "list" && listFormValue()}
      {item?.type === "html" && htmlFormValue()}
    </VStack>
  );
};
export default SystemCMSContentValue;
