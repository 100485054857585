import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";


const ButterflyAnimation = () => {

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }
useEffect(()=>{
  var butterfly = document.querySelector(".butterfly");
  setInterval(function () {
    var numberX = Math.floor(getRandomArbitrary(0, 1200));
    var numberY = Math.floor(getRandomArbitrary(0, 500));

    butterfly.style.setProperty("--random-y", numberY + "px");
    butterfly.style.setProperty("--random-x", numberX + "px");
  }, 8000);
})
 

  return (
<div class="canvas">
<div class="butterfly">
  <div class="butterfly-wing-1"></div>
  <div class="butterfly-body"></div>
  <div class="butterfly-wing-2"></div>
  </div>
</div>

  );
};
export default ButterflyAnimation;
