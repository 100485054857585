import React, { useEffect, useState } from "react";
import { Box, Center, Divider, HStack, Text } from "native-base";
import { useHistory } from "react-router-dom";
import { startCase } from "lodash";
const SecondarySiderMenuItem = (props) => {
  const { item, index, selected_index, handleSelectedIndex } = props;
  const [isHovered, setIsHovered] = useState(false);
  const is_selected = selected_index === index;
  const history = useHistory();
  const moveToPath = (route) => {
    history.push({
      pathname: `${route}`,
    });
  };
  // useEffect(()=>{

  // },[is_selected])
  console.log("is_selected", is_selected,index);
  return (
    <Box>
      <Box
        pl="3"
        minH="37px"
        justifyContent="center"
        key={index}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={() => {
          moveToPath(item.route);
          handleSelectedIndex(index);
        }}
      >
        <HStack>
          <Divider
            bg="#3a6a01"
            thickness="2"
            mx="2"
            orientation="vertical"
            display={is_selected || isHovered ? "block" : "none"}
          />
          <Box width="200px">
            <Text
              cursor="pointer"
              fontSize="15px"
              style={{
                flexWrap: 'wrap'
              }}
              fontWeight={is_selected || isHovered ? 600 : 300}
              pl={is_selected || isHovered ? "3" : ""}
              color={is_selected || isHovered ? "#3a6a01" : "#283619"}
            >
              {startCase(item.name)}
            </Text>
          </Box>
        </HStack>
      </Box>
      <Center>
        <Divider width={"90%"} bg={"#d1e1c7"} />
      </Center>
    </Box>
  );
};

export default SecondarySiderMenuItem;
