import React, { useEffect, useState } from "react";
import HTMLRenderer from "@views/components/ui/html_renderer";
import {
  Box,
  Center,
  HStack,
  Pressable,
  Text,
  VStack,
  Button,
  Hidden,
} from "native-base";
import { AiFillEdit } from "react-icons/ai";
import ReactSelect from "react-select";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import {} from "@services/redux";
import ReactPlayer from "react-player/lazy";
import ScrollView from "@views/components/ui/scroll_view";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import MapContainer from "@views/components/ui/map_container";
import ForestGlance from "./components/forest_glance/forest_glance";
import About from "./components/about_cms/about_page";
import Newsfeed from "./components/about_cms/components/newsfeed";
import GalleryTab from "./components/media_&_gallery/gallery_tabs";
import UsefulLinksTab from "./components/useful_links/useful_links_tabs";
import HomeReactPlayer from "./components/react_player/react_player";
import { MdOutlinePhoneInTalk } from "react-icons/md";

import { useStorageItem } from "@helpers/storage";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import { color } from "styled-system";
import { Linking } from "react-native-web";

const HomeDesign = (props) => {
  const {} = props;
  const history = useHistory();
  const moveToPage = (routes) => {
    history.push({
      pathname: `${routes}`,
    });
  };
  const { Photo, roles, name } = useStorageItem("user");
  const MapWithAMarker = GoogleApiWrapper({
    apiKey: "AIzaSyCVY8hRJ-X4VVYVuDECrRQynxwvuk6Kgow",
  })(MapContainer);
  const { t } = useTranslation();
  const onMarkerClick = (props, marker) => {};
  // useEffect(() => {
  //   localStorage.setItem("currentLanguage", "en");
  //   localStorage.setItem("current_language", [
  //     { id: "e0d9e2a1-4d8a-4e1c-a623-5a558954893d", key: "en", name: "தமிழ்" },
  //   ]);
  // }, []);
  return (
    <Box flex={"1"} width={["100%"]}>
      <VStack>
        <Box width={"100vw"} background="#f6f6e9">
          <HomeReactPlayer />
          <Hidden only={["xl", "md", "lg"]}>
            <Box
              alignItems="flex-end"
              mt={["-200px", "-200px", "0px"]}
              marginBottom={50}
            >
              <VStack>
                <Box
                  borderRadius="10px"
                  backgroundColor="rgba(255, 255, 255, 0.1)"
                  width="150px"
                  height={"50px"}
                  justifyContent="center"
                >
                  <HStack space={5} justifyContent="center">
                    <Box
                      width="30px"
                      height="30px"
                      justifyContent="center"
                      style={{ borderRadius: "50%", background: "#6d6d6887" }}
                    >
                      <MdOutlinePhoneInTalk
                        size="20"
                        color="white"
                        style={{ width: "90%" }}
                      />
                    </Box>
                    <Box width="60%">
                      <VStack space={2}>
                        <Text color="white" fontSize={8}>
                          {t("24-x-7-helpline")}
                        </Text>
                        <Text bold color="white" fontSize={8}>
                          <a
                            href="tel:1800 425 4509"
                            style={{
                              color: "white",
                            }}
                          >
                            1800 425 4509
                          </a>
                        </Text>
                      </VStack>
                    </Box>
                  </HStack>
                </Box>
                <Box
                  borderRadius="10px"
                  backgroundColor="rgba(255, 255, 255, 0.1)"
                  width="150px"
                  marginTop={5}
                >
                  <VStack px={3} py={3}>
                    <Text bold color="white" cursor="pointer" fontSize={8}>
                      {t("news-Updates")}
                    </Text>
                    <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        moveToPage(ROUTES.RECENT_G_OS);
                      }}
                      fontSize={8}
                    >
                      Recent G.O.s - Admin
                    </Pressable>
                    <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        moveToPage(ROUTES.LATEST_NEWS);
                      }}
                      fontSize={8}
                    >
                      Latest News
                    </Pressable>
                    <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        moveToPage(ROUTES.G_OS_FOR_WILDLIFE_SCHEMES);
                      }}
                      fontSize={8}
                    >
                      G.O.s of Wildlife Schemes
                    </Pressable>
                    <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        moveToPage(ROUTES.G_O_S_FOR_OTHER_SCHEMES);
                      }}
                      fontSize={8}
                    >
                      G.O.s of Other Schemes
                    </Pressable>
                  </VStack>
                </Box>
              </VStack>
            </Box>
          </Hidden>
          <Hidden only={["sm", "xs"]}>
            <Box alignItems="flex-end">
              <VStack space={10} marginTop={["0px", "-100px", "-700px"]}>
                <Box
                  borderRadius="10px"
                  backgroundColor="rgba(255, 255, 255, 0.1)"
                  width="350px"
                  height={"70px"}
                  mr="30px"
                  justifyContent="center"
                >
                  <HStack space={10} justifyContent="center">
                    <Box
                      width="60px"
                      height="60px"
                      justifyContent="center"
                      style={{ borderRadius: "50%", background: "#6d6d6887" }}
                    >
                      <MdOutlinePhoneInTalk
                        size="32"
                        color="white"
                        style={{ width: "90%" }}
                      />
                    </Box>
                    <Box width="60%">
                      <VStack space={2}>
                        <Text color="white">{t("24-x-7-helpline")}</Text>
                        <Text bold color="white">
                          <a
                            href="tel:1800 425 4509"
                            style={{
                              color: "white",
                            }}
                          >
                            1800 425 4509
                          </a>
                        </Text>
                      </VStack>
                    </Box>
                  </HStack>
                </Box>
                <Box
                  borderRadius="10px"
                  backgroundColor="rgba(255, 255, 255, 0.1)"
                  width="350px"
                  mr="30px"
                >
                  <VStack p="5" space="5">
                    <Text bold color="white" cursor="pointer">
                      {t("news-Updates")}
                    </Text>
                    <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        moveToPage(ROUTES.RECENT_G_OS);
                      }}
                    >
                      {t("Recent_G.O.s_Admin")}
                    </Pressable>
                    <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        moveToPage(ROUTES.LATEST_NEWS);
                      }}
                    >
                      {t("Latest_News")}
                    </Pressable>
                    <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        moveToPage(ROUTES.G_OS_FOR_WILDLIFE_SCHEMES);
                      }}
                    >
                      {t("G.O.s_of_Wildlife_Schemes")}
                    </Pressable>
                    <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        moveToPage(ROUTES.G_O_S_FOR_OTHER_SCHEMES);
                      }}
                    >
                      {t("G.O.s_of_Other_Schemes")}
                    </Pressable>
                  </VStack>
                </Box>
                <Box
                  borderRadius="10px"
                  backgroundColor="rgba(255, 255, 255, 0.1)"
                  width="350px"
                  // mr="30px"
                >
                  <VStack p="5" space="5">
                    <Text bold color="white" cursor="pointer">
                      <span className="blink-text">{t("What's New")}</span>
                    </Text>
                    {/* <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        Linking.openURL(
                          "https://forests.tn.gov.in/storage/tnfd/b946e59b-1318-4e30-a8e1-6b573bf61edf.pdf"
                        );
                      }}
                    >
                      {t(
                        "Protection and Securing Elephant Corridors in Tamil Nadu-Send Comments"
                      )}
                      <Text style={{ color: "#9090ff" }}>
                        elephantcorridortnfd@gmail.com
                      </Text>
                    </Pressable> */}
		     <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        Linking.openURL(
                          "https://forests.tn.gov.in/storage/tnfd/42ff4c9e-d108-44e0-a46c-01b3590ed86e.pdf"
                        );
                      }}
                    >
                      {t(
                        "Wildlife Announcement 2024-25 Constitution of 'Dr. AJT Johnsingh Wildlife Conservation Award'"
                      )}
                      
                    </Pressable>
	             <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        Linking.openURL(
                          "https://forests.tn.gov.in/storage/tnfd/cfc19afa-f947-4c48-bc9f-43bb4aac7e37.pdf"
                        );
                      }}
                    >
                      {t(
                        "Guidelines for Declaration of Stock of Exotic Live Species in the state of Tamil nadu -- Send Comment Email"
                      )}
                      <Text style={{ color: "#9090ff" }}>
                      cwlw_wildlife3@yahoo.in
                      </Text>
                    </Pressable>
                    <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="white"
                      onPress={() => {
                        Linking.openURL(
                          "https://forests.tn.gov.in/storage/tnfd/98ae1e21-8d5f-4e0a-932d-0e2405f39966.pdf"
                        );
                      }}
                    >
                      {t(
                        "Captive Elephant (Transfer or Transport) Rules, 2024"
                      )}
                    </Pressable>
                  </VStack>
                </Box>
              </VStack>
            </Box>
          </Hidden>
        </Box>
        <Newsfeed />
        <Box
          bgImage="url(https://forests.tn.gov.in/storage/tnfd/leaves-2x.png)"
          backgroundSize="cover"
          bgColor="#f6f6e9"
          backgroundRepeat="no-repeat"
          // pt="10px"
          overflow="hidden"
          padding={["10px", "10px", "0px"]}
          h="auto"
          justifyContent="center"
          w={["100vw", "100vw", "100vw"]}
        >
          <Box width="100%" justifyContent={"center"}>
            {/* <HTMLRenderer cmsKey={"test4"}></HTMLRenderer> */}
            <About />
          </Box>
        </Box>

        <Box marginTop={[0, 0, 0]}>
          <ForestGlance />
        </Box>

        {/* <Box>
          <Box
            bgImage="url(https://forests.tn.gov.in/storage/tnfd/wreath-1x.png)"
            backgroundSize={["100vw 400px", "230vw 350px", "100vw 400px"]}
            backgroundRepeat="no-repeat"
            marginTop={"-120px"}
            h="500px"
            w="100vw"
          ></Box>
          <Box marginTop="-300">
            <Center>
              <h1 className="headingtext1"> {t("Protected_Areas")}</h1>
            </Center>
            <Box
              maxW="70vw"
              mx={"15vw"}
              minHeight={["0vh", "40vh", "70vh"]}
              marginTop={["-100px", "30px", "0px"]}
            >
              <MapWithAMarker />
            </Box>
          </Box>
        </Box> */}
        <Box marginTop="0px">
          <Box
            bgImage="url(https://forests.tn.gov.in/storage/tnfd/leaves-2x.png)"
            backgroundSize="cover"
            bgColor="#f6f6e9"
            backgroundRepeat="no-repeat"
            pt="20px"
            h={["60vh", "70vh", "90vh"]}
            w="100vw"
          >
            <Center p={10}>
              <h1 className="headingtext1"> {t("Media_Gallery")}</h1>
            </Center>
            <Box ml="5%" width="90%" justifyContent={"center"}>
              <GalleryTab />
            </Box>
          </Box>
        </Box>
        <Box bgColor="#fffff8">
          <Box>
            <Box w={"100vw"} bgColor="#ffff">
              <Center p={10}>
                <h1 className="headingtext1"> {t("Useful_Links")}</h1>
              </Center>
              <Box
                ml={[0, 0, 5]}
                justifyContent={"center"}
                overflowX="auto"
                overflowY="hidden"
              >
                <UsefulLinksTab />
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              bgImage="url(https://forests.tn.gov.in/storage/tnfd/grass-1x.png)"
              backgroundSize="100vw 200px"
              backgroundRepeat="no-repeat"
              w="100vw"
              h="20vh"
            ></Box>
          </Box>
        </Box>
        <Box></Box>
      </VStack>
    </Box>
  );
};
export default HomeDesign;
