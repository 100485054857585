import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import CampaHTML from "./components/campa";
import GoGreenImage from "@assets/images/organization/go-green.jpg";
import GoRelatedStateCAMPA from "./components/go_related_state_campa/go_related_state_campa";
import CAMPACollapsible from "./components/campa_collapsible/campa_collapsible";

const Campa = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection imageUrl={ForestImageCover} title={"CAMPA"} />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["90%", "90%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="organization" />
                </Box>
                <Box alignItems="center" w="100%">
                  <VStack space={4}>
                    <Box>
                      <Text fontWeight={"bolder"}>{"Important Link"}</Text>
                    </Box>
                    <Box
                      style={{
                        border: "1px solid #c7dd80",
                        borderRadius: "15px",
                      }}
                      padding="5"
                    >
                      {" "}
                      <a href="https://egreenwatch.nic.in/" target={"blank"}>
                        <img src={GoGreenImage} height="64px" />
                      </a>
                    </Box>
                  </VStack>
                </Box>
                <Box alignItems="center" w="100%">
                  {/* <GoRelatedStateCAMPA /> */}
                </Box>
              </VStack>
            </Box>
            <Box
              w={["100%", "100%", "65%"]}
              h="100%"
              padding={["20px", "20px"]}
            >
              <CampaHTML />
              <CAMPACollapsible />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default Campa;
