import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import GOsForTaniiTable from "./components/dynamictable";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const GOsForTanii = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state } = useLocation();
  console.log("state", state);
  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection imageUrl={ForestImageCover} title={state.cms} />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="Important_G_Os" />
                </Box>
              </VStack>
            </Box>
            <Box w={["100%", "100%", "65%"]} h="100%">
              <GOsForTaniiTable cmskey={state.cms} />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default GOsForTanii;
