import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import SystemCmsValue from "./components/system_cms_value";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import { useLocation } from "react-router-dom";

const SuccessStoryTnfusrc = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection imageUrl={ForestImageCover} title={"CMS"} />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box
              w={["100%", "100%", "100%"]}
              h="100%"
              mt={["10px", "10px", "0px"]}
              padding="30px"
            >
              <SystemCmsValue />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default SuccessStoryTnfusrc;
