import React, { useEffect, useState } from "react";
import Tnlogo from "@src/views/pages/sign_in/logo.png";
import Background from "@src/views/pages/sign_in/background.jpg";
import {
  Button,
  HStack,
  VStack,
  Text,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Pressable,
} from "native-base";
import AntDesign from "react-native-vector-icons/dist/AntDesign";
import Entypo from "react-native-vector-icons/dist/Entypo";
import FloatingLabelInput from "./components/FloatingLabelInput";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { login, loginSelector } from "@services/redux/slices/login";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "native-base";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { showToast } from "@helpers/toast";
import { encryptRequest } from "@helpers/crypto";
// import FooterBar from "@views/components/ui/footer_bar";
export function SignInForm({ props }) {
  // add next router here
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { item, authorize, loading, error, is_profile_completed } =
    useSelector(loginSelector);
  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = useState(false);
  const handleLogin = () => {
    if (!text.length) {
      showToast({
        type: "error",
        message: "Please enter a Username",
      });
    } else if (!pass.length) {
      showToast({
        type: "error",
        message: "Please enter a Password",
      });
    } 
    else {
      dispatch(login({ username: text, password: encryptRequest(pass) }));

    }
  };
  if (authorize) {
    history.push({
      pathname: `${ROUTES.HOME}`,
      state: item,
    });
  }
  useEffect(() => {
    if (error) {
      showToast({
        type: "error",
        message: error,
      });
    }
  }, [error]);
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleLogin();
    }
  };
  const handleForgetPassword = () => {
    history.push(ROUTES.REGISTRATION);
  };
  return (
    <VStack space={3} flex={1} justifyItems={"center"} my="auto">
      <Box bg={Background} width="100%">
        <Center>
          <Box marginBottom={3}>
            <img src={Tnlogo} width="70px"></img>
          </Box>
          <Text
            fontFamily="poppins"
            bold
            fontSize="25px"
            textAlign="center"
            color="#095b09"
          >
            Government of Tamilnadu
            <br /> Forest Department
          </Text>
        </Center>
        <Box mx="auto">
          <Text
            fontSize="lg"
            fontWeight="bold"
            color={"#0077c0"}
            textAlign="center"
            marginBottom={5}
          >
            {t("sign_header")}
          </Text>
        </Box>

        <Box mx="auto" width="100%">
          <FloatingLabelInput
            isRequired
            label={t("form:Username")}
            labelColor="#9ca3af"
            labelBGColor={useColorModeValue("#fff", "#1f2937")}
            borderRadius="4"
            defaultValue={text}
            height="10"
            onKeyPress={handleKeypress}
            autoFocus={true}
            onChangeText={(txt) => setText(txt)}
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.300",
            }}
          />
        </Box>
        <Box mx="auto" width="100%" margin={5}>
          <FloatingLabelInput
            isRequired
            type={showPass ? "" : "password"}
            label={t("form:Password")}
            height="10"
            borderRadius="4"
            labelColor="#9ca3af"
            labelBGColor={useColorModeValue("#fff", "#1f2937")}
            defaultValue={pass}
            onKeyPress={handleKeypress}
            onChangeText={(txt) => setPass(txt)}
            InputRightElement={
              <IconButton
                variant="unstyled"
                icon={
                  showPass ? (
                    <AiFillEyeInvisible
                      size={18}
                      color={"#2f57005c"}
                    />
                  ) : (
                    <AiFillEye size={18} />
                  )
                }
                onPress={() => {
                  setShowPass(showPass ? false : true);
                }}
              />
            }
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.300",
            }}
          />
        </Box>
        <Box mx="auto" width="100">
          <Button
            isLoading={loading}
            flex={1}
            mt="5"
            size="md"
            borderRadius="4"
            _text={{
              fontWeight: "medium",
            }}
            _light={{
              bg: "#0077c0",
            }}
            _hover={{
              bg: "#0077c0",
            }}
            _dark={{
              bg: "success.700",
            }}
            onPress={handleLogin}
            /* onPress function naviagteTo:"OTP" (next/Router) */
          >
            {t("login")}
          </Button>
        </Box>
      </Box>
    </VStack>
  );
}
export default function SignIn(props) {
  const { t } = useTranslation();
  return (
    <>
      <Box
        _dark={{
          bgImage: `url("")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        _light={{
          bgImage: `url(${Background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        // my="auto"
        // px={4}
        overflowY="hidden"
        mt={"-5px"}
        height={"100vh"}
      >
        <Center
          my="auto"
          _dark={{
            bg: "coolGray.900",
          }}
          _light={{
            opacity: "1",
          }}
          // marginTop="12%"
          // marginBottom="12%"
        >
          <Stack
            flexDirection={{
              base: "column",
              md: "row",
            }}
            w="95%"
            maxW={{
              md: "350px",
            }}
            flex={{
              base: "1",
              md: "none",
            }}
          >
            {/* <Hidden from="md">
              <VStack px="4" mt="4" mb="5" space="9">
                <VStack space="2">
                  <Text
                    fontSize="md"
                    textAlign={"center"}
                    fontWeight="bold"
                    color="white"
                  >
                    {t("sign_header")}
                  </Text>
                </VStack>
              </VStack>
            </Hidden> */}
            {/* <Hidden till="md">
              <Center
                flex="1"
                bg="#8d15a4"
                opacity={"0.8"}
                borderTopLeftRadius={{
                  base: "0",
                  md: "xl",
                }}
                borderBottomLeftRadius={{
                  base: "0",
                  md: "xl",
                }}
              >
                <Image
                  h="18"
                  size="40"
                  alt="NativeBase Startup+ "
                  resizeMode={"contain"}
                  source="https://nrtdata-assets.s3.ap-south-1.amazonaws.com/logo.png"
                />
                <Text
                  color="white"
                  fontSize="lg"
                  fontWeight={"600"}
                  padding="3"
                >
                  <div className="mr-5">{t("ctrmp")}</div>
                </Text>
              </Center>
            </Hidden> */}
            <Box
              flex={1}
              px="6"
              py="9"
              _light={{
                bg: "white",
                opacity: "0.9",
              }}
              _dark={{
                bg: "coolGray.800",
              }}
              space="3"
              justifyContent="center"
              borderTopRightRadius={{
                base: "2xl",
                md: "xl",
              }}
              borderTopLeftRadius={{
                base: "2xl",
                md: "xl",
              }}
              borderBottomRightRadius={{
                base: "xl",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "xl",
                md: "xl",
              }}
            >
              <SignInForm props={props} />
            </Box>
          </Stack>
        </Center>
        {/* <HStack
          h={8}
          py={2}
          justifyContent={"center"}
          opacity={0.7}
          bg={"#0077c0"}
        >
          <Text color="#fff" fontSize={12}>
            Powered By{" "}
          </Text>
          <a href="https://vertace.com/" target="blank">
            <img height="20" alt="vertace" src={vertace_logo} />
          </a>
        </HStack> */}
      </Box>
    </>
  );
}
