import { ImageList } from "@mui/material";
import { Card } from "antd";
import { startCase } from "lodash";
import {
  AspectRatio,
  Box,
  Center,
  HStack,
  Image,
  Text,
  VStack,
} from "native-base";
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { borderRadius } from "styled-system";

const ProtectedAreaCms = (props) => {
  const { groupValues, cms_key, items } = props;
  let sortitems = items.sort((a, b) => a?.rank?.value - b?.rank?.value);
  useEffect(() => {}, [cms_key]);
  const designation = items?.map((a) => {
    return a.designation?.value;
  });
  const name = items?.map((a) => {
    return a.name?.value;
  });
  const image = items?.map((a) => {
    return a.image?.value;
  });

  const rows = [];
  for (let i = 0; i < sortitems.length; i += 3) {
    const rowItems = sortitems.slice(i, i + 3); // Get up to three items for each row

    const row = (
      <HStack
        key={i}
        space={5}
        justifyContent="space-evenly"
        mt={5}
        flexDirection={{
          base: "column",
          md: "row",
        }}
      >
        {rowItems.map((item, index) => (
          <Box key={index}>
            <HStack
              width={"400px"}
              justifyContent={"center"}
              alignItems={"center"}
              mt={[5, 5, 0]}
            >
              <Box>
                <img
                  src={item.name?.value ? item.image?.value : ""}
                  style={{
                    borderRadius: "50%",
                    height: "70px",
                    width: "70px",
                    objectFit: "cover",
                    marginRight: "10px",
                  }}
                  alt="User Profile"
                />
              </Box>
              <Box width={"300px"}>
                {""}
                <Box>
                  <b>{item.name?.value}</b>
                </Box>
                <Box width={"80%"}>{item.designation?.value}</Box>
              </Box>
            </HStack>
          </Box>
        ))}
      </HStack>
    );

    rows.push(row);
  }

  // return (
  //   // <>
  //   //   <HStack space={10}>
  //   //     {items.map((item, index) => (
  //   //       <HStack key={index}>
  //   //         <Box>
  //   //           <img
  //   //             src={item.image?.value}
  //   //             style={{
  //   //               borderRadius: "50%",
  //   //               height: "70px",
  //   //               width: "70px",
  //   //               objectFit: "cover",
  //   //               marginRight: "10px",
  //   //             }}
  //   //           />
  //   //         </Box>

  //   //         <Box justifyContent={"center"}>
  //   //           <Box>{item.name?.value}</Box>
  //   //           <Box>{item.designation?.value}</Box>
  //   //         </Box>
  //   //       </HStack>
  //   //     ))}
  //   //   </HStack>
  //   // </>
  //   <> <VStack>{rows}</VStack>;</>
  // );
  return (
    <VStack>
      <HStack width={"100%"}>
        <Box width={"100%"}>
          <Text fontSize={"30px"}>About us</Text>
          <Box mt={5} fontSize={"16px"}>
            <span>
              <b>The Tamil Nadu Forest Department</b> has completed a journey of
              more than 160 years. Since the formation of the Department in
              1856, during the last sixteen decades Forest Department had
              witnessed many transformations.
            </span>
            <br />
            <span>
              The initiatives of the Department in the erstwhile Madras
              Presidency and in the Tamil Nadu State after independence where
              largely guided by the National Forest Policies of 1894, 1952 and
              1988 and programmes supported mainly through fund allocations in
              the Five Year Plans.
            </span>
          </Box>
        </Box>
      </HStack>
      <VStack> {rows}</VStack>
    </VStack>
  );
};
export default ProtectedAreaCms;
