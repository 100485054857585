import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import LatestNewsCardList from "./components/latest_news_card_list";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import GoGreenImage from "@assets/images/organization/go-green.jpg";

const LatestNews = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection imageUrl={ForestImageCover} title={"Latest News"} />
        </Box>
        <Box my="10">
          <HStack>
            <Box w="15%" h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center"></Box>
                <Box alignItems="center" w="100%">
                  {/* <VStack space={4}>
                    <Box>
                      <Text fontWeight={"bolder"}>{"Important Link"}</Text>
                    </Box>
                    <Box
                      style={{
                        border: "1px solid #c7dd80",
                        borderRadius: "15px",
                      }}
                      padding="5"
                    >
                      <img src={GoGreenImage} height="64px" />
                    </Box>
                  </VStack> */}
                </Box>
              </VStack>
            </Box>
            <Box w="65%" h="100%">
              <LatestNewsCardList />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default LatestNews;
