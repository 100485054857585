import { Box, Button, Text } from "native-base";
import React from "react";

const ForestGlanceDisplay = (props) => {
  const { displayValue } = props;
  return (
    <>
      <Text color={"white"} width={"90%"} pl="5%" textAlign="justify">
        {displayValue}
      </Text>
      {/* <Box pl={"5%"} pt={"5"}>
        <Button
          variant="outline"
          width="150px"
          borderColor="#00ff59"
          _hover={{
            borderColor: "#00ff59",
            bg: "green.500",
            _text: {
              color: "#ffffff",
            },
          }}
          _text={{
            color: "#02ff5a",
            fontFamily: "Poppins",
          }}
        >
          Read more
        </Button>
      </Box> */}
    </>
  );
};
export default ForestGlanceDisplay;
