import React from "react";
import { Link as ReactLink } from "react-router-dom";
import {
  Box,
  Text,
  VStack,
  HStack,
  Center,
  Link,
  useTheme,
  useColorModeValue,
} from "native-base";
import { ImSad } from "react-icons/im";
import { useTranslation } from "react-i18next";
import HomeHeader from "@views/pages/Menu/home";
import Footer_tnfd from "../ui/footer_tnfd";
import ScrollView from "../ui/scroll_view/scroll_view";

const NotFound = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  return (
    <ScrollView>
      <HomeHeader />
      <Box style={{ height: "700px" }}>
        <Center
          my="auto"
          bg={"#f8fbef"}
          height={"100%"}
        >
          <VStack space="4">
            <Center>
              <ImSad size="175" color={"#2f57005c"} />
            </Center>
            <Box alignItems="center">
              <Text
                fontSize={{
                  base: "90",
                  md: "80",
                }}
                fontWeight="bold"
                color={"#2f57005c"}
              >
                {t("404")}
              </Text>
            </Box>
            <Center>
              <Text
                fontSize={{
                  base: "20",
                  md: "20",
                }}
                fontWeight="bold"
                color={"#2f57005c"}
              >
                {t("page_not_exist")}
              </Text>
            </Center>
            <Center>
              <HStack>
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  textDecoration="none"
                  color={"#2f57005c"}
                >
                  {t("go_back_to")}
                </Text>
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  textDecoration="none"
                  color={"#2f57005c"}
                >
                  {" "}
                </Text>
                <ReactLink to="/">
                  <Link
                    _text={{
                      fontSize: "sm",
                      fontWeight: "bold",
                      textDecoration: "none",
                      textTransform: "uppercase",
                      color: "#2f57005c",}}
                    _hover={{
                      _text: {
                        color:"#2f57005c",
                      },
                    }}
                  >
                    <span className="home-tag">
                      {t("home")}
                    </span>
                  </Link>
                </ReactLink>
              </HStack>
            </Center>
          </VStack>
        </Center>
      </Box>
      <Footer_tnfd />
    </ScrollView>
  );
};
export default NotFound;
