import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  ZStack,
  Button,
} from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
// import CirclesTable from "./components/circles";
import { AiOutlineFilePdf } from "react-icons/ai";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import Calander from "@assets/images/bg-images/WhatsApp Image 2024-01-02 at 9.00.15 PM.jpeg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";

const Circles = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDownload = () => {
    try {
        window.open("https://forests.tn.gov.in/storage/tnfd/7db31912-0f4f-4d3e-89ea-bcce704eb711.pdf", "_blank");
    } catch (error) {
        console.error("Error opening the file in a new tab:", error);
        // Handle the error (e.g., show a message to the user)
    }
};

  const cardStyle = {
    background: "#fafafa",
    borderRadius: "md",
    boxShadow: "md",
    color: "white",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "10px",
    width: ["100%", "100%", "65%"], // Responsive width
    height: "100%", // Set a fixed height or adjust as needed
  };

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection
            imageUrl={ForestImageCover}
            title={t("Calendar 2024")}
          />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="Publications" />
                </Box>
              </VStack>
            </Box>
            <Box w={["100%", "100%", "65%"]} h="100%" style={cardStyle}>
              <HStack
                space={5}
                flexDirection={{
                  base: "column",
                  md: "row",
                }}
              >
                <Box>
                  <a
                    href="https://forests.tn.gov.in/storage/tnfd/7db31912-0f4f-4d3e-89ea-bcce704eb711.pdf"
                    download
                    style={{ textDecoration: "none", color: "white" }}
                    target="blank"
                    // onClick={(e) => e.stopPropagation()}
                  >
                    <img src={Calander} height="150px" />
                  </a>
                </Box>
                <Box justifyContent={"center"}>
                  <VStack>
                    {" "}
                    <Box>
                      <Text fontWeight="bold" fontSize={"18px"}>
                        Calendar 2024
                      </Text>
                      <Text fontWeight="bold" fontSize={"18px"}>
                        Elephant Whisperers
                      </Text>
                    </Box>
                    <Box>
                      <Button
                        onPress={handleDownload}
                        colorScheme="teal"
                        size="sm"
                      >
                        Download
                      </Button>
                    </Box>
                  </VStack>
                </Box>
              </HStack>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default Circles;
