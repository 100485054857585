import { Button, Form, } from "antd";
import JoditEditor from "jodit-react";
import { Box, Center, HStack, Text, VStack } from "native-base";
const HTMLForm = ({ htmlForm, items, onFinishHtml, language }) => {
  let html_data =
    items
      ?.find((field) => field.key === "code")
      ?.system_cms_values.find(
        (system_cms_item) => system_cms_item.system_cms_language_id === language
      ) || {};
  htmlForm.resetFields();
  return (
    <Form form={htmlForm} initialValues={html_data} onFinish={onFinishHtml}>
      <Box width="100%" alignItems="flex-end">
        <Button
          htmlType="submit"
          style={{
            marginBottom: "30px",
          }}
        >
          Update
        </Button>
      </Box>

      <Form.Item name="value">
        <JoditEditor />
      </Form.Item>
    </Form>
  );
};

export default HTMLForm;
