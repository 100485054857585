import { useState } from "react";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";

const MapContainer = (props) => {
  const [activeMarker, setActive] = useState({});
  const [showingInfoWindow, setShow] = useState(false);
  const { google } = props;
  const history = useHistory();
  // eslint-disable-next-line
  const onMarkerClick = (props, marker) => {
    setActive(marker);
    setShow(true);
  };
  const onMapClicked = () => {
    if (showingInfoWindow) {
      setShow(false);
      setActive(null);
    }
  };
  const moveToPage = (routes) => {
    history.push({
      pathname: `${routes}`,
    });
  };

  return (
    <Map
      google={google}
      style={{ width: "100%", height: "100%", position: "relative" }}
      initialCenter={{
        lat: 11.607403203242495,
        lng: 78.47718345676526,
      }}
      zoom={7}
      gestureHandling="cooperative"
    >
      <Marker
        title={"Guindy National Park"}
        position={{ lat: 13.0002235139, lng: 80.2283928841 }}
        location={"guindy_national_zoological_park"}
        icon={{
          url: "https://nt-nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Deer.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "guindy_national_zoological_park" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Arignar Anna Zoological Park"}
        position={{ lat: 12.8798, lng: 80.0808 }}
        location={"arignar_anna_zoological_park"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Deer.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "arignar_anna_zoological_park" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Srivilliputhur Grizzled Squirrel Wildlife Sanctuary"}
        position={{ lat: 9.58399823076, lng: 77.5141492913 }}
        location={"grizzled_giant_squirrel_sanctuary"}
        onMouseOver={onMarkerClick}
        onMouseOut={onMapClicked}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "grizzled_giant_squirrel_sanctuary" },
          });
        }}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/duck.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
      >
        {showingInfoWindow && (
          <InfoWindow>
            <h4>{info}</h4>
          </InfoWindow>
        )}
      </Marker>
      <Marker
        title={"Point Calimere Wildlife Sanctuary park"}
        position={{ lat: 10.3220050563, lng: 79.598600185 }}
        location={"point_calimere_wildlife_sanctuary_block_a_b"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Deer.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "point_calimere_wildlife_sanctuary_block_a_b" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Vallanadu Black Buck Sanctuary"}
        position={{ lat: 8.71399, lng: 77.88727 }}
        location={"vallanadu_black_buck_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Deer.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "vallanadu_black_buck_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Kaniyakumari Wildlife Sanctuary"}
        position={{ lat: 8.41111136928, lng: 77.3726545704 }}
        location={"kanyakumari_wildlife_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/crocodile.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "kanyakumari_wildlife_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Meghamalai Wildlife Sanctuary"}
        position={{ lat: 9.84652996002, lng: 77.5722944513 }}
        location={"megamalai_wildlife_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Sambar.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "megamalai_wildlife_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Kodaikanal Wildlife Sanctuary"}
        position={{ lat: 10.2300386753, lng: 77.4061344491 }}
        location={"kodaikanal_wildlife_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/elephant.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "kodaikanal_wildlife_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Gangaikondan Spotted Deer Sanctuary"}
        position={{ lat: 8.827145, lng: 77.759772 }}
        location={"gangaikondan_spotteddeer_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Deer.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "gangaikondan_spotteddeer_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Cauvery North Wildlife Sanctuary"}
        position={{ lat: 12.3457897822, lng: 77.7920659424 }}
        location={"cauvery_north_wildlife_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/elephant.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "cauvery_north_wildlife_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Nellai Wildlife Sanctuary"}
        position={{ lat: 9.19111754764, lng: 77.2949392621 }}
        location={"nellai_wildlife_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/bear.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "nellai_wildlife_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      {/* <Marker
        title={"Kadavur Slender Loris Sanctuary"}
        position={{ lat: 9.19111754764, lng: 77.2949392621 }}
        location={"kadavur_slender_loris_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Deer.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "kadavur_slender_loris_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker /> */}

      <Marker
        title={"Vedanthangal Birds Sanctuary"}
        position={{ lat: 12.5451581003, lng: 79.8560575594 }}
        location={"vedanthangal_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/egrets.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "vedanthangal_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Karikili Birds Sanctuary"}
        position={{ lat: 12.5991724551, lng: 79.8433238293 }}
        location={"karikili_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Ibis.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "karikili_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Pulicat Lake Birds Sanctuary"}
        position={{ lat: 13.469284, lng: 80.313907 }}
        location={"pulicat_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/flamming.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "pulicat_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Vettangudi birds Sanctuary"}
        position={{ lat: 10.11134, lng: 78.510445 }}
        location={"vettangudi_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/duck.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "vettangudi_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Kanjirankulam Birds Sanctuary"}
        position={{ lat: 9.3578198634, lng: 78.478955392 }}
        location={"kanjirankulam_and_chitrangudi_water_bird_sanctuaries"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Heron.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: {
              detail: "kanjirankulam_and_chitrangudi_water_bird_sanctuaries",
            },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Chitrangudi Birds Sanctuary"}
        position={{ lat: 9.333075, lng: 78.475932 }}
        location={"kanjirankulam_and_chitrangudi_water_bird_sanctuaries"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Ibis.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: {
              detail: "kanjirankulam_and_chitrangudi_water_bird_sanctuaries",
            },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Udayamarthandapuram Birds Sanctuary"}
        position={{ lat: 10.4503947833, lng: 79.5560893827 }}
        location={"udayamarthandapuram_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Heron.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "udayamarthandapuram_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Vaduvoor birds Sanctuary"}
        position={{ lat: 10.7011501295, lng: 79.3183020508 }}
        location={"udayamarthandapuram_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/egrets.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "udayamarthandapuram_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Karaivetti Birds Sanctuary"}
        position={{ lat: 10.9785517903, lng: 79.043869547 }}
        location={"karaivetti_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/stork.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "karaivetti_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Vellode Birds Sanctuary"}
        position={{ lat: 11.2511387768, lng: 77.6542952491 }}
        location={"vellode_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/duck.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "vellode_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Melaselvanur-Kelaselvanur Birds Sanctuary"}
        position={{ lat: 9.21714959864, lng: 78.5586398744 }}
        location={"vellode_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/stork.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "vellode_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Therthangal Bird Sanctuary"}
        position={{ lat: 9.45911369046, lng: 78.7716023546 }}
        location={"theerthangal_bird_sanctuaries"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/duck.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "theerthangal_bird_sanctuaries" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Sakkarakottai Tank  Birds Sanctuary "}
        position={{ lat: 9.34516486671, lng: 78.8235283281 }}
        location={"sakkarakottai_tank_bird_sanctuaries"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Heron.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "sakkarakottai_tank_bird_sanctuaries" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Oussudu Lake Birds Sanctuary"}
        position={{ lat: 11.97444444, lng: 79.7452777778 }}
        location={"oussdu_lake_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/flamming.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "oussdu_lake_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Kazhuveli Birds Sanctuary"}
        position={{ lat: 12.122469, lng: 79.879283 }}
        location={"oussdu_lake_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/pelican.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "oussdu_lake_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Nanjarayan Tank Birds Sanctuary"}
        position={{ lat: 11.135899, lng: 77.389207 }}
        location={"oussdu_lake_bird_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/stork.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "oussdu_lake_bird_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Mukurthi National Park"}
        position={{ lat: 11.429217, lng: 76.560325 }}
        location={"mukurthi_national_park"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Sambar.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "mukurthi_national_park" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />

      <Marker
        title={
          "Gulf of Mannar Marine Park (21 Islands) Reserve Land - 623.12 Ha + Sea area - 51978.88 Ha"
        }
        position={{ lat: 9.2430555556, lng: 79.1680555556 }}
        location={"gulf_of_mannar_biosphere_reserve_and_national_park"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/Sambar.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: {
              detail: "gulf_of_mannar_biosphere_reserve_and_national_park",
            },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Sathyamangalam Tiger Reserve"}
        position={{ lat: 11.598326, lng: 77.221972 }}
        location={"sathyamangalam_tiger_reserve"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/tiger.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "sathyamangalam_tiger_reserve" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Annamalai Tiger Reserve"}
        position={{ lat: 10.406091, lng: 77.129491 }}
        location={"sathyamangalam_tiger_reserve"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/tiger.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "sathyamangalam_tiger_reserve" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Mudumalai Tiger Reserve"}
        position={{ lat: 11.562499, lng: 76.53455 }}
        location={"mudumalai_national_park_and_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/tiger.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "mudumalai_national_park_and_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Kalakkadu Mundanthurai Tiger Reserve"}
        position={{ lat: 8.688434, lng: 77.309482 }}
        location={"kalakad_mundanthurai_tiger_reserve"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/tiger.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "kalakad_mundanthurai_tiger_reserve" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Srivilliputhur Megamalai Tiger Reserve"}
        position={{ lat: 9.592326, lng: 77.431885 }}
        location={"kalakad_mundanthurai_tiger_reserve"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/tiger.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "kalakad_mundanthurai_tiger_reserve" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Nellai Wildlife sanctuary"}
        position={{ lat: 8.93058, lng: 77.238014 }}
        location={"nellai_wildlife_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/tiger.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "nellai_wildlife_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
      <Marker
        title={"Koonthankulam-Kadankulam Birds Sanctuary"}
        position={{ lat: 8.49345014165, lng: 77.7568812405 }}
        location={"koonthankulam_kadankulam_birds_sanctuary"}
        icon={{
          url: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/map-icons/tiger.png",
          anchor: new google.maps.Point(32, 32),
          scaledSize: new google.maps.Size(30, 30),
        }}
        onClick={(e) => {
          history.push({
            pathname: `${ROUTES.PROTECTED_AREA}/${e.location}`,
            state: { detail: "koonthankulam_kadankulam_birds_sanctuary" },
          });
        }}
      />
      {showingInfoWindow && (
        <InfoWindow>
          <h4>{info}</h4>
        </InfoWindow>
      )}
      <Marker />
    </Map>
  );
};
export default MapContainer;
