import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import ForestConservationActHTML from "./components/forest_conservation_act";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";

const ForestConservationAct = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <VStack>
          <Box>
            <HeaderSection
              imageUrl={ForestImageCover}
              title={t("forest_conservation_act")}
            />
          </Box>
          <Box my="10">
            <HStack
              flexDirection={{
                base: "column",
                md: "row",
              }}
            >
              <Box w={["100%", "100%", "30%"]} h="100%">
                <VStack space={5} w="100%">
                  <Box w="100%" alignItems="center">
                    <SecondarySiderMenu type="organization" />
                  </Box>
                </VStack>
              </Box>
              <Box w={["100%", "100%", "65%"]} h="100%">
                <ForestConservationActHTML />
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </>
  );
};
export default ForestConservationAct;
