import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";
import FlowerImage from "@assets/images/menu/plant.png";

const VisionFirstCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Box
        width={["92%","92%","90%"]}
        height={["300px","300px","200px"]}
        marginLeft={["9px","9px"]}
          //   flex={1}
          style={{
            // width: "90%",
            marginTop: "20px",
            padding: "20px",
            borderRadius: "20px",
            background: "#E7F6DB",
            // height: "200px",
            // boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px",
            justifyContent: "center",
          }}
        >
          <HStack>
            <Box width={["0%","0%","25%"]} justifyContent="center" padding="10"
            position="relative"
            right={["35px","35px","0px"]}
            mt={["153px","153px","50px"]}
           >
              <img src={FlowerImage} alt="forest" width="100px" />
            </Box>
            <Box flex={1} alignSelf="center" paddingRight="10"
            position="relative"
            right={["-35px","-35px","0px"]}
            fontSize={["13px","13px","18px"]}>

              <p>
                In 2014 Government of Tamil Nadu had launched{" "}
                <b>“Vision Tamil Nadu 2023”</b> which stresses on maintaining
                ecological balance across the State and it{" "}
                <b>emphasizes tree cover outside forest</b> as the single most
                important and cost effective strategy for achieving the{" "}
                <b>goal of 33% forest and tree cover.</b>
              </p>
            </Box>
          </HStack>
        </Box>
      </Box>
    </>
  );
};
export default VisionFirstCard;
