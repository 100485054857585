import { Box, VStack, Text } from "native-base";
import React, { useState } from "react";

const ForestGlanceLoopBox = (props) => {
  const { forest_glance_list, selectedItem, parentOnClick } = props;
  const [is_hovered, setIsHovered] = useState(false);
  const [hovered_index, setHoveredIndex] = useState(0);
  return (
    <VStack w="100%" h="100%" space={10} justifyContent="center">
      {forest_glance_list.map((item, index) => {
        return (
          <VStack
            onMouseEnter={() => {
              setIsHovered(true);
              setHoveredIndex(index);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
          >
            <Box pl={5} key={`box-${index}`}>
              <Text
                key={`text-${index}`}
                color="black"
                cursor="pointer"
                fontFamily="Poppins"
                fontSize={selectedItem?.id === item.id ? 20 : ""}
                fontWeight={selectedItem?.id === item.id ? "bolder" : ""}
                onClick={() => {
                  parentOnClick(item);
                }}
              >
                {item.name}
              </Text>
            </Box>
            <Box mt="1" h="20%" w="100%">
              <div
                className={
                  is_hovered && hovered_index === index
                    ? "main-forest-glance-transition-header-left"
                    : "forest-glance-transition-header-left"
                }
              />
            </Box>
          </VStack>
        );
      })}
    </VStack>
  );
};
export default ForestGlanceLoopBox;
