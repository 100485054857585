import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import Officerstable from "./components/list_of_ifs_officers";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";

const ListOfIfsOfficers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection imageUrl={ForestImageCover} title={" List Of Ifs Officers"} />
        </Box>
        <Box my="10">
          <HStack>
            <Box w="15%" h="100%"></Box>
            <Box w="65%" h="100%">
              <Officerstable />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default ListOfIfsOfficers;
