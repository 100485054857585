import React, { useEffect, useState } from "react";
import { Animated } from "react-native";
import { Input, Box } from "native-base";
import { Platform } from "react-native";

const FloatingLabelInput = (props) => {
  const { label, labelColor, labelBGColor, defaultValue, containerWidth } =
    props;
  const _animatedIsFocused = new Animated.Value(defaultValue === "" ? 0 : 1);
  const lableContainerStyles = {
    position: "absolute",
    left: 16,
    top: _animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: [12, -7],
    }),
    zIndex: 5,
    paddingLeft: 3,
    paddingRight: 3,
    backgroundColor: labelBGColor,
  };
  const AndroidlabelStyle = {
    fontWeight: "500",
    fontSize: _animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: [14, 12],
    }),

    color: labelColor,
  };
  const IOSlabelStyle = {
    fontWeight: "500",
    fontSize: _animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: [14, 12],
    }),

    marginTop: _animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: [-3, 0],
    }),
    color: labelColor,
  };
  const [isFocused, setIsFocused] = useState(false);

  // }

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  useEffect(() => {
    Animated.timing(_animatedIsFocused, {
      duration: 200,
      useNativeDriver: false,
      toValue: isFocused || defaultValue !== "" ? 1 : 0,
    }).start();
  }, []);

  return (
    <Box w={containerWidth}>
      <Animated.View pointerEvents="none" style={lableContainerStyles}>
        <Animated.Text
          style={Platform.OS === "android" ? AndroidlabelStyle : IOSlabelStyle}
        >
          {label}
        </Animated.Text>
      </Animated.View>
      <Input
        {...props}
        autoComplete={"off"}
        onFocus={handleFocus}
        onBlur={handleBlur}
        _hover={{ bg: labelBGColor }}
      />
    </Box>
  );
};
export default FloatingLabelInput;
