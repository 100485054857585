import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";

const TribalDevelopmentHTML = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <HTMLRenderer cmsKey={"tribal_development"} />
      </Box>
    </>
  );
};
export default TribalDevelopmentHTML;
