import { Card } from "antd";
import { Box, HStack, VStack, Text } from "native-base";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const UsefulLinksContent = (props) => {
  const { groupValues } = props;
  const moveToPath = (url) => {
    var a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
      slidesToSlide: 8, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div>
      {groupValues?.length?
       <Carousel responsive={responsive}>
        {groupValues?.map((item, index) => {
          return (
            <VStack
              space={5}
              cursor={"pointer"}
              onClick={() => {
                moveToPath(item.link);
              }}
            >
              <Box key={index} borderRadius={"5px"} height="50%">
                <img
                  alt={item.name}
                  src={item.image}
                  width="92px"
                  style={{ marginLeft: "10px" }}
                />
              </Box>
              <Box key={index} borderRadius={"5px"} height="30%">
                <Text
                  color={"black"}
                  textAlign="center"
                  style={{
                    width: "120px",
                  }}
                >
                  {item.tittle}
                </Text>
              </Box>
            </VStack>
          );
        })}
      </Carousel>:""}
    </div>
  );
};
export default UsefulLinksContent;
