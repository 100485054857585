import Wildlife from "@views/pages/wildlife";
import { Tabs } from "antd";
import { Box, Button, Center, HStack, VStack } from "native-base";
import HeaderSection from "@views/components/ui/header_section";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import BestPracticesHTML from "../components/best_practices";
const BestPracticesTab = () => {
  let tab_items = [
    {
      id: 1,
      label: `Innovations`,
      key: 1,
      children: <BestPracticesHTML />,
    },
    {
      id: 2,
      label: `Best Practices`,
      key: 2,
      children: <BestPracticesHTML />,
    },
    {
      id: 3,
      label: `Success Stories`,
      key: 3,
      children: <BestPracticesHTML />,
    },
  ];

  return (
    <Box
      style={{
        overflow: "hidden",
      }}
    >
      <VStack>
        <Box my="10">
          <Center>
            <HStack>
              <Box w={["100%","100%","85%"]}
              marginLeft={["200px","200px","0px"]}>
                <Tabs items={tab_items} />
              </Box>
            </HStack>
          </Center>
        </Box>
      </VStack>
    </Box>
  );
};
export default BestPracticesTab;
