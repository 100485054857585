import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Text,
  Card,
  Button,
  Row,
  Column,
} from "native-base";
import { useTranslation } from "react-i18next";
const handlePdf = (item) => {
  var a = document.createElement("a");
  var filePath = item;
  a.href = filePath;
  a.target = "_blank";
  a.download = filePath.substr(filePath.lastIndexOf("/") + 1);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const LatestNewsCardItem = (props) => {
  const dispatch = useDispatch();
  const { item } = props;
  const { t } = useTranslation();
  return (
    <Box>
      <Card variant="latestnews">
        <HStack>
          <Box w="50%">
            <VStack p="5" space={2}>
              <Text fontWeight="bolder">{item.title?.value}</Text>
              <Text color="#969e89">{"Remarks"}</Text>
              <Text fontSize={12}>{item.description?.value}</Text>
            </VStack>
          </Box>
          <Box w="40%">
            <VStack p="5" space={2}>
              <Text color="#969e89">{"Attachments"}</Text>
              {/* <HStack
                alignItem="start"
                justifyContent={"start"}
                // style={{ gap: "5px" }}
                space={2}
                w="50%"
              > */}
              {item.file?.value && (
                <Button
                  variant="outline"
                  width="120px"
                  borderColor="#6bb384"
                  _hover={{
                    borderColor: "#6bb384",
                    bg: "#026608",
                    _text: {
                      color: "#ffffff",
                    },
                  }}
                  _light={{
                    _text: {
                      color: "#026608",
                    },
                  }}
                  onPress={() => {
                    handlePdf(item.file.value);
                  }}
                >
                  {"File"}
                </Button>
              )}
              {item.notifications?.value && (
                <Button
                  variant="outline"
                  width="120px"
                  borderColor="#6bb384"
                  _hover={{
                    borderColor: "#6bb384",
                    bg: "#026608",
                    _text: {
                      color: "#ffffff",
                    },
                  }}
                  _light={{
                    _text: {
                      color: "#026608",
                    },
                  }}
                  // onPress={() => {
                  //   handlePdf(item);
                  // }}

                  onPress={() => {
                    handlePdf(item?.notifications?.value);
                  }}
                >
                  {"Notification"}
                </Button>
              )}
              {item.annexure?.value && (
                <Button
                  variant="outline"
                  width="120px"
                  borderColor="#6bb384"
                  _hover={{
                    borderColor: "#6bb384",
                    bg: "#026608",
                    _text: {
                      color: "#ffffff",
                    },
                  }}
                  _light={{
                    _text: {
                      color: "#026608",
                    },
                  }}
                  // onPress={() => {
                  //   handlePdf(item);
                  // }}

                  onPress={() => {
                    handlePdf(item.annexure.value);
                  }}
                >
                  {"Annexure"}
                </Button>
              )}
              {item.webinar_link?.value && (
                <Button
                  variant="outline"
                  width="120px"
                  borderColor="#6bb384"
                  _hover={{
                    borderColor: "#6bb384",
                    bg: "#026608",
                    _text: {
                      color: "#ffffff",
                    },
                  }}
                  _light={{
                    _text: {
                      color: "#026608",
                    },
                  }}
                  // onPress={() => {
                  //   handlePdf(item);
                  // }}

                  onPress={() => {
                    handlePdf(item.webinar_link.value);
                  }}
                >
                  {"Webinar Link"}
                </Button>
              )}
              {item.notice?.value && (
                <Button
                  variant="outline"
                  width="120px"
                  borderColor="#6bb384"
                  _hover={{
                    borderColor: "#6bb384",
                    bg: "#026608",
                    _text: {
                      color: "#ffffff",
                    },
                  }}
                  _light={{
                    _text: {
                      color: "#026608",
                    },
                  }}
                  // onPress={() => {
                  //   handlePdf(item);
                  // }}

                  onPress={() => {
                    handlePdf(item.notice.value);
                  }}
                >
                  {"Notice"}
                </Button>
              )}
              {item.tittle_file?.value && (
                <Button
                  variant="outline"
                  width="120px"
                  borderColor="#6bb384"
                  _hover={{
                    borderColor: "#6bb384",
                    bg: "#026608",
                    _text: {
                      color: "#ffffff",
                    },
                  }}
                  _light={{
                    _text: {
                      color: "#026608",
                    },
                  }}
                  // onPress={() => {
                  //   handlePdf(item);
                  // }}

                  onPress={() => {
                    handlePdf(item.tittle_file.value);
                  }}
                >
                  {item.title?.value}
                </Button>
              )}
              {item.application_form?.value && (
                <Button
                  variant="outline"
                  width="120px"
                  borderColor="#6bb384"
                  _hover={{
                    borderColor: "#6bb384",
                    bg: "#026608",
                    _text: {
                      color: "#ffffff",
                    },
                  }}
                  _light={{
                    _text: {
                      color: "#026608",
                    },
                  }}
                  // onPress={() => {
                  //   handlePdf(item);
                  // }}

                  onPress={() => {
                    handlePdf(item.application_form.value);
                  }}
                >
                  {"Application"}
                </Button>
              )}
              {/* </HStack> */}

              <Text color="#969e89">{"Deadline"}</Text>
            </VStack>
          </Box>
        </HStack>
      </Card>
    </Box>
  );
};
export default LatestNewsCardItem;
