import React, { useState } from "react";
import { Box, Center, HStack, Text, VStack } from "native-base";
const WildLifeSecondCard = () => {
  return (
    <Box h="60%" mt="20px">
      <HStack 
       flexDirection={{
        base: "column",
        md: "row",
      }}>
        <Box width={["100%","100%","50%"]} h="200px">
          <Box
            w="100%"
            h="100%"
            justifyContent={"center"}
            // borderRadius=""
            style={{ background: "#1a3723" }}
          >
            <>
              <Box paddingTop="10" paddingLeft="5">
                <Text color="white" fontWeight={"bold"} fontSize="20px">
                  The faunal diversity of the State
                </Text>
              </Box>
              <Box flex={1} paddingTop="10" paddingLeft="5">
                <Text color="white">
                  includes 165 species of fresh water fishes, 76 <br></br>
                  species of amphibians, 177 species of reptiles, 454 <br></br>
                  species of birds and 187 species of mammals.
                </Text>
              </Box>
            </>
          </Box>
        </Box>
        <Box width={["100%","100%","50%"]} h="200px" style={{ background: "#F9FAF2" }}>
          <>
            <Box paddingTop="10" paddingLeft="5">
              <Text color="black" fontWeight={"bold"} fontSize="20px">
                According to the CAMP reports
              </Text>
            </Box>

            <Box flex={1} paddingTop="10" paddingLeft="5">
              <Text color="black">
                Red-listed species include 126 species of fishes, <br></br>56
                species of amphibians, 77 species of reptiles, <br></br>32
                species of birds and 40 species of mammals.
              </Text>
            </Box>
          </>
        </Box>
      </HStack>
      <Box mt="30px" alignSelf="center" padding={5}>
        <Text fontSize="18px">
          <b>The endemic fauna includes </b>
          <br />
          36 species of amphibians, 63 species of reptiles, 17 species of birds,
          and 24  species of mammals.
        </Text>
      </Box>
    </Box>
  );
};
export default WildLifeSecondCard;
