import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, VStack, HStack, Center, Pressable, Text} from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import {useTranslation} from "react-i18next";
import Campa5ComponentsOfStateCampaHTML from "./components/campa_5_components_of_state_campa"
import GovernmentOrdersRelatedToStateCampaTable from "@views/pages/campa/components/government_orders_related_to_state_campa/government_orders_related_to_state_campa"


const Campa5ComponentsOfStateCampa = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    return (<>
        
    <Box><Campa5ComponentsOfStateCampaHTML/></Box>
    <Box><GovernmentOrdersRelatedToStateCampaTable/></Box>
        
    
    
    </>);
};
export default Campa5ComponentsOfStateCampa;

