import { gql } from "@apollo/client";

export const get_system_cms_value_query = gql`
    query getSystemCmsValue(
        $search_string: String
        $page_number: Int
        $system_cms_id: String
    ) {
        getSystemCmsValues(
            search_string: $search_string
            page_number: $page_number
            system_cms_id: $system_cms_id
        ) {
            items {
                id
                value
                system_cms_field_id
                system_cms_language_id
                system_cms_field_option_value_id
            }
        }
    }
`;

export const get_one_system_cms_value_query = gql`
    query getSystemCmsValue($json: get_system_cms_value_input) {
        getSystemCmsValue(json: $json) {
            id
            value
            system_cms_field_id
            system_cms_language_id
            system_cms_field_option_value_id
        }
    }
`;

export const delete_system_cms_value_mutation = gql`
    mutation deleteSystemCmsValue($json: delete_system_cms_value_input) {
        deleteSystemCmsValue(json: $json) {
            id
            status
            error {
                status_code
                message
            }
        }
    }
`;

export const create_system_cms_value_mutation = gql`
    mutation createSystemCmsValues($json: create_cms_values_input) {
        createSystemCmsValues(json: $json) {
            id
            status
            error {
                status_code
                message
            }
        }
    }
`;

export const update_system_cms_value_mutation = gql`
    mutation updateSystemCMSValue($json: system_cms_value_input, $id: String!) {
        updateSystemCMSValue(json: $json, id: $id) {
            id
            status
            error {
                status_code
                message
            }
        }
    }
`;
