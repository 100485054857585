import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";

const StrategiesHtml = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <VStack>
          <HStack>
            <Box flex={1} variant="thrust_area_left">
              <Text>
                {
                  " Ecosystem Services of Forests will be garnered to ensure urban and rural prosperity"
                }
              </Text>
            </Box>
            <Box flex={1}></Box>
          </HStack>
          <HStack>
            <Box flex={1}></Box>
            <Box flex={1} variant="thrust_area_right">
              <Text>
                {
                  " Professional systems will be institutionalized in execution, planning, reporting and monitoring for responsive and enabled Forest Department"
                }
              </Text>
            </Box>
          </HStack>
          <HStack>
            <Box flex={1} variant="thrust_area_left">
              <Text>
                {
                  " Five year tenure based programmes on landscape approach to be mainstreamed for plan proposals"
                }
              </Text>
            </Box>
            <Box flex={1}></Box>
          </HStack>
          <HStack>
            <Box flex={1}></Box>
            <Box flex={1} variant="thrust_area_right">
              <Text>
                {
                  " Human Resource Development including personnel management to be streamlined"
                }
              </Text>
            </Box>
          </HStack>{" "}
          <HStack>
            <Box flex={1} variant="thrust_area_left">
              <Text textAlign="justify">
                {
                  " Support of local community will be harnessed to strengthen the protection system through a humane connectivity with Tribal’s and local community"
                }
              </Text>
            </Box>
            <Box flex={1}></Box>
          </HStack>{" "}
          <HStack>
            <Box flex={1}></Box>
            <Box flex={1} variant="thrust_area_right">
              <Text>
                {
                  "Improving infrastructure, connectivity, communication and technology to strengthen forest protection"
                }
              </Text>
            </Box>
          </HStack>
          <HStack>
            <Box flex={1} variant="thrust_area_left">
              <Text>
                {
                  "Organizing an efficient forestry research, education and extension activities"
                }
              </Text>
            </Box>
            <Box flex={1}></Box>
          </HStack>{" "}
        </VStack>
      </Box>
    </>
  );
};
export default StrategiesHtml;
