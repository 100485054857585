import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import BiodiversityHTML from "./components/biodiversity";
import PioneersInSearchOfThePresidencyFloraHTML from "./components/biodiversity_collapsible/pioneers_in_search_of_the_presidency_flora";
import BioDiversityCollapsible from "./components/biodiversity_collapsible/biodiversity_collapsible";
const Biodiversity = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <Box my="10" >
        <VStack>
          <Box>
            <BiodiversityHTML />
          </Box>
          <Box>
            <BioDiversityCollapsible />
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};
export default Biodiversity;
