import { gql } from "@apollo/client";

export const createEdafMutation = gql`
  mutation createEdaf($data: edaf_input) {
    create_edaf: createEdaf(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateEdafMutation = gql`
  mutation updateEdaf($id: String!, $data: edaf_input) {
    update_edaf: updateEdaf(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const deleteEdafMutation = gql`
  mutation deleteEdaf($id: String!) {
    delete_edaf: deleteEdaf(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getAllEdafQuery = gql`
  query getEdafs($search_string: String, $page_number: Int, $page_limit: Int) {
    get_all_edaf: getEdafs(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        edaf_date
        pdf
        gender
        circle_id
        division_id
        range_id
        circle {
          id
          name
        }
        division {
          id
          name
        }
        range {
          id
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const getCirclesQuery = gql`
  query getCircles {
    get_all_circle: getCircles {
      items {
        id
        name
        areas {
          id
          name
          areas {
            id
            name
          }
        }
      }
    }
  }
`;
