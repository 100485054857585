import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";

const RecruitmentHTML = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Text fontSize="30px" bold textAlign={"center"} color={"rgb(37 80 66)"}>
          Salient features of Recruitment
        </Text>
        <Text
          mt="10px"
          fontSize="20px"
          fontWeight="500"
          textAlign={"center"}
          color={"rgb(37 80 66)"}
        >
          for the first time ever in the history of Tamil Nadu...
        </Text>
      </Box>
      <Box>
        <HTMLRenderer cmsKey={"recruitment"} />
      </Box>
    </>
  );
};
export default RecruitmentHTML;
