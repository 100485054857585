import { Collapse } from "antd";
import React from "react";
import SocialForestryForestryExtensionHTML from "./social_forestry_forestry_extension";
import SocialForestryTankForeshorePlantationsHTML from "./social_forestry_tank_foreshore_plantations";
import SocialForestryTreeCultivationInPrivateLandsHTML from "./social_forestry_tree_cultivation_in_private_lands";
const { Panel } = Collapse;
const SocialForesytryCollapsible = () => {
  return (
    <Collapse defaultActiveKey={["1"]} expandIconPosition={"end"} ghost>
      <Panel header="Tree Cultivation in Private Lands" key="1">
        <SocialForestryTreeCultivationInPrivateLandsHTML />
      </Panel>
      <Panel header="Tank Foreshore Plantations" key="2">
        <SocialForestryTankForeshorePlantationsHTML />
      </Panel>
      <Panel header="Forestry Extension" key="3">
        <SocialForestryForestryExtensionHTML />
      </Panel>
    </Collapse>
  );
};
export default SocialForesytryCollapsible;
