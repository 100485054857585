import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import WildLifeHTML from "./components/wild_life";
import WildlifeTableTable from "./components/wildlife_table";
import WildLifeFirstCard from "./components/cards/wild_life_first_card";
import WildLifeSecondCard from "./components/cards/wild_life_second_card";
import WildlifeThirdCard from "./components/cards/wild_life_third_card";

const Wildlife = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Box>
          <WildLifeFirstCard />
        </Box>
        <Box marginTop={"30px"}>
          <WildLifeSecondCard />
        </Box>
        <Box>
          <WildlifeThirdCard />
        </Box>
        <Box marginTop={"30px"} padding={5}>
          <WildlifeTableTable />
        </Box>
      </Box>
    </>
  );
};
export default Wildlife;
