import { Tabs } from "antd";
import UsefulLinksContent from "./useful_links_content";
import {
  getSystemCMSFieldUserListSelector,
  get_system_cms_user_field_request,
} from "@services/redux";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const UsefulLinksTab = () => {
  const dispatch = useDispatch();
  const { items } = useSelector(getSystemCMSFieldUserListSelector);
  const [groupValues, setGroupValues] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(
      get_system_cms_user_field_request({
        json: {
          system_cms_key: "usefull_link",
        },
      })
    );
  }, []);
  useEffect(() => {
    if (items?.length) {
      let cms = items.find((e) => e.key === "usefull_link");
      if (cms?.field_items?.length) {
        let group_key = {
          department: { row_number: [], items: [] },
          interdepartment: { row_number: [], items: [] },
        };
        let group_item = cms.field_items.find((x) => x.key === "group");
        group_item.system_cms_values.map((item) => {
          group_key?.[item.value]?.["row_number"].push(item.row_number);
        });
        cms?.field_items.map((cms_field_item) => {
          if (cms_field_item.key !== "group")
            cms_field_item.system_cms_values.map((cms_value_item) => {
              Object.keys(group_key).map((key_group) => {
                if (
                  group_key?.[key_group]?.["row_number"].includes(
                    cms_value_item.row_number
                  )
                ) {
                  let object_index = group_key?.[key_group]?.[
                    "items"
                  ].findIndex(
                    (item) => item.row_number === cms_value_item.row_number
                  );
                  let assign_object = {};
                  assign_object[cms_field_item.key] = cms_value_item.value;
                  if (object_index !== -1)
                    group_key[key_group]["items"][object_index] = {
                      ...group_key[key_group]["items"][object_index],
                      ...assign_object,
                    };
                  else
                    group_key?.[key_group]?.["items"].push({
                      ...assign_object,
                      row_number: cms_value_item.row_number,
                    });
                }
              });
            });
        });
        setGroupValues(group_key);
      }
    }
  }, [items]);
  let tab_items = [
    {
      id: 1,
      label: t("department_links"),
      key: 1,
      children: (
        <UsefulLinksContent
          groupValues={groupValues["department"]?.["items"]}
        />
      ),
    },
    {
      id: 2,
      label: t("inter_department_links"),
      key: 2,
      children: (
        <UsefulLinksContent
          groupValues={groupValues["interdepartment"]?.["items"]}
        />
      ),
    },
  ];

  return <Tabs items={tab_items} />;
};
export default UsefulLinksTab;
