import { BASE_URL_ASSETS } from "@helpers/constants";
import {
  Box,
  HStack,
  Hidden,
  Image,
  Pressable,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Software from "@src/assets/images/it/Animation.gif";
import { ROUTES } from "@views/routes/my_routes";
const HomeReactPlayer = (props) => {
  const videoEl = useRef(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const history = useHistory();
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };
  const moveToPage = (routes) => {
    history.push({
      pathname: `${routes}`,
    });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  useEffect(() => {
    // Toggle the animation every 1.5 seconds (adjust as needed)
    const interval = setInterval(() => {
      setShowAnimation((prev) => !prev);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  
  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column", // Stack children vertically
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <video
        style={{
          width: "100%",
        }}
        playsInline
        loop
        muted
        controls={false}
        alt="All the devices"
        src={BASE_URL_ASSETS + "TNFD-Home-Video.mp4"}
        ref={videoEl}
      />
    
    </div>
  );
};
export default HomeReactPlayer;
