import React, { useState } from "react";
import "./homestyle.css";
import HeaderTNFD from "@views/components/ui/header_tnfd";
import HomeMenu from "./menu";
import { useBreakpointValue } from "native-base";

const HomeHeader = () => {
  const [collapsed, setCollapsed] = useState( useBreakpointValue({
    base: true,
    sm: true,
    xs: true,
    md:  false,
    lg:  false,
    xl: false,
  }));

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div>
      <HeaderTNFD toggleCollapsed={toggleCollapsed} collapsed={collapsed}/>
      <HomeMenu collapsed={collapsed}/>
    </div>
  );
};

export default HomeHeader;
