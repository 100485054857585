import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, VStack, HStack, Center, Pressable, Text} from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import {useTranslation} from "react-i18next";
import CampaFundingyHTML from "./components/campa_funding"
import AnnualPlanCampaTable from "@views/pages/campa/components/annual_plan_campa";


const CampaFunding = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    return (<>
        
    <Box><CampaFundingyHTML/></Box>
    <br/>
    <Box><AnnualPlanCampaTable/></Box>
    
    
    
    </>);
};
export default CampaFunding;

