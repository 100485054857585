import { Card } from "antd";
import { AspectRatio, Box, Center, HStack, Image, VStack } from "native-base";
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";

const GalleryContent = (props) => {
  const { groupValues, cms_key } = props;
  useEffect(() => {}, [cms_key]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <HStack space={20} justifyContent={"center"}>
      <a
        href="https://www.facebook.com/profile.php?id=100089369856476"
        target="blank"
      >
        <BsFacebook size={100} color="#1877F2" cursor={"pointer"} />
      </a>
      <a href="https://twitter.com/tnforestdept" target="blank">
        <AiFillTwitterCircle size={110} color="#1877F2" cursor={"pointer"} />
      </a>
      <a href="https://www.youtube.com/" target="blank">
        <BsYoutube size={100} color="#FF0000" cursor={"pointer"} />
      </a>
      <a
        href="https://instagram.com/tnforestdept?igshid=YmMyMTA2M2Y="
        target="blank"
      >
        <AiFillInstagram size={100} color="#F70947" cursor={"pointer"} />
      </a>
    </HStack>
  );
};
export default GalleryContent;
