import React, { useState } from "react";
import { Box, Center, HStack, Text, VStack } from "native-base";
const RecruitmentSecondCard = () => {
  return (
    <Box h="60%" mt="16px">
      <Box w="100%" h="250px">
        <Box
          w="100%"
          h="100%"
          justifyContent={"center"}
          style={{ background: "rgb(25 135 59)" }}
        >
          <VStack space={5}>
            <HStack>
              <Box alignSelf="center" flex={1}>
                <Text
                  color="white"
                  fontWeight={"bold"}
                  fontSize="42px"
                  textAlign={"center"}
                >
                  3.48L
                </Text>
              </Box>
              <Box alignSelf="center" flex={1}>
                <Text
                  color="white"
                  fontWeight={"bold"}
                  fontSize="42px"
                  textAlign={"center"}
                >
                  2.2L
                </Text>
              </Box>
              <Box alignSelf="center" flex={1}>
                <Text
                  color="white"
                  fontWeight={"bold"}
                  fontSize="42px"
                  textAlign={"center"}
                >
                  1.5L
                </Text>
              </Box>
              <Box alignSelf="center" flex={1}>
                <Text
                  color="white"
                  fontWeight={"bold"}
                  fontSize="42px"
                  textAlign={"center"}
                >
                  150
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Box alignSelf="center" flex={1}>
                <Text
                  color="white"
                  //   fontWeight={"bold"}
                  fontSize="16px"
                  textAlign={"center"}
                >
                  Applications Received
                </Text>
              </Box>
              <Box alignSelf="center" flex={1}>
                <Text
                  color="white"
                  //   fontWeight={"bold"}
                  fontSize="16px"
                  textAlign={"center"}
                >
                  Applications Received
                </Text>
              </Box>
              <Box alignSelf="center" flex={1}>
                <Text
                  color="white"
                  //   fontWeight={"bold"}
                  fontSize="16px"
                  textAlign={"center"}
                >
                  Applications Received
                </Text>
              </Box>
              <Box alignSelf="center" flex={1}>
                <Text
                  color="white"
                  //   fontWeight={"bold"}
                  fontSize="16px"
                  textAlign={"center"}
                >
                  Applications Received
                </Text>
              </Box>
            </HStack>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};
export default RecruitmentSecondCard;
