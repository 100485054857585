import { useParams } from "react-router-dom";
import {
  getSystemCMSFieldUserListSelector,
  get_system_cms_user_field_request,
} from "@services/redux";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Protectedcms from "./protectedcms";

const ProtectedAreaCms = (props) => {
  const dispatch = useDispatch();
  const { items } = useSelector(getSystemCMSFieldUserListSelector);
  const [groupValues, setGroupValues] = useState("");
  const { key } = useParams();
  useEffect(() => {
    dispatch(
      get_system_cms_user_field_request({
        json: {
          system_cms_key: "protected_area_cms",
        },
      })
    );
  }, []);

  useEffect(() => {
    if (items?.length) {
      let cms = items.find((e) => e.key === "protected_area_cms");
      if (cms?.field_items?.length) {
        let group_key = {
          pulicat_bird_sanctuary: { row_number: [], items: [] },
          guindy_national_zoological_park: { row_number: [], items: [] },
          arignar_anna_zoological_park: { row_number: [], items: [] },
          karikili_bird_sanctuary: { row_number: [], items: [] },
          vedanthangal_bird_sanctuary: { row_number: [], items: [] },
          amirdhi_mini_zoo: { row_number: [], items: [] },
          oussdu_lake_bird_sanctuary: { row_number: [], items: [] },
          sathanur_crocodile_far: { row_number: [], items: [] },
          cauvery_north_wildlife_sanctuary: { row_number: [], items: [] },
          hogenakkal_crocodile_farm: { row_number: [], items: [] },
          kurumbapatti_zoological_park: { row_number: [], items: [] },
          sathyamangalam_tiger_reserve: { row_number: [], items: [] },
          mudumalai_national_park_and_sanctuary: { row_number: [], items: [] },
          nilgiri_biosphere_reserve: { row_number: [], items: [] },
          mukurthi_national_park: { row_number: [], items: [] },
          vellode_bird_sanctuary: { row_number: [], items: [] },
          karaivetti_bird_sanctuary: { row_number: [], items: [] },
          butterfly_park_and_nakshatravanam: { row_number: [], items: [] },
          vadavoor_bird_sanctuary: { row_number: [], items: [] },
          udayamarthandapuram_bird_sanctuary: { row_number: [], items: [] },
          point_calimere_wildlife_sanctuary_block_a_b: {
            row_number: [],
            items: [],
          },
          vettangudi_bird_sanctuary: { row_number: [], items: [] },
          anamalai_tiger_reserve_wildlife_sancturay_and_national_park: {
            row_number: [],
            items: [],
          },
          kodaikanal_wildlife_sanctuary: { row_number: [], items: [] },
          megamalai_wildlife_sanctuary: { row_number: [], items: [] },
          grizzled_giant_squirrel_sanctuary: { row_number: [], items: [] },
          nellai_wildlife_sanctuary: { row_number: [], items: [] },
          gangaikondan_spotteddeer_sanctuary: { row_number: [], items: [] },
          agasthiyarmalai_biosphere_reserve: { row_number: [], items: [] },
          kalakad_mundanthurai_tiger_reserve: { row_number: [], items: [] },
          kanyakumari_wildlife_sanctuary: { row_number: [], items: [] },
          suchindrum_theroor_managudi_conservation_reserve: {
            row_number: [],
            items: [],
          },
          koonthankulam_Kadankulam_bird_sanctuary: {
            row_number: [],
            items: [],
          },
          tiruppadaimaruthur_birds_conservation_reserve: {
            row_number: [],
            items: [],
          },
          vallanadu_black_buck_sanctuary: { row_number: [], items: [] },
          gulf_of_mannar_biosphere_reserve_and_national_park: {
            row_number: [],
            items: [],
          },
          sakkarakottai_tank_bird_sanctuaries: { row_number: [], items: [] },
          theerthangal_bird_sanctuaries: { row_number: [], items: [] },
          kanjirankulam_and_chitrangudi_water_bird_sanctuaries: {
            row_number: [],
            items: [],
          },
        };
        let group_item = cms.field_items.find(
          (x) => x.key === "protected_area_name"
        );
        group_item.system_cms_values.map((item) => {
          group_key?.[item.value]?.["row_number"].push(item.row_number);
        });
        cms?.field_items.map((cms_field_item) => {
          if (cms_field_item.key !== "type")
            cms_field_item.system_cms_values.map((cms_value_item) => {
              Object.keys(group_key).map((key_group) => {
                if (
                  group_key?.[key_group]?.["row_number"].includes(
                    cms_value_item.row_number
                  )
                ) {
                  let object_index = group_key?.[key_group]?.[
                    "items"
                  ].findIndex(
                    (item) => item.row_number === cms_value_item.row_number
                  );
                  let assign_object = {};
                  assign_object[cms_field_item.key] = cms_value_item.value;
                  if (object_index !== -1)
                    group_key[key_group]["items"][object_index] = {
                      ...group_key[key_group]["items"][object_index],
                      ...assign_object,
                    };
                  else
                    group_key?.[key_group]?.["items"].push({
                      ...assign_object,
                      row_number: cms_value_item.row_number,
                    });
                }
              });
            });
        });
        setGroupValues(group_key);
      }
    }
  }, [items]);

  return (
    <>
      <Protectedcms groupValues={groupValues} cms_key={key} />
    </>
  );
};
export default ProtectedAreaCms;
