import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./antd.css";
import { Provider } from "react-redux";
import {
  Box,
  NativeBaseProvider,
  extendTheme,
  theme as nbTheme,
} from "native-base";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconContext } from "react-icons";
import { store } from "@services/redux/store";
import App from "./App";
import "./i18n";

const theme = extendTheme({
  colors: {
    primary: nbTheme.colors.violet,
    primaryContent: nbTheme.colors.warmGray,
    inputBorder: nbTheme.colors.coolGray,
    error: nbTheme.colors.red,
  },
  components: {
    Text: {
      defaultProps: {
        fontFamily: "Poppins",
      },
      variants: {
        header: () => {
          return {
            color: "white",
            fontWeight: 500,
            fontSize: 11,
            cursor: "pointer",
          };
        },
        footer_link: () => {
          return {
            color: "#d7d7d7",
            cursor: "pointer",
          };
        },
        govt_of_tn_eng: () => {
          return {
            color: "#707070",
            fontWeight: 300,
            fontSize: "22px",
          };
        },
        govt_of_tn_tam: () => {
          return {
            color: "#707070",
            fontWeight: 300,
            fontSize: "22px",
          };
        },
        govt_of_tn_eng: () => {
          return {
            color: "#707070",
            fontWeight: 300,
            fontSize: "22px",
          };
        },
        forest_department_eng: () => {
          return {
            color: "#707070",
            fontWeight: "bolder",
            fontSize: "22px",
          };
        },
        forest_department_tam: () => {
          return {
            color: "#707070",
            fontWeight: "bolder",
            fontSize: "22px",
          };
        },
      },
    },
    Card: {
      variants: {
        tender: () => {
          return {
            background: "#f8fbef",
            height: "30vh",
          };
        },
      },
    },
    Box: {
      variants: {
        thrust_area_left: () => {
          return {
            width: "45%",
            marginTop: "20px",
            padding: "20px",
            borderRadius: "20px",
            background: "#E7F6DB",
            height: "auto",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
            justifyContent: "center",
            // boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
          };
        },
        thrust_area_right: () => {
          return {
            width: "45%",
            marginTop: "20px",
            padding: "20px",
            borderRadius: "20px",
            background: "#F9FAF2",
            height: "auto",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
            justifyContent: "center",
          };
        },
        direct_recruitment_left: () => {
          return {
            width: "45%",
            marginTop: "20px",
            padding: "20px",
            borderRadius: "20px",
            background: "#E7F6DB",
            // boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
            justifyContent: "center",
          };
        },
        direct_recruitment_right: () => {
          return {
            width: "45%",
            marginTop: "20px",
            padding: "20px",
            borderRadius: "20px",
            background: "#F9FAF2",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
            justifyContent: "center",
          };
        },
      },
    },
  },

  breakpoints: {
    base: 0,
    xs: 320,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
    "2xl": 2560,
    "3xl": 3840,
  },
});
ReactDOM.render(
  <Provider store={store}>
    <NativeBaseProvider theme={theme}>
      <IconContext.Provider value={{ className: "global-class-name" }}>
        <App />
        <ToastContainer newestOnTop pauseOnFocusLoss />
      </IconContext.Provider>
    </NativeBaseProvider>
  </Provider>,
  document.getElementById("root")
);
