import React, { useState } from "react";
import { Box, VStack } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import HeaderBar from "@views/components/ui/header_bar";
import FooterBar from "@views/components/ui/footer_bar";
import HomeMenu from "@views/pages/Menu/menu";
import HomeHeader from "@views/pages/Menu/home";
import Footer_tnfd from "@views/components/ui/footer_tnfd";

const Layout1 = ({ children }) => {
  const [menuExpanded, setMenuExpanded] = useState(false);
  return (
    <Box flexDirection="row" flexGrow="1">
      <VStack flex={1}>
        <ScrollView>
          <HomeHeader />
          <Box flex={1} mt={menuExpanded ? "220px" : "0px"}>
            {children}
          </Box>
          <Footer_tnfd />
        </ScrollView>
      </VStack>
    </Box>
  );
};
export default Layout1;
