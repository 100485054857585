import { gql } from "@apollo/client";

export const get_system_cms_field_query = gql`
    query getSystemCmsFields($json: get_all_system_cms_field_input) {
        getSystemCmsFields(json: $json) {
            items {
                id
                key
                type
                system_cms{
                    id
                }
                system_cms_values {
                    id
                    value
                    row_number
                    system_cms_field_id
                    system_cms_language_id
                    system_cms_field_option_value_id
                }
            }
        }
    }
`;

// export const get_one_system_cms_value_query = gql`
//   query getSystemCmsValue($id: String!) {
//     getSystemCmsValue(id: $id) {
//       id
//       value
//       system_cms_field_id
//       system_cms_language_id
//       system_cms_field_option_value_id
//     }
//   }
// `;

// export const delete_system_cms_value_mutation = gql`
//   mutation deleteSystemCmsValue($id: String!) {
//     deleteSystemCmsValue(id: $id) {
//       id
//       status
//       error {
//         status_code
//         message
//       }
//     }
//   }
// `;

// export const create_system_cms_value_mutation = gql`
// mutation createSystemCmsValues($json: create_cms_values_input){
//   createSystemCmsValues(json: $json) {
//     id
//     status
//     error{
//       status
//       message
//     }
//   }
// }
// `;

// export const update_system_cms_value_mutation = gql`
//   mutation updateSystemCMSValue($json: system_cms_value_input, $id: String!) {
//     updateSystemCMSValue(json: $json, id: $id) {
//       id
//       status
//       error {
//         status_code
//         message
//       }
//     }
//   }
// `;
