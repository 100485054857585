import { combineReducers } from "redux";
import { accordionsReducer } from "./default_slices/accordions";
import { formsReducer } from "./default_slices/forms";
import { listQueriesReducer } from "./default_slices/list_queries";

import { loginReducers } from "./slices/login";
import { pageReducers } from "./slices/page";
import { systemCMSValueReducers } from "./slices/system_cms_value";
import { cmsReducers } from "./slices/cms";
import { systemCMSFieldReducers } from "./slices/system_cms_field";
import { edafReducers } from "./slices/edaf";
import { dynamicReducers } from "./slices/dynamic_entity";

//IMPORT

export const rootReducer = combineReducers({
  ...accordionsReducer,
  ...formsReducer,
  ...listQueriesReducer,
  ...loginReducers,
  ...pageReducers,
  ...systemCMSValueReducers,
  ...cmsReducers,
  ...systemCMSFieldReducers,
  ...edafReducers,
  ...dynamicReducers,
  //EXPORT
});
