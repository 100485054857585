import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  Hidden,
} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";
import wildlifefirst from "@assets/images/menu/wildlifefirst.png";
import insect from "@assets/images/menu/insect.png";

const WildLifeFirstCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Box
          //   flex={1}
          style={{
            width: "100%",
            marginTop: "20px",
            padding: "20px",
            // borderRadius: "20px",
            background: "#F9FAF2",
            height: "200px",
            // boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px",
            justifyContent: "center",
          }}
        >
          <HStack>
            <Hidden  only={["xs", "sm",]}>
              <Box justifyContent="center" padding="10">
                <img src={wildlifefirst} alt="forest" width="600px" />
              </Box>
            </Hidden>

            <Hidden only={["xl", "md", "lg"]}>
              <Box justifyContent="center" padding="10">
                <img src={wildlifefirst} alt="forest" width="300px" />
              </Box>
            </Hidden>
          </HStack>
        </Box>
        <Box mt="20px" alignSelf="center" padding={5}>
          <Text fontSize={"15px"} fontWeight="600">
            Tamilnadu's faunal diversity is equally impressive. Dr. K.
            Venkataraman, Zoological Survey of  India, Chennai has
            published about
          </Text>
        </Box>
        <Box mt="20px" alignSelf="center">
          <HStack justifyContent={"center"}
           flexDirection={{
            base: "column",
            md: "row",
          }}
           >
            <Box flex={1}>
              <img src={insect} alt="forest" width="200px" />
              <Box flex={1} textAlign="center">
                <Text fontSize={"15px"} fontWeight="600">
                  595 freshwater fauna
                </Text>
              </Box>
            </Box>
            <Box flex={1}>
              <img src={insect} alt="forest" width="200px" />
              <Box flex={1} textAlign="center">
                <Text fontSize={"15px"} fontWeight="600">
                  2247 marine fauna
                </Text>
              </Box>
            </Box>
            <Box
              flex={1}
              style={{
                justifyContent: " center",
              }}
            >
              <img src={insect} alt="forest" width="200px" />
              <Text fontSize={"15px"} fontWeight="600" textAlign="center">
                1898 terrestrial fauna
              </Text>
              {/* </Box> */}
            </Box>
          </HStack>
        </Box>
      </Box>
    </>
  );
};
export default WildLifeFirstCard;
