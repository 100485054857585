import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  Hidden,
} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";

const DirectRecruitment = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Text
        fontSize="22px"
        fontWeight="bold"
        textAlign={"center"}
        color={"rgb(37 80 66)"}
      >
        Direct Recruitment
      </Text>
      <Text fontSize="14px" textAlign={"center"} color={"rgb(37 80 66)"}>
        in the year of 2018
      </Text>
      <Box>
        <VStack>
          <HStack space={15}>
            <Box
              variant="direct_recruitment_left"
              flex={1}
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 5px",
              }}
            >
              <Text>
                Notification : 06.10.2018 for 1178 posts of front line staff.
                <br />
                - 300 Foresters,
                <br />
                - 726 Forest Guards
                <br />- 152 Forest Guard with Driving Licence{" "}
              </Text>
            </Box>
            <Hidden only={["sm", "xs"]}>
              <Box flex={1}></Box>
            </Hidden>
          </HStack>
          <HStack space={15}>
          <Hidden only={["sm", "xs"]}>
              <Box flex={1}></Box>
            </Hidden>
            <Box
              flex={1}
              variant="direct_recruitment_right"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 5px",
              }}
            >
              <Text>The online examination: 06.12.2018 to 11.12.2018</Text>
            </Box>
          </HStack>
          <HStack space={15}>
            <Box
              flex={1}
              variant="direct_recruitment_left"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 5px",
              }}
            >
              <Text>
                The Question Answer challenge was made available online
              </Text>
            </Box>
            <Hidden only={["sm", "xs"]}>
              <Box flex={1}></Box>
            </Hidden>
          </HStack>
          <HStack space={15}>
          <Hidden only={["sm", "xs"]}>
              <Box flex={1}></Box>
            </Hidden>
            <Box
              flex={1}
              variant="direct_recruitment_right"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 5px",
              }}
            >
              <Text>
                The post examination procedures: Certificate Verification (CV),
                Physical Standards Verification (PSV), Endurance Test (ET) and
                Personality Test (PT) : January 2019 and February 2019
              </Text>
            </Box>
          </HStack>
          <HStack space={15}>
            <Box
              flex={1}
              variant="direct_recruitment_left"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 5px",
              }}
            >
              <Text textAlign="justify">
                The final result - Forest Guard and Forest Guard with Driving
                Licence : 27th February 2019
              </Text>
            </Box>
            <Hidden only={["sm", "xs"]}>
              <Box flex={1}></Box>
            </Hidden>
          </HStack>{" "}
          <HStack space={15}>
          <Hidden only={["sm", "xs"]}>
              <Box flex={1}></Box>
            </Hidden>
            <Box
              flex={1}
              variant="direct_recruitment_right"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 5px",
              }}
            >
              <Text>
                The Hon’ble Chief Minister on 04.03.2019 distributed allotment
                orders to 10 provisionally selected candidates
              </Text>
            </Box>
          </HStack>
          <HStack space={15}>
            <Box
              flex={1}
              variant="direct_recruitment_left"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 5px",
              }}
            >
              <Text>
                Despite severe constraints, in an pioneering effort, the Direct
                Recruitment to 1178 posts was accomplished in record time of 2½
                months for the post of Forest Guard and about 4 months for the
                post of Forester
              </Text>
            </Box>
            <Hidden only={["sm", "xs"]}>
              <Box flex={1}></Box>
            </Hidden>
          </HStack>
          <HStack space={15}>
          <Hidden only={["sm", "xs"]}>
              <Box flex={1}></Box>
            </Hidden>
            <Box
              flex={1}
              variant="direct_recruitment_right"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 5px",
              }}
            >
              <Text>
                The final result for the post of Forester was published on 16th
                April 2019 after conclusion of court case in Madurai Bench of
                Chennai High Court and after getting clearance from the Election
                Commission
              </Text>
            </Box>
          </HStack>
        </VStack>
      </Box>
    </>
  );
};
export default DirectRecruitment;
