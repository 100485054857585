import React, { useState } from "react";
import { Box, Center, HStack, Text, VStack } from "native-base";
import ForestGlanceLoopBox from "./forest_glance_loop_box";
import ForestGlanceDisplay from "./forest_glance_display";
import { useTranslation } from "react-i18next";
import { BASE_URL_ASSETS } from "@helpers/constants";

const ForestGlance = () => {
  const { t } = useTranslation();
  const forest_glance_list = [
    {
      id: 1,
      name: t("Forest_at_a_Glance"),
      content: t(
        "The State Tamil Nadu has a spectrum of nine major forest types ranging from wet evergreen forest to moist deciduous, dry deciduous, sholas, grass lands and scrub forest. The Western Ghats, the longest hill range in the State is one of the 25 global hotspots of bio-diversity and one of the three mega centers of endemism in India"
      ),
    },
    {
      id: 2,
      name: t("TN_State_Profile"),
      content: t(
        "Forest Cover of the State is 26,419 sq.km which is 20.31% of the State’s geographical area. In terms of forest canopy density classes, the State has 3,593 sq.km (2.76 % of GA) very dense forest,  11,034 sq.km (8.48% of GA)moderately dense forest and open forest and 11,792 sq.km (9.07% of GA) respectively. There is an increase of 55.21 sq.km in the forest cover of the state as compared to 2021 assessment."
      ),
    },
    {
      id: 3,
      name: t("TN_District_Wise_Profile"),
      content: t(
        "District-wise forest cover shows districts like Erode (2,295.73 sq. km.), Coimbatore (1,985.04 sq. km), Dindigul (1,877.97 sq.km), Vellore (1,824.98 sq. km), The Nilgiris(1,731.38 sq.km) and Dharmapuri (1,702.71 sq.km) covers higher forest cover.The forest and tree cover of the State now is 30,843.23 sq km which constitutes 23.71% of the total geographical area of the State."
      ),
    },
    {
      id: 4,
      name: t("TN_Forest_Types"),
      content: t(
        "In Tamil Nadu, Forest vegetation is divided into 3 major groups according to temperature zones namely Tropical Forests, Montane Subtropical Forests and Montane Temperate Forests, which are sub divided into nine type groups based on moisture and physiognomic variation"
      ),
    },
    // { id: 5, name: "FSI Reports", content: "FSI Reports" },
  ];
  const [selectedItem, setSelectedIteme] = useState(forest_glance_list[0]);
  const [displayValue, setDisplayValue] = useState(
    forest_glance_list[0].content
  );
  const parentOnClick = (item) => {
    setDisplayValue(item.content);
    setSelectedIteme(item);
    return item;
  };
  return (
    <Box
      style={{
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
        // overflowX: "hidden",
      }}
    >
      <Box>
        <img
          className="forestglance"
          src={BASE_URL_ASSETS + "forest-at-glance.png.png"}
          alt="Menu Image"
          // width={["100%"]}
          // height={["600px"]}
          style={{ overflowY: "hidden" }}
        />
      </Box>
      <Box h="100%" w="100%" position="absolute" bg="black" opacity={0.5}></Box>
      <Box position="absolute" h="100%" w="100%">
        <Box h="100%">
          <HStack
            h="100%"
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box h={["30%", "43%", "60%"]} w={["0%", "95%", "30%"]}>
              <Box
                w="100%"
                h="100%"
                bg="white"
                borderLeftRadius={"15px"}
                px="36px"
                marginTop={["0px", "-5px", "0px"]}
              >
                <ForestGlanceLoopBox
                  forest_glance_list={forest_glance_list}
                  selectedItem={selectedItem}
                  parentOnClick={parentOnClick}
                />
              </Box>
            </Box>
            <Box w={["0%", "95%", "50%"]} h={["50%", "45%", "60%"]}>
              <Box
                w="100%"
                h="100%"
                justifyContent={"center"}
                marginTop={["0px", "-5px", "0px"]}
                style={{ background: "#1a3723", opacity: "0.7" }}
              >
                <ForestGlanceDisplay displayValue={displayValue} />
              </Box>
            </Box>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};
export default ForestGlance;
