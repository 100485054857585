export const ProjectName = "Tamil Nadu Forest Department";
export const gender_list = [
    { id: "male", name: "ஆண்" },
    { id: "female", name: "பெண்" },
];
export const differently_abled = [
    { id: "yes", name: "Yes" },
    { id: "no", name: "No" },
];

export const relation_type = [
    { id: "father", name: "Father" },
    { id: "mother", name: "Mother" },
    { id: "son", name: "Son" },
];

export const discipline_type = [
    { id: "group", name: "குழு" },
    { id: "individal", name: "தனிப்பட்ட" },
];

export const blood_group_type = [
    { id: "a_posive", name: "A+" },
    { id: "a_negative", name: "A-" },
];

export const size_type = [
    { id: "s", name: "S" },
    { id: "m", name: "M" },
    { id: "l", name: "L" },
    { id: "xl", name: "XL" },
    { id: "xxl", name: "XXL" },
    { id: "xs", name: "XS" },
];

export const level_type = [
    { id: "district", name: "District" },
    { id: "Regional", name: "Regional" },
    { id: "state", name: "State" },
    { id: "national", name: "National" },
    { id: "international", name: "International" },
];

export const Mailregex = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
);

export const Pincoderegex = new RegExp("^6[0-9]{5}$");

// export const BASE_URL_ASSETS= "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/"
export const BASE_URL_ASSETS = "https://forests.tn.gov.in/storage/tnfd/"
