import React from "react";
//  import './index.css';
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Card } from "antd";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { Pressable } from "native-base";

const AntdCard = (props) => {
  const { Meta } = Card;

  const history = useHistory();
  const moveToPage = (routes) => {
    history.push({
      pathname: `${routes}`,
    });
  };
  const { item } = props;
  return (
    <Pressable
      onPress={() => {
        history.push({
          pathname: `${ROUTES.ALBUMS}`,
          state: { detail: item.album_type },
        });
      }}
    >
      <Card
        style={{ width: 350, borderRadius: "10px" }}
        cover={
          <img
            style={{ width: "100%", padding: "10px", height: "230px" }}
            alt={item.name}
            src={item.image}
          />
        }
      >
        <Meta title={item.name} />
      </Card>
    </Pressable>
  );
};

export default AntdCard;
