import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
// import Table from "@views/components/ui/table";
import { Row, Col, Table,Card, CardBody } from "reactstrap";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";

const ScreenReader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box flex="1">
        <Card>
          <CardBody>
            <Row>
              <Col lg="12">
                <h2>Screen Reader Access</h2>
                <p>
                  Following table lists the information about different screen
                  readers:
                </p>
                <Table responsive striped bordered>
                  <thead>
                    <tr>
                      <th>Screen Reader</th>
                      <th>Website</th>
                      <th>Free/Commercial</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Screen Access For All (SAFA)</td>
                      <td className="screenReader">
                        <a
                          className="LinkStyle"
                          href="http://www.nabdelhi.in/it-services/technology-training-center/downloads/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.nabdelhi.in
                        </a>
                      </td>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <td>Non Visual Desktop Access (NVDA)</td>
                      <td className="screenReader">
                        <a
                          className="LinkStyle"
                          href="http://www.nvda-project.org/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window "
                        >
                          http://www.nvda-project.org
                        </a>
                      </td>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <td>System Access To Go</td>
                      <td className="screenReader">
                        <a
                          className="LinkStyle"
                          href="http://www.satogo.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window "
                        >
                          http://www.satogo.com/
                        </a>
                      </td>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <td>Thunder</td>
                      <td className="screenReader">
                        <a
                          className="LinkStyle"
                          href="http://www.screenreader.net/index.php?pageid=2"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.screenreader.net/index.php?pageid=2
                        </a>
                      </td>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <td>WebAnywhere</td>
                      <td className="screenReader">
                        <a
                          className="LinkStyle"
                          href="http://webanywhere.cs.washington.edu/wa.php"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://webanywhere.cs.washington.edu/wa.php
                        </a>
                      </td>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <td>Hal</td>
                      <td className="screenReader">
                        <a
                          className="LinkStyle"
                          href="http://www.yourdolphin.co.uk/productdetail.asp?id=5"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.yourdolphin.co.uk/productdetail.asp?id=5
                        </a>
                      </td>
                      <td>Commercial</td>
                    </tr>
                    <tr>
                      <td>JAWS</td>
                      <td className="screenReader">
                        <a
                          className="LinkStyle"
                          href="http://www.freedomscientific.com/jaws-hq.asp"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.freedomscientific.com/jaws-hq.asp
                        </a>
                      </td>
                      <td>Commercial</td>
                    </tr>
                    <tr>
                      <td>Supernova</td>
                      <td className="screenReader">
                        <a
                          className="LinkStyle"
                          href="http://www.yourdolphin.co.uk/productdetail.asp?id=1"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.yourdolphin.co.uk/productdetail.asp?id=1
                        </a>
                      </td>
                      <td>Commercial</td>
                    </tr>
                    <tr>
                      <td>Window-Eyes</td>
                      <td className="screenReader">
                        <a
                          className="LinkStyle"
                          href="http://www.gwmicro.com/Window-Eyes/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.gwmicro.com/Window-Eyes/
                        </a>
                      </td>
                      <td>Commercial</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Box>{/* <Box mt="30px" width="200px" zIndex={1}></Box> */}</Box>
      </Box>
    </>
  );
};
export default ScreenReader;
