import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import TenderCardList from "./components/tender_card_list";
import ADSCardList from "./components/ads_card_list";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import GoGreenImage from "@assets/images/organization/go-green.jpg";
import { Collapse } from "antd";
const { Panel } = Collapse;
const TendersAdvertisements = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection
            imageUrl={ForestImageCover}
            title={t("tender")}
          />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center"></Box>
                <Box alignItems="center" w="100%">
                  <VStack space={4}>
                    <Box>
                      <Text fontWeight={"bolder"}>{"Important Link"}</Text>
                    </Box>
                    <Box
                      style={{
                        border: "1px solid #c7dd80",
                        borderRadius: "15px",
                      }}
                      padding="5"
                    >
                      {" "}
                      <a href="https://egreenwatch.nic.in/" target={"blank"}>
                        <img src={GoGreenImage} height="64px" />
                      </a>
                    </Box>
                  </VStack>
                </Box>
              </VStack>
            </Box>
            <Box w={["100%", "100%", "65%"]} h="100%" mt={["10px", "10px"]}>
              {/* <Collapse
                defaultActiveKey={[""]}
                expandIconPosition={"end"}
                ghost
              > */}
                {/* <Box>
                  <Text fontSize={"20px"} fontWeight={"bold"}>
                    Tenders
                  </Text>
                </Box>
                <br />
                <Box>
                  <TenderCardList />
                </Box>
                <br />
                <Box>
                  <Text fontSize={"20px"} fontWeight={"bold"}>
                    Advertisement
                  </Text>
                </Box>
                <br />
                <Box>
                  <ADSCardList />
                </Box> */}
                {/* <Panel header={t("tender")} key="1"> */}
                  <div>{<TenderCardList />}</div>
                {/* </Panel> */}
              {/* </Collapse> */}
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default TendersAdvertisements;
