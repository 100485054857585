import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";
import FlowerImage from "@assets/images/menu/plant.png";
import { FaBullseye } from "react-icons/fa";

const WildlifeThirdCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [scheduleI, setSheduleI] = useState(false);
  const [scheduleII, setSheduleII] = useState(false);
  const [scheduleIII, setSheduleIII] = useState(false);
  const [scheduleIV, setSheduleIV] = useState(false);
  const [scheduleV, setSheduleV] = useState(false);

  const showSheduleI = () => {
    setSheduleI(!scheduleI);
  };
  const showSheduleII = () => {
    setSheduleII(!scheduleII);
  };
  const showSheduleIII = () => {
    setSheduleIII(!scheduleIII);
  };
  const showSheduleIV = () => {
    setSheduleIV(!scheduleIV);
  };
  const showSheduleV = () => {
    setSheduleV(!scheduleV);
  };

  return (
    <>
      <Box>
        <Box
          style={{
            marginTop: "20px",
            borderRadius: "20px",
            background: "#F9FAF2",
            height: "auto",
            justifyContent: "center",
          }}
        >
          <VStack>
            <Box marginBottom={"5"} alignSelf="center" padding={5}>
              <Text fontSize="15px">
                Many faunal species have been included in the various schedules
                of the
                <b>
                  Wild Life
                  <br /> Protection Act 1972
                </b>
                , considering their endangered status. (Click on the tiles to
                view more)
              </Text>
            </Box>
            <Center>
              {" "}
              <HStack
                space="5"
                justifyContent={"center"}
                flexDirection={{
                  base: "column",
                  md: "row",
                }}
                padding={5}
              >
                <Pressable onPress={showSheduleI} marginBottom={5}>
                  <Box width={["300px","300px","126px"]}
                    style={{
                      borderRadius: "5px",
                      background: "green",
                      height: "120px",
                      // width: "120px",
                      justifyContent: "center",
                    }}
                  >
                    {!scheduleI && (
                      <Text
                        textAlign="center"
                        color="white"
                        fontSize={"16px"}
                        fontWight="bold"
                        alignSelf="center"
                      >
                        Schedule I
                      </Text>
                    )}

                    {scheduleI && (
                      <Text
                        textAlign="center"
                        color="white"
                        fontSize={"12px"}
                        alignSelf="center"
                      >
                        22 species of
                        <br /> mammals, 42 <br />
                        species of birds
                        <br /> and 9 species of
                        <br />
                        reptiles.
                      </Text>
                    )}
                  </Box>
                </Pressable>
                <Pressable onPress={showSheduleII} marginBottom={5}>
                  <Box width={["300px","300px","126px"]}
                    style={{
                      borderRadius: "5px",
                      background: "green",
                      height: "120px",
                      // width: "120px",
                      justifyContent: "center",
                    }}
                  >
                    {!scheduleII && (
                      <Text
                        textAlign="center"
                        color="white"
                        fontSize={"16px"}
                        fontWight="bold"
                        alignSelf="center"
                      >
                        Schedule II
                      </Text>
                    )}
                    {scheduleII && (
                      <Text
                        textAlign="center"
                        color="white"
                        fontSize={"12px"}
                        alignSelf="center"
                      >
                        13 species of <br />
                        mammals
                      </Text>
                    )}
                  </Box>
                </Pressable>
                <Pressable onPress={showSheduleIII} marginBottom={5}>
                  <Box width={["300px","300px","126px"]}
                    style={{
                      borderRadius: "5px",
                      background: "green",
                      height: "120px",
                      // width: "120px",
                      justifyContent: "center",
                    }}
                  >
                    {!scheduleIII && (
                      <Text
                        textAlign="center"
                        color="white"
                        fontSize={"16px"}
                        fontWight="bold"
                        alignSelf="center"
                      >
                        Schedule III
                      </Text>
                    )}
                    {scheduleIII && (
                      <Text
                        textAlign="center"
                        color="white"
                        fontSize={"12px"}
                        alignSelf="center"
                      >
                        5 species of <br />
                        mammals
                      </Text>
                    )}
                  </Box>
                </Pressable>
                <Pressable onPress={showSheduleIV} marginBottom={5}>
                  <Box width={["300px","300px","126px"]}
                    style={{
                      borderRadius: "5px",
                      background: "green",
                      height: "120px",
                      // width: "120px",
                      justifyContent: "center",
                    }}
                  >
                    {!scheduleIV && (
                      <Text
                        textAlign="center"
                        color="white"
                        fontSize={"16px"}
                        fontWight="bold"
                        alignSelf="center"
                      >
                        Schedule IV
                      </Text>
                    )}

                    {scheduleIV && (
                      <Text
                        textAlign="center"
                        color="white"
                        fontSize={"12px"}
                        alignSelf="center"
                      >
                        5 species of
                        <br /> mammals, 367 <br />
                        species of birds
                        <br /> and 109 species of
                        <br />
                        reptiles,and 23 species
                        <br /> of amphibians
                      </Text>
                    )}
                  </Box>
                </Pressable>
                <Pressable onPress={showSheduleV} marginBottom={5}>
                  <Box width={["300px","300px","126px"]}
                    style={{
                      borderRadius: "5px",
                      background: "green",
                      height: "120px",
                      // width: "120px",
                      justifyContent: "center",
                    }}
                  >
                    {!scheduleV && (
                      <Text
                        textAlign="center"
                        color="white"
                        fontSize={"16px"}
                        fontWight="bold"
                        alignSelf="center"
                      >
                        Schedule V
                      </Text>
                    )}
                    {scheduleV && (
                      <Text
                        textAlign="center"
                        color="white"
                        fontSize={"12px"}
                        alignSelf="center"
                      >
                        13 species of
                        <br /> mammals and 1 <br />
                        species of birds
                      </Text>
                    )}
                  </Box>
                </Pressable>
              </HStack>
            </Center>
          </VStack>
        </Box>
        <br />
        <Box padding={5}>
          <Text>More details</Text>
          <a
            href="https://tnenvis.nic.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            tnenvis.nic.in
          </a>
        </Box>
      </Box>
    </>
  );
};
export default WildlifeThirdCard;
