import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";

const PioneersInSearchOfThePresidencyFloraHTML = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <HTMLRenderer
          cmsKey={
            "Biodiversity _THE_PIONEERS_IN_SEARCH_OF_THE_PRESIDENCY'S_FLORA"
          }
        />
      </Box>
    </>
  );
};
export default PioneersInSearchOfThePresidencyFloraHTML;
