import {
  getSystemCMSFieldUserListSelector,
  get_system_cms_user_field_request,
  get_one_cms,
  getcmsSelector,
} from "@services/redux";
import { Pressable, Box } from "native-base";
import { ROUTES } from "@views/routes/my_routes";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useStorageItem } from "@helpers/storage";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";

const HTMLRenderer = (props) => {
  const { Photo, roles, name } = useStorageItem("user");
  const dispatch = useDispatch();
  const { id } = useParams();
  let current_language = JSON.parse(
    localStorage.getItem("current_language")
  ) || {
    id: "e0d9e2a1-4d8a-4e1c-a623-5a558954893d",
    name: "தமிழ் ",
    key: "en",
  };
  const { items, system_cms_id } = useSelector(
    getSystemCMSFieldUserListSelector
  );
  const { item } = useSelector(getcmsSelector);
  const history = useHistory();
  const [code, setCode] = useState("");
  const moveToPage = (routes) => {
    history.push({
      pathname: `${routes}`,
    });
  };

  useEffect(() => {
    if (props.cmsKey) {
      dispatch(
        get_system_cms_user_field_request({
          json: {
            system_cms_key: props.cmsKey,
          },
        })
      );
    }
  }, [props.cmsKey]);

  useEffect(() => {
    if (items?.length) {
      let cms = items.find((e) => e.key === props.cmsKey);
      if (cms?.field_items?.length) {
        let item = cms.field_items.find((x) => x.key === "code");
        let val = item?.system_cms_values?.find(
          (x) => x.system_cms_language_id === current_language?.id
        );
        if (val?.value) {
          setCode(val.value);
        } else {
          // If val.value is null, take the value from another language
          let otherLanguageVal = item?.system_cms_values?.find(
            (x) => x.system_cms_language_id !== current_language?.id
          );
          setCode(otherLanguageVal?.value);
        }
      }
    }
  }, [items, current_language]);

  return (
    <Box>
      {name ? (
        <Pressable
          _hover={{
            borderColor: "red.500",
            borderWidth: "6px",
          }}
        >
          <Box
            alignItems={"flex-end"}
            onClick={(e) => {
              history.push({
                pathname: `${ROUTES.SYSTEM_CMS_VALUE}/${system_cms_id}`,
                state: { cms: props.cmsKey },
              });
            }}
          >
            <AiFillEdit size={30} color="#409d06" />
          </Box>
          <Box style={{ maxWidth: "100vw" }}>
            {ReactHtmlParser(code ? code : "")}
          </Box>
        </Pressable>
      ) : (
        <Box style={{ maxWidth: "100vw" }}>
          {ReactHtmlParser(code ? code : "")}
        </Box>
      )}
    </Box>
  );
};

export default HTMLRenderer;
