import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text, Card, Button, Hidden } from "native-base";
import { useTranslation } from "react-i18next";

const TenderCardItem = (props) => {
  const dispatch = useDispatch();
  const { item } = props;
  const { t } = useTranslation();
  const handlePdf = (item) => {
    var a = document.createElement("a");
    var filePath = item;
    a.href = filePath;
    a.target = "_blank";
    a.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Box>
      <Card variant="tender" height={["auto", "auto", "auto"]}>
        <HStack>
          <Box w="50%">
            <VStack p="5" space={2}>
              <Text fontWeight="bolder">{item.Titlee?.value}</Text>
              <Text color="#969e89">{"Remarks"}</Text>
              <Text fontSize={12}>{item.Description?.value}</Text>
            </VStack>
          </Box>
          <Box w="40%">
            <VStack p="5" space={2}>
              <Text color="#969e89">{"Attachments"}</Text>
              <HStack
                space={5}
                w="50%"
                flexDirection={{
                  base: "column",
                  md: "row",
                }}
              >
                {item.Forms?.value && (
                  <Button
                    marginBottom={["10px", "10px", "0px"]}
                    variant="outline"
                    width="130px"
                    borderColor="#6bb384"
                    _hover={{
                      borderColor: "#6bb384",
                      bg: "#026608",
                      _text: {
                        color: "#ffffff",
                      },
                    }}
                    _light={{
                      _text: {
                        color: "#026608",
                      },
                    }}
                    onPress={() => {
                      handlePdf(item.Forms.value);
                    }}
                  >
                    {"Application Form"}
                  </Button>
                )}
                {item.Annexure?.value && (
                  <Button
                    marginBottom={["10px", "10px", "0px"]}
                    variant="outline"
                    width="120px"
                    borderColor="#6bb384"
                    _hover={{
                      borderColor: "#6bb384",
                      bg: "#026608",
                      _text: {
                        color: "#ffffff",
                      },
                    }}
                    _light={{
                      _text: {
                        color: "#026608",
                      },
                    }}
                    onPress={() => {
                      handlePdf(item.Annexure.value);
                    }}
                  >
                    {"Annexure"}
                  </Button>
                )}
                {item.EOI?.value && (
                  <Button
                    marginBottom={["10px", "10px", "0px"]}
                    variant="outline"
                    width="120px"
                    borderColor="#6bb384"
                    _hover={{
                      borderColor: "#6bb384",
                      bg: "#026608",
                      _text: {
                        color: "#ffffff",
                      },
                    }}
                    _light={{
                      _text: {
                        color: "#026608",
                      },
                    }}
                    onPress={() => {
                      handlePdf(item.EOI.value);
                    }}
                  >
                    {"EOI"}
                  </Button>
                )}
              </HStack>

              {/* <Text color="#969e89">{"Deadline"}</Text> */}
            </VStack>
          </Box>
        </HStack>
      </Card>
    </Box>
  );
};
export default TenderCardItem;
