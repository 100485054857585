import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text, Hidden } from "native-base";
import { useTranslation } from "react-i18next";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import MoreMenu from "@views/components/modules/secondary_sider_menu/more_menu";
import RecruitmentHTML from "./components/recruitment";
import RecruitmentFirstPart from "./components/recruitment_first_part";
import DirectRecruitment from "./components/direct_recruitment";
import RecruitmentSecondCard from "./components/recruitment_second_card";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";

const Recruitment = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack overflowX={"hidden"}>
        <Box>
          <HeaderSection imageUrl={ForestImageCover} title={"Recruitment"} />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} >
                <Box  alignItems="center">
                  <SecondarySiderMenu type="more_menu" />
                </Box>
              </VStack>
            </Box>
            <Box w={["100%", "100%", "65%"]} h="100%">
              <Box >
                <RecruitmentFirstPart />
              </Box>
              <Box w={["100%","100%","65%"]} mt="30px">
                <DirectRecruitment />
              </Box>
              <Box w={["100%","100%","65%"]} mt="30px">
                <RecruitmentSecondCard />
              </Box>
              <Box w={["100%","100%","65%"]}>
                <Box
                  bgImage="url(https://eazyrp-projects-files.s3.ap-south-1.amazonaws.com/TNFD/wreath-1x.png)"
                  backgroundSize="100vw 400px"
                  backgroundRepeat="no-repeat"
                  h="250px"
                  w="100vw"
                ></Box>
              </Box>
              <Box  w={["100%","100%","65%"]} mt="30px">
                <RecruitmentHTML />
              </Box>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default Recruitment;
