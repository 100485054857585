import { Tabs } from "antd";
import { Button } from "native-base";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import GalleryContent from "./gallery_content";
import GalleryContentForestTypes from "./gallery_content_forest_types";
import GallerySocialMediaContent from "./gallery_social_media_content";
import GalleryAlbumSchemes from "./gallery_album_schemes";
import GalleryContentTypes from "./gallery_content_types";
import {
  getSystemCMSFieldUserListSelector,
  get_system_cms_user_field_request,
} from "@services/redux";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

const GalleryTab = () => {
  const dispatch = useDispatch();
  const { items } = useSelector(getSystemCMSFieldUserListSelector);
  const [groupValues, setGroupValues] = useState("");
  const [tabTriggered, setTabTriggered] = useState(false);
  const [tabItems, setTabItems] = useState([]);
  useEffect(() => {
    dispatch(
      get_system_cms_user_field_request({
        json: {
          system_cms_key: "gallery",
        },
      })
    );
  }, []);
  const onTabClick = () => {
    setTabTriggered(!tabTriggered);
  };
  useEffect(() => {
    if (items?.length) {
      let cms = items.find((e) => e.key === "gallery");
      if (cms?.field_items?.length) {
        let group_key = {
          schemes: { row_number: [], items: [] },
          wildlife: { row_number: [], items: [] },
          "forest-types": { row_number: [], items: [] },
          "forest-protection": { row_number: [], items: [] },
          "green-tn-mission": { row_number: [], items: [] },
          reports: { row_number: [], items: [] },
          "social-media": { row_number: [], items: [] },
        };
        let group_item = cms.field_items.find((x) => x.key === "type");
        group_item.system_cms_values.map((item) => {
          group_key?.[item.value]?.["row_number"].push(item.row_number);
        });
        cms?.field_items.map((cms_field_item) => {
          if (cms_field_item.key !== "type")
            cms_field_item.system_cms_values.map((cms_value_item) => {
              Object.keys(group_key).map((key_group) => {
                if (
                  group_key?.[key_group]?.["row_number"].includes(
                    cms_value_item.row_number
                  )
                ) {
                  let object_index = group_key?.[key_group]?.[
                    "items"
                  ].findIndex(
                    (item) => item.row_number === cms_value_item.row_number
                  );
                  let assign_object = {};
                  assign_object[cms_field_item.key] = cms_value_item.value;
                  if (object_index !== -1)
                    group_key[key_group]["items"][object_index] = {
                      ...group_key[key_group]["items"][object_index],
                      ...assign_object,
                    };
                  else
                    group_key?.[key_group]?.["items"].push({
                      ...assign_object,
                      row_number: cms_value_item.row_number,
                    });
                }
              });
            });
        });
        setGroupValues(group_key);
        let tab_items = [
          {
            id: 1,
            label: t("Schemes"),
            key: 1,
            children: (
              <GalleryContentTypes
                groupValues={group_key}
                cms_key={"schemes"}
                // groupValues={groupValues["schemes"]?.["items"]}
              />
            ),
          },
          {
            id: 2,
            label: t(`Wildlife`),
            key: 2,
            children: (
              <GalleryContent
                groupValues={group_key}
                cms_key={"wildlife"}
                // groupValues={groupValues["wildlife"]?.["items"]}
              />
            ),
          },
          {
            id: 3,
            label: t(`Forest_Types`),
            key: 3,
            children: (
              <GalleryContentForestTypes
                groupValues={group_key}
                cms_key={"forest-types"}
                // groupValues={groupValues["forest-types"]?.["items"]}
              />
            ),
          },
          {
            id: 4,
            label: t(`Forest_Protection`),
            key: 4,
            children: (
              <GalleryContent
                groupValues={group_key}
                cms_key={"forest-protection"}
                // groupValues={groupValues["forest-protection"]?.["items"]}
              />
            ),
          },
          {
            id: 5,
            label: t(`Green_TN_Mission`),
            key: 5,
            children: (
              <GalleryContent
                groupValues={group_key}
                cms_key={"green-tn-mission"}
                // groupValues={groupValues["green-tn-mission"]?.["items"]}
              />
            ),
          },
          {
            id: 6,
            label: t(`soil_and_moisture_conservation`),
            key: 6,
            children: (
              <GalleryContentTypes
                groupValues={group_key}
                cms_key={"reports"}
                // groupValues={groupValues["reports"]?.["items"]}
              />
            ),
          },
          {
            id: 7,
            label: t(`Social Media`),
            key: 7,
            children: (
              <GallerySocialMediaContent
                groupValues={group_key}
                cms_key={"social-media"}
                // groupValues={groupValues["social-media"]?.["items"]}
              />
            ),
          },
        ];
        setTabItems(tab_items);
      }
    }
  }, [items, tabTriggered]);

  const operations = (
    <Button
      variant="outline"
      width="150px"
      borderColor="#6bb384"
      endIcon={<MdOutlineKeyboardArrowRight />}
      _hover={{
        borderColor: "#6bb384",
      }}
      _text={{
        color: "#6bb384",
      }}
    >
      All Albums
    </Button>
  );
  return (
    <Tabs
      // tabBarExtraContent={operations}
      onTabClick={onTabClick}
      animated={true}
      items={tabItems}
      destroyInactiveTabPane={true}
    />
  );
};
export default GalleryTab;
