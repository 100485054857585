import { getcmsListReducer } from "./cms_list";
import { createcmsReducer } from "./cms_create";
import { deletecmsReducer } from "./cms_delete";
import { updatecmsReducer } from "./cms_update";
import { getcmsReducer } from "./cms_getOne";
export const cmsReducers = {
    cmsList: getcmsListReducer,
    cmsCreate: createcmsReducer,
    cmsDelete: deletecmsReducer,
    cmsUpdate: updatecmsReducer,
    cmsGet: getcmsReducer,
};

export * from "./cms_list";
export * from "./cms_create";
export * from "./cms_delete";
export * from "./cms_update";
export * from "./cms_getOne";
