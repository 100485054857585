import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import ProtectionVigilanceHTML from "./components/protection_vigilance";
import ForestProtectionSquadsTable from "./components/forest_protection_squads";
import ForestCheckPostsTable from "./components/forest_check_posts";
import RovingCheckPostsTable from "./components/roving_check_posts";
import DetailsOfArmsAndAmmunitionsTable from "./components/details_of_arms_and_ammunitions";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";

const ProtectionVigilancePV = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection
            imageUrl={ForestImageCover}
            title={t("protection_vigilance")}
          />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="organization" />
                </Box>
              </VStack>
            </Box>
            <Box
              w={["100%", "100%", "65%"]}
              h="100%"
              mt={["20px", "20px", "0px"]}
            >
              <Box>
                <ProtectionVigilanceHTML />
              </Box>
              <Box h="100%">
                <DetailsOfArmsAndAmmunitionsTable />
              </Box>
              <br />
              {/* <Box w="65%" h="100%">
                <ForestProtectionSquadsTable />
              </Box> */}
              <Box>
                <Text bold fontSize="20px">
                  Forest Check Post
                </Text>
              </Box>
              <Box h="100%">
                <ForestCheckPostsTable />
              </Box>
              <br />
              <Box>
                <Text bold fontSize="20px">
                  Roving Check Post
                </Text>
              </Box>
              <Box h="100%">
                <RovingCheckPostsTable />
              </Box>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default ProtectionVigilancePV;
