import React, { useState } from "react";
import { Button, Select } from "antd";
import {
  Box,
  Center,
  Divider,
  Hidden,
  HStack,
  Menu,
  Image,
  Pressable,
  Stagger,
  Text,
  VStack,
} from "native-base";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from "@ant-design/icons";

import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useStorageItem } from "@helpers/storage";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "@i18n";
import { setAuthorize } from "@services/redux";
import Avatar from "react-avatar";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import Dialog from "../dialog";
import { BASE_URL_ASSETS } from "@helpers/constants";
const HeaderTNFD = (props) => {
  const { toggleCollapsed, collapsed } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Photo, roles, name } = useStorageItem("user");
  const [dialogVisible, setDialogVisible] = useState(false);
  const languages = [
    { id: "e0d9e2a1-4d8a-4e1c-a623-5a558954893d", name: "தமிழ் ", key: "en" },
    { id: "ab5a2300-a29c-442f-84d0-8b1bd4174500", name: "English", key: "tn" },
  ];
  let find_index = languages.findIndex(
    (item) =>
      item.id === JSON.parse(localStorage?.getItem("current_language"))?.id
  );

  const language_name = JSON.parse(
    localStorage.getItem("current_language")
  )?.key;

  const [language, setLanguage] = useState(
    languages[find_index > -1 ? find_index : 0]
  );
  const [index, setIndex] = useState(find_index > -1 ? find_index : 0);

  const history = useHistory();
  const moveToPage = (routes) => {
    history.push({
      pathname: `${routes}`,
    });
  };
  const handleChangeLanguage = () => {
    setLanguage(languages[(index + 1) % 2]);
    setIndex((index + 1) % 2);
    localStorage.setItem(
      "current_language",
      JSON.stringify(languages[(index + 1) % 2])
    );
    changeLanguage(languages[(index + 1) % 2].key);
    window.location.reload();
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(setAuthorize(false));
    history.push({
      pathname: `${ROUTES.HOME}`,
    });
  };
  const handleLogin = () => {
    history.push({
      pathname: `${ROUTES.LOGIN}`,
    });
  };
  const redirectToEDAF = () => {
    window.open("https://edaf.forests.tn.gov.in/users/login", "_blank");
  };
  return (
    <Box w="100%">
      <VStack w="100%">
        <Box w="100%" h="35px" style={{ background: "#2f5700" }}>
          <HStack h="100%" alignItems={"center"}>
            <Box w="40%" h="100%" justifyContent={"center"} alignItems="center">
              <Hidden only={["lg", "md", "xl"]}>
                <Button
                  type="primary"
                  onClick={toggleCollapsed}
                  style={{ marginBottom: 0, marginRight: "60px" }}
                >
                  {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
                {/* <Box pl="10">
                  <Text
                    variant="header"
                    onClick={() => {
                      moveToPage(ROUTES.ABOUTPRESENTCONTENT);
                    }}
                  >
                    {t("about_us")}
                  </Text>
                </Box> */}
              </Hidden>
              <Hidden only={["sm", "xs"]}>
                <HStack space={7}>
                  <Box pl="10">
                    <Text
                      variant="header"
                      onClick={() => {
                        moveToPage(ROUTES.ABOUTPRESENTCONTENT);
                      }}
                    >
                      {t("about_us")}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      variant="header"
                      onClick={() => {
                        moveToPage(ROUTES.CITIZEN_CHARTER);
                      }}
                    >
                      {t("citizen_charter")}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      variant="header"
                      onClick={() => {
                        moveToPage(ROUTES.LISTOFIFSOFFICERS);
                      }}
                    >
                      {t("list-of_IFS_Officers")}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      variant="header"
                      onClick={() => {
                        moveToPage(ROUTES.RTI);
                      }}
                    >
                      {"RTI"}
                    </Text>
                  </Box>
                  {/* <Box>
                  <Text
                    variant="header"
                    onClick={() => {
                      moveToPage(ROUTES.HASH);
                    }}
                  >
                    {"Archives"}
                  </Text>
                </Box> */}
                  <Box>
                    <Text
                      variant="header"
                      onClick={() => {
                        moveToPage(ROUTES.FAQ);
                      }}
                    >
                      {"FAQs"}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      variant="header"
                      onClick={() => {
                        moveToPage(ROUTES.CONTACTUS);
                      }}
                    >
                      {t("contact_us")}
                    </Text>
                  </Box>
                  <Box>
                    <Text variant="header"></Text>
                  </Box>
                </HStack>
              </Hidden>
            </Box>

            <Box w={["5%", "5%", "20%"]}></Box>
            <Box w="30%" alignItems={"end"} h="100%" justifyContent={"center"}>
              <HStack space={10}>
                <Hidden only={["sm", "xs"]}>
                  <Box justifyContent="center">
                    <Text variant="header" onClick={redirectToEDAF}>
                      {t("EDAF Login")}
                    </Text>
                  </Box>
                </Hidden>
                <Box justifyContent="center">
                  <Text
                    variant="header"
                    onClick={() => {
                      moveToPage(ROUTES.SCREEN_READER);
                    }}
                  >
                    {t("screen_reader_access")}
                  </Text>
                </Box>
                <Box justifyContent="center">
                  <Text variant="header" onPress={handleChangeLanguage}>
                    {language.name}
                  </Text>
                </Box>
                <Hidden till="md">
                  {" "}
                  <Box my={"auto"}>
                    <HStack>
                      <Box right="10px" ml="6">
                        <Menu
                          w="160"
                          position="absolute"
                          right="0px"
                          top="20px"
                          style={{
                            border: "1px solid #d7d7d7",
                            boxShadow: "-2px 2px #7a7a7a42",
                          }}
                          shouldOverlapWithTrigger={true}
                          placement={"left bottom"}
                          trigger={(triggerProps) => {
                            return (
                              <Pressable
                                alignSelf="center"
                                alignItems="center"
                                variant="solid"
                                {...triggerProps}
                              >
                                <HStack>
                                  {name ? (
                                    <Text bold fontSize="md" variant="header">
                                      {`Welcome, ${name}!`}
                                    </Text>
                                  ) : (
                                    ""
                                  )}
                                  <Box right="10px" ml="6">
                                    <Avatar
                                      round
                                      size="25"
                                      src={
                                        Photo ||
                                        "https://nt-aim-assets.s3.ap-south-1.amazonaws.com/default-avatar.png"
                                      }
                                    />
                                  </Box>
                                </HStack>
                              </Pressable>
                            );
                          }}
                        >
                          {name ? (
                            <Menu.Item onPress={handleLogout}>Logout</Menu.Item>
                          ) : (
                            <Menu.Item onPress={handleLogin}>Login</Menu.Item>
                          )}
                        </Menu>
                      </Box>
                    </HStack>
                  </Box>
                </Hidden>
              </HStack>
            </Box>
          </HStack>
        </Box>
        <Box w="100%" h="10%">
          <HStack w="100%">
            <Hidden from="md">
              <Box w="10%"></Box>
              <Box w={"27%"} justifyContent={"center"}>
                <VStack>
                  <Box alignItems={"center"}>
                    <Text
                      w={["0px", "165px", "100%"]}
                      marginLeft={["270px", "270px", "60px"]}
                      marginTop={["0px", "20px", "0px"]}
                      variant="govt_of_tn_eng"
                      onClick={() => {
                        moveToPage(ROUTES.HASH);
                      }}
                      cursor="pointer"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      {t("gotn")}
                    </Text>
                  </Box>
                  <Box alignItems={"center"}>
                    <Text
                      w={["0px", "224px", "100%"]}
                      marginLeft={["195px", "195px", "158px"]}
                      marginTop={["0px", "10px", "0px"]}
                      variant="forest_department_eng"
                      onClick={() => {
                        moveToPage(ROUTES.HASH);
                      }}
                      cursor="pointer"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      {t("forest_dept")}
                    </Text>
                  </Box>
                </VStack>
              </Box>
              <Box
                mt="-3"
                w="6%"
                marginLeft={["0px", "90px", "0px"]}
                marginTop={["0px", "10px", "-15px"]}
                cursor="pointer"
                onClick={() => {
                  moveToPage(ROUTES.HASH);
                }}
              >
                <img
                  className="logowidth"
                  src={
                    language_name === "en"
                      ? BASE_URL_ASSETS + "TamilNadu_Logo_Eng.png"
                      : BASE_URL_ASSETS + "TamilNadu_Logo_Tamil.png"
                  }
                  alt="Menu Image"
                  width={94}
                />
              </Box>
            </Hidden>
            <Hidden only={["sm", "xs"]}>
              <Box w="20%"></Box>
              <Box w={"27%"} justifyContent={"center"}>
                <VStack>
                  <Box alignItems={"center"}>
                    <Text
                      w={["0px", "165px", "100%"]}
                      marginLeft={["0px", "200px", "60px"]}
                      marginTop={["0px", "20px", "0px"]}
                      variant="govt_of_tn_eng"
                      onClick={() => {
                        moveToPage(ROUTES.HASH);
                      }}
                      cursor="pointer"
                    >
                      {"GOVERNMENT OF TAMIL NADU"}
                    </Text>
                  </Box>
                  <Box alignItems={"center"}>
                    <Text
                      w={["0px", "224px", "100%"]}
                      marginLeft={["0px", "125px", "158px"]}
                      marginTop={["0px", "10px", "0px"]}
                      variant="forest_department_eng"
                      onClick={() => {
                        moveToPage(ROUTES.HASH);
                      }}
                      cursor="pointer"
                    >
                      {"FOREST DEPARTMENT"}
                    </Text>
                  </Box>
                </VStack>
              </Box>
              <Box
                mt="-3"
                w="6%"
                marginLeft={["0px", "60px", "0px"]}
                marginTop={["0px", "10px", "-15px"]}
                cursor="pointer"
                onClick={() => {
                  moveToPage(ROUTES.HASH);
                }}
              >
                <img
                  className="logowidth"
                  src={
                    language_name === "en"
                      ? BASE_URL_ASSETS + "TamilNadu_Logo_Eng.png"
                      : BASE_URL_ASSETS + "TamilNadu_Logo_Tamil.png"
                  }
                  alt="Menu Image"
                  width={94}
                />
              </Box>
              <Box w={"27%"} justifyContent={"center"}>
                <VStack>
                  <Box alignItems={"center"}>
                    <Text
                      cursor="pointer"
                      variant="govt_of_tn_tam"
                      onClick={() => {
                        moveToPage(ROUTES.HASH);
                      }}
                    >
                      {"தமிழ்நாடு அரசு"}
                    </Text>
                  </Box>
                  <Box alignItems={"center"}>
                    <Text
                      variant="forest_department_tam"
                      onClick={() => {
                        moveToPage(ROUTES.HASH);
                      }}
                      cursor="pointer"
                    >
                      {"வனத்துறை"}
                    </Text>
                  </Box>
                </VStack>
              </Box>
            </Hidden>
            <Box w="20%"></Box>
          </HStack>
        </Box>
        <Box w="100%" h="10%">
          <HStack w="100%">
            <Box w="20%">
              <Divider thickness={2} bg="#white" />
            </Box>
            <Hidden only={["xs", "sm"]}>
              <Box w="27%">
                <div
                  className={
                    // is_hovered
                    //   ? "main-divider-transition-header-left"
                    //   :
                    "divider-transition-header-left"
                  }
                />
              </Box>

              <Box w="6%">
                <Divider thickness={2} bg="#white" />
              </Box>
              <Box w="27%">
                {/* <Divider thickness={2} bg="#519007e0" />
                 */}
                <div
                  className={
                    // is_hovered
                    //   ? "main-divider-transition-header-right"
                    //   :
                    "divider-transition-header-right"
                  }
                />
              </Box>
            </Hidden>
            <Box w="20%">
              <Divider thickness={2} bg="#white" />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default HeaderTNFD;
