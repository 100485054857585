import React, { useEffect, useState } from "react";
import { ROUTES } from "@views/routes/my_routes";
import { Box, Center, Divider, Image, Text, VStack } from "native-base";
import MenuSiderImage from "@assets/images/menu/secondary-menu.png";
import SecondarySiderMenuItem from "./secondary_sider_menu_item";
import { useTranslation } from "react-i18next";
import { startCase } from "lodash";
import { useLocation } from "react-router-dom";
const SecondarySiderMenu = (props) => {
  const location = useLocation();
  const url = location.pathname.replace("/", "");
  const { t } = useTranslation();
  const { type } = props;
  const selected_index_previous = Number(
    localStorage.getItem("selected_index")
  );
  const [selected_index, setSelectedIndex] = useState(
    selected_index_previous || 0
  );

  const handleSelectedIndex = (index) => {
    console.log("handleSelectedIndex", index);
    setSelectedIndex(index);
    localStorage.setItem("selected_index", String(index));
  };
  useEffect(() => {
    let selected_ind = menu_header_switch_object[type].findIndex(
      (item) => item.key === url
    );
    setSelectedIndex(selected_ind);
  }, [type]);
  
  let menu_header_switch_object = {
    organization: [
      {
        name: t("administration"),
        key: "administration",
        route: ROUTES.ADMINISTRATION,
      },
      { name: t("circles"), key: "circles", route: ROUTES.CIRCLES },
      { name: "CAMPA", key: "campa", route: ROUTES.CAMPA },
      {
        name: t("forest_conservation_act"),
        key: "forest-conservation-act",
        route: ROUTES.FOREST_CONSERVATION_ACT,
      },
      {
        name: t("information_technology"),
        key: "information_technology_it",
        route: ROUTES.INFORMATION_TECHNOLOGY_IT,
      },
      {
        name: t("Planning_budgetting"),
        key: "shared-schemes",
        route: ROUTES.SHARED_SCHEMES,
      },
      {
        name: t("protection_vigilance"),
        key: "protection-vigilance-p-v",
        route: ROUTES.PROTECTION_VIGILANCE_P_V,
      },
      {
        name: t("research_education"),
        key: "research",
        route: ROUTES.RESEARCH,
      },
      {
        name: t("Social_forestry_extension"),
        key: "social-forestry",
        route: ROUTES.SOCIAL_FORESTRY,
      },
      {
        name: t("biodiversity_wildlife"),
        key: "biodiversity-tab",
        route: ROUTES.BIODIVERSITY_TAB,
      },
      {
        name: t("working_plan"),
        key: "working-plan",
        route: ROUTES.WORKING_PLAN,
      },
    ],
    project_and_programs: [
      {
        name: t("past_decadal_programs"),
        key: "part-ii-schemes",
        route: ROUTES.PART_II_SCHEMES,
      },
      {
        name: t("policy_notes"),
        key: "policy-note",
        route: ROUTES.POLICY_NOTE,
      },
      {
        name: t("announcements"),
        key: "announcements",
        route: ROUTES.ANNOUNCEMENTS,
      },
      {
        name: t("citizen_charter"),
        key: "citizen-charter",
        route: ROUTES.CITIZEN_CHARTER,
      },
      {
        name: t("schemes_implementation"),
        key: "schemes-implementation",
        route: ROUTES.SCHEMES_IMPLEMENTATION,
      },
      { name: t("ongoing_programmes"), key: "vision", route: ROUTES.VISION },
    ],
    more_menu: [
      {
        name: t("Paper_Clippings"),
        key: "paper-clippings",
        route: ROUTES.PAPER_CLIPPINGS,
      },
      {
        name: t("Recruitments"),
        key: "recruitment",
        route: ROUTES.RECRUITMENT,
      },
      {
        name: t("Innovation_&_best_Practices"),
        key: "best-practices",
        route: ROUTES.BEST_PRACTICES,
      },
      { name: t("Recent_GO.s"), key: "recent-gos", route: ROUTES.RECENT_G_OS },
    ],
    legislation: [
      { name: t("Policies"), key: "policies", route: ROUTES.POLICIES },
      { name: t("Codes"), key: "codes", route: ROUTES.CODES },
      { name: t("Rules"), key: "rules", route: ROUTES.RULES },
      {
        name: t("notifications"),
        key: "notifications",
        route: ROUTES.NOTIFICATIONS,
      },
      { name: t("Guidelines"), key: "guidelines", route: ROUTES.GUIDELINES },
      { name: t("acts"), key: "acts", route: ROUTES.ACTS },
    ],
    Public_Information: [
      {
        name: t("Important_G.Os"),
        key: "g-os-for-tanii",
        route: ROUTES.G_OS_FOR_TANII,
      },
      {
        name: t("List_Of_Biofertilizer_Centres"),
        key: "list-of-biofertilizer-centres",
        route: ROUTES.LIST_OF_BIOFERTILIZER_CENTRES,
      },
      {
        name: t("List_Of_Central_Nurseries_In_The_State"),
        key: "list-of-central-nurseries-in-the-state",
        route: ROUTES.LIST_OF_CENTRAL_NURSERIES_IN_THE_STATE,
      },
      {
        name: t("Manuals_For_Online_Submissions"),
        key: "manuals-for-online-submissions",
        route: ROUTES.MANUALS_FOR_ONLINE_SUBMISSIONS,
      },
      {
        name: t("Guidelines_For_Sawmills"),
        key: "guidelines-for-sawmills",
        route: ROUTES.GUIDELINES_FOR_SAWMILLS,
      },
      {
        name: t("Departmental_Books"),
        key: "departmental-books",
        route: ROUTES.DEPARTMENTAL_BOOKS,
      },
      // { name: "Forest Fire Alert", route: ROUTES.ACTS },
      // { name: "Sale of Seedings,Seeds etc.", route: ROUTES.SALE_OF_SEEDLINGS },
      {
        name: t("Eco_tourism_Sites"),
        key: "eco-tourism-sites",
        route: ROUTES.ECO_TOURISM_SITES,
      },
      {
        name: t("periodcal_death_audit_report_of_elephants"),
        key: "pdar",
        route: ROUTES.PDAR,
      },
    ],
    Tenders: [{ name: "Tenders", route: ROUTES.TENDERS_ADVERTISEMENTS }],
    About: [
      { name: "present", route: ROUTES.ABOUTPRESENTCONTENT },
      { name: "past", route: ROUTES.ABOUTPASTCONTENT },
    ],
    Past_Decadal_Programs: [
      {
        name: t("part-II_schemes"),
        key: "part-ii-schemes",
        route: ROUTES.PART_II_SCHEMES,
      },
      {
        name: t("coastal_area_management"),
        key: "coastal-area-management",
        route: ROUTES.COASTAL_AREA_MANAGEMENT,
      },
      {
        name: t("green_india_mission_(GIM)"),
        key: "green-india-mission",
        route: ROUTES.GREEN_INDIA_MISSION,
      },
      {
        name: t("hill_area_development_programme"),
        key: "hill-area-development-programme",
        route: ROUTES.HILL_AREA_DEVELOPMENT_PROGRAMME,
      },
      {
        name: t("national_aforestation_programme"),
        key: "national-afforestation-programme",
        route: ROUTES.NATIONAL_AFFORESTATION_PROGRAMME,
      },
      {
        name: t("tank_foreshore_plantations"),
        key: "tank-foreshore-plantations",
        route: ROUTES.TANK_FORESHORE_PLANTATIONS,
      },
      {
        name: t("TAP_(Phase-I)"),
        key: "tap-phase-i",
        route: ROUTES.TAP_PHASE_I,
      },
      {
        name: t("TAP_(Phase-II)"),
        key: "tap-phase-ii",
        route: ROUTES.TAP_PHASE_II,
      },
      {
        name: t("tree_cultivation_in_private_lands_tcpl"),
        key: "tree-cultivation-in-private-lands-tcpl",
        route: ROUTES.TREE_CULTIVATION_IN_PRIVATE_LANDS_TCPL,
      },
      {
        name: t("western_ghats_development_programme-(WDGP)"),
        key: "western-ghats-development-programme-wdgp",
        route: ROUTES.WESTERN_GHATS_DEVELOPMENT_PROGRAMME_WDGP,
      },
      {
        name: t("five_year_plans"),
        key: "five-year-plans",
        route: ROUTES.FIVE_YEAR_PLANS,
      },
    ],
    Ongoing_Programmes: [
      { name: t("vision"), key: "vision", route: ROUTES.VISION },
      { name: t("trust_area"), key: "thrust-area", route: ROUTES.THRUST_AREA },
      {
        name: t("policy_intitiatives"),
        key: "policy-initiatives",
        route: ROUTES.POLICY_INITIATIVES,
      },
      {
        name: t("strategies"),
        key: "strategies",
        route: ROUTES.STRATEGIES,
      },
      {
        name: t("state_schemes"),
        key: "state-schemes",
        route: ROUTES.STATE_SCHEMES,
      },
      {
        name: t("central_schemes"),
        key: "central-schemes",
        route: ROUTES.CENTRAL_SCHEMES,
      },
      {
        name: t("externally_aided_project"),
        key: "externally-aided-project-eap-schemes",
        route: ROUTES.EXTERNALLY_AIDED_PROJECT_EAP_SCHEMES,
      },
      {
        name: t("tribal_development"),
        key: "tribal-development",
        route: ROUTES.TRIBAL_DEVELOPMENT,
      },
    ],
    Important_G_Os: [
      {
        name: t("G.Os FOR TANII"),
        key: "g-os-for-tanii",
        route: ROUTES.G_OS_FOR_TANII,
      },
      {
        name: t("G.Os_for_wildLife_schemes"),
        key: "g-os-for-wildlife-schemes",
        route: ROUTES.G_OS_FOR_WILDLIFE_SCHEMES,
      },
      {
        name: t("G.Os_for_Other_Schemes"),
        key: "g-o-s-for-other-schemes",
        route: ROUTES.G_O_S_FOR_OTHER_SCHEMES,
      },
      {
        name: t("Other_General_G.Os"),
        key: "other-general-g-o-s",
        route: ROUTES.OTHER_GENERAL_G_O_S,
      },
    ],
    Innovation: [
      {
        name: "Innovation Madurai",
        key: "innovation-madurai",
        route: ROUTES.INNOVATION_MADURAI,
      },
      {
        name: "Mudumalai Tiger Reserve",
        key: "innovation-mudumalai-tiger-reserve",
        route: ROUTES.INNOVATION_MUDUMALAI_TIGER_RESERVE,
      },
      {
        name: "Sathyamangalam Tiger Reserve",
        key: "innovation-str",
        route: ROUTES.INNOVATION_STR,
      },
      {
        name: "Innovation Dharmapuri",
        key: "innovation-dharmapuri",
        route: ROUTES.INNOVATION_DHARMAPURI,
      },
      {
        name: "Innovation Tnfusrc",
        key: "innovation-tnfusrc",
        route: ROUTES.INNOVATION_TNFUSRC,
      },
    ],
    Success_Story: [
      {
        name: "Success Story Coimbatore",
        key: "success-story-coimbatore",
        route: ROUTES.SUCCESS_STORY_COIMBATORE,
      },
      {
        name: "Success Story - ATR",
        key: "success-story-atr",
        route: ROUTES.SUCCESS_STORY_ATR,
      },
      {
        name: "Success Story Srivilliputhur",
        key: "success-story-srivilliputhur",
        route: ROUTES.SUCCESS_STORY_SRIVILLIPUTHUR,
      },
      {
        name: "Success Story TNFUSRC",
        key: "success-story-tnfusrc",
        route: ROUTES.SUCCESS_STORY_TNFUSRC,
      },
    ],
    Publications: [
      {
        name: t("Govt_Of_Tamil_Nadu_Publications"),
        key: "govt-of-tamil-nadu-publications",
        route: ROUTES.GOVT_OF_TAMIL_NADU_PUBLICATIONS,
      },
      {
        name: t("International_Publications"),
        key: "international-publications",
        route: ROUTES.INTERNATIONAL_PUBLICATIONS,
      },
      {
        name: t("Govt_Of_India_Publications"),
        key: "govt-of-india-publications",
        route: ROUTES.GOVT_OF_INDIA_PUBLICATIONS,
      },
      {
        name: t("TN_Forest_Dept_Publications"),
        key: "tn-forest-dept-publications",
        route: ROUTES.TN_FOREST_DEPT_PUBLICATIONS,
      },
      {
        name: t("General"),
        key: "general",
        route: ROUTES.GENERAL,
      },
      {
        name: t("Calendar 2024"),
        key: "calendar",
        route: ROUTES.CALENDAR,
      },
    ],
    Plan_budget: [
      {
        name: t("shared_schemes"),
        key: "shared-schemes",
        route: ROUTES.SHARED_SCHEMES,
      },
      {
        name: t("externally_aided_project"),
        key: "externally-aided-project",
        route: ROUTES.EXTERNALLY_AIDED_PROJECT,
      },
      {
        name: t("state_schemes"),
        key: "state-schemes",
        route: ROUTES.STATE_SCHEMES,
      },
    ],
  };
  let menu_height_switch_object = {
    organization: "500px",
    project_and_programs: "330px",
    more_menu: "300px",
    legislation: "300px",
    Public_Information: "500px",
    Past_Decadal_Programs: "580px",
    Ongoing_Programmes: "390px",
    Important_G_Os: "230px",
  };
  let menu_width_switch_object = {
    Publications: "60%",
    Public_Information: "70%",
    organization: "60%",
    project_and_programs: "60%",
    Past_Decadal_Programs: "60%",
    Important_G_Os: "60%",
    more_menu: "auto",
  };
  console.log("selected_index", selected_index);
  const RenderSecondaryMenu = () => {
    let menu = menu_header_switch_object[type].map((item, index) => {
      return (
        <SecondarySiderMenuItem
          item={item}
          index={index}
          selected_index={selected_index}
          handleSelectedIndex={handleSelectedIndex}
        />
      );
    });
    return menu;
  };

  return (
    <Box w="50%" h="11%" bg="#f9faf2" width={menu_width_switch_object[type]}>
      <Box h="100%">
        <img
          src={MenuSiderImage}
          alt="Menu Image"
          height={menu_height_switch_object[type]}
          className="secondary_sider_menu"
        />
        <VStack position={"absolute"} width="100%" h="100%">
          <Box p="3">
            <Text fontSize="20px" fontWeight="bolder" color={"#3c5649"}>
              {t(startCase(type))}
            </Text>
          </Box>
          <Center>
            <Divider width={"90%"} bg={"#d1e1c7"} />
          </Center>
          {RenderSecondaryMenu()}
        </VStack>
      </Box>
    </Box>
  );
};

export default SecondarySiderMenu;
