import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import EleventhFiveYearPlanTable from "./components/eleventh_five_year_plan";
import TwelvethFiveYearPlanTable from "./components/twelveth_five_year_plan";
import TenthFiveYearPlanTable from "./components/tenth_five_year_plan";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";

const FiveYearPlans = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection
            imageUrl={ForestImageCover}
            title={t("five_year_plans")}
          />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%","100%","30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type={"Past_Decadal_Programs"} />
                </Box>
              </VStack>
            </Box>
            <Box w={["100%","100%","65%"]} h="100%">
            <Box>
                <Text
                  style={{
                    fontFamily: "poppins",
                    fontSize: "20px",
                    marginBottom: "-18px",
                    fontWeight: 500,
                  }}
                >
                  Twelveth Five Year Plan
                  <hr />
                </Text>
                <TwelvethFiveYearPlanTable />
              </Box>
              <br />
              <Box>
                <Text
                  style={{
                    fontFamily: "poppins",
                    fontSize: "20px",
                    marginBottom: "-18px",
                    fontWeight: 500,
                  }}
                >
                  Eleventh FiveYear Plan
                  <hr />
                </Text>
                <EleventhFiveYearPlanTable />
              </Box>
              <br />
              
              <Box>
                <Text
                  style={{
                    fontFamily: "poppins",
                    fontSize: "20px",
                    marginBottom: "-18px",
                    fontWeight: 500,
                  }}
                >
                  Tenth Five Year Plan
                  <hr />
                </Text>
                <TenthFiveYearPlanTable />
              </Box>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default FiveYearPlans;
