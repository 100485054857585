import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  Image,
} from "native-base";
import { useTranslation } from "react-i18next";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import VisionFirstCard from "./components/vision_first_card";
import VisionHTML from "./components/vision";
import VisionSecondCard from "./components/vision_second_card";
import VisionTwoHTML from "./components/vision_two";

const Vision = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection imageUrl={ForestImageCover} title={"Vision"} />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%","100%","30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="Ongoing_Programmes" />
                </Box>
              </VStack>
            </Box>
            <Box w={["100%","100%","65%"]} h="100%">
              <Box>
                <VisionFirstCard />
              </Box>
              <Box>
                <VisionHTML />
              </Box>
              <Box>
                <VisionSecondCard />
              </Box>
              <Box>
                <VisionTwoHTML />
              </Box>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default Vision;
