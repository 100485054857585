import { getsystemCMSValueListReducer } from "./system_cms_value_list";
import { createsystemCMSValueReducer } from "./system_cms_value_create";
import { deletesystemCMSValueReducer } from "./system_cms_value_delete";
import { updatesystemCMSValueReducer } from "./system_cms_value_update";
import { getsystemCMSValueReducer } from "./system_cms_value_getOne";
export const systemCMSValueReducers = {
  systemCMSValueList: getsystemCMSValueListReducer,
  systemCMSValueCreate: createsystemCMSValueReducer,
  systemCMSValueDelete: deletesystemCMSValueReducer,
  systemCMSValueUpdate: updatesystemCMSValueReducer,
  systemCMSValueGet: getsystemCMSValueReducer,
};

export * from "./system_cms_value_list";
export * from "./system_cms_value_create";
export * from "./system_cms_value_delete";
export * from "./system_cms_value_update";
export * from "./system_cms_value_getOne";
