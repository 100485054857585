import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import OnlineServiceCard from "./component/online_service_card";
import OnlineServiceCardOne from "./component/online_service_card_one";

const OnlineService = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection imageUrl={ForestImageCover} title={"Online Service"} />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w="20%" h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center"></Box>
              </VStack>
            </Box>
            <Box w={["100%", "100%", "65%"]} h="100%">
              <Box>
                <OnlineServiceCard />
              </Box>
            </Box>
          </HStack>
          <HStack
            // marginTop="20px"
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w="20%" h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center"></Box>
              </VStack>
            </Box>
            <Box w={["100%", "100%", "65%"]} h="100%">
              <Box>
                <OnlineServiceCardOne />
              </Box>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default OnlineService;
