import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { login_query } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    item: null,
    authorize: false,
    is_profile_completed: false,
    roles: [],
};

const slice = createSlice({
    name: "login",
    initialState,
    reducers: {
        _login: (state) => {
            state.loading = true;
            state.item = null;
            state.error = null;
            state.authorize = false;
            state.roles = [];
        },
        _login_authorize: (state) => {
            state.loading = false;
            state.item = null;
            state.error = null;
            state.authorize = false;
            state.roles = [];
        },
        _login_success: (state, { payload }) => {
            state.loading = false;
            state.authorize = true;
            state.item = payload;
            state.roles = payload?.roles;
            localStorage.setItem("roles", JSON.stringify(payload?.roles));

            localStorage.setItem("user", JSON.stringify(payload));
            localStorage.setItem("token", payload?.token);
            state.error = null;
        },
        _login_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
            state.authorize = false;
            state.item = null;
            state.roles = [];
        },
    },
});

const { _login, _login_success, _login_authorize, _login_failure } =
    slice.actions;

export const loginSelector = (state) => state.login;

export const loginReducer = slice.reducer;

export function login(variables) {
    return async (dispatch) => {
        dispatch(_login());
        try {
            const response = await QueryRequest(
                login_query,
                variables,
                dispatch
            );
            if (
                response?.data?.login &&
                !response?.data?.login.error &&
                response?.data?.login?.token
            ) {
                dispatch(_login_success(response?.data?.login));
            } else if (response?.data?.login?.error) {
                dispatch(_login_failure(response?.data?.login.error));
            }
        } catch (error) {
            dispatch(_login_failure(error));
        }
    };
}

export function setAuthorize() {
    return async (dispatch) => {
        dispatch(_login_authorize());
    };
}
export function set_login_reset() {
    return (dispatch) => {
        dispatch(_login_authorize);
    };
}
