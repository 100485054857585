import React from "react";
// import './index.css';
import { Card } from "antd";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";

const AntdCard = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <HStack space={10}>
        <Pressable>
          <Box>
            <Card style={{ width: 300, backgroundColor: "#C7DFAA" }}>
              <p>{t("tamil_nadu")}</p>
              <p>{t("biodiversity_board")}</p>
            </Card>
          </Box>
        </Pressable>
        <Pressable>
          <Box>
            <Card style={{ width: 300, backgroundColor: "#C7DFAA" }}>
              <p>{t("green_tamilnadu")}</p>
              <p>{t("mission")}</p>
            </Card>
          </Box>
        </Pressable>
      </HStack>
      <HStack space={10} mt={10}>
        <Pressable>
          <Box>
            <Card style={{ width: 300, backgroundColor: "#C7DFAA" }}>
              <p>{t("tamil_nadu")} </p>
              <p>{t("wetlands_mission")}</p>
            </Card>
          </Box>
        </Pressable>
        <Pressable>
          <Box>
            <Card style={{ width: 300, backgroundColor: "#C7DFAA" }}>
              <p>{t("tamil_nadu")}</p>
              <p>{t("climate_change_mission")} </p>
            </Card>
          </Box>
        </Pressable>
      </HStack>
    </Box>
  );
};

export default AntdCard;
