import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";
import secondcard from "@assets/images/menu/secondcard.png";

const VisionSecondCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Box
      // width="92%"
      // style={{
      //   boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px",
      // }}
      >
        <Box justifyContent="center" flex={1}
        marginLeft={["25px","25px","0px"]}>
          <img src={secondcard} alt="forest" width="92%" />
        </Box>
        {/* <Box
          style={{
            width: "90%",
            marginTop: "20px",
            borderRadius: "20px",
            background: "#E7F6DB",
            height: "300px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px",
            justifyContent: "center",
          }}
        >
          <>
            <HStack>
              <Box flex={1} justifyContent="center" padding="30px">
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  According to <br />
                  India State of <br />
                  Forest Report,
                  <br /> 2019
                </p>
              </Box>
              <Box flex={1} alignSelf="center">
                <p>
                  <span
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    26,364
                  </span>
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    sq. km
                  </span>
                  <br />
                  of total forest cover in <br /> the State
                </p>
              </Box>
              <Box flex={1} alignSelf="center">
                <p>
                  <span
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    20.27%
                  </span>
                  <br /> of the State’s Geographical,
                  <br /> area under forest cover
                </p>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1} justifyContent="center" padding="30px">
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  According to <br />
                  India State of <br />
                  Forest Report,
                  <br /> 2019
                </p>
              </Box>
              <Box flex={1} alignSelf="center">
                <p>
                  <span
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    26,364
                  </span>
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    sq. km
                  </span>
                  <br />
                  of total forest cover in <br /> the State
                </p>
              </Box>
              <Box flex={1} alignSelf="center">
                <p>
                  <span
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    20.27%
                  </span>
                  <br /> of the State’s Geographical,
                  <br /> area under forest cover
                </p>
              </Box>
            </HStack>
          </>
        </Box> */}
      </Box>
    </>
  );
};
export default VisionSecondCard;
