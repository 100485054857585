import { Card } from "antd";
import { startCase } from "lodash";
import {
  AspectRatio,
  Box,
  Center,
  HStack,
  Image,
  Text,
  VStack,
} from "native-base";
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { borderRadius } from "styled-system";

const ProtectedAreaCms = (props) => {
  const { groupValues, cms_key } = props;
  useEffect(() => {}, [cms_key]);

  return (
    <div style={{ position: "relative" }}>
      {groupValues?.[cms_key]?.["items"]?.map((item, index) => {
        return (
          <HStack>
            <VStack width="90%">
              <Text fontSize={20} bold marginBottom={5}>
                {startCase(item.protected_area_name)}
              </Text>
              <Card
                style={{
                  backgroundColor: "#fffff8",
                  borderRadius: "10px",
                }}
              >
                <VStack>
                  <HStack>
                    <Box width="30%">
                      <Text marginBottom={5}>Location</Text>
                    </Box>
                    <Box width="70%">
                      <Text marginBottom={5}>
                        {item.location ? item.location : "-"}
                      </Text>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box width="30%">
                      <Text marginBottom={5}>Area</Text>
                    </Box>
                    <Box width="70%">
                      <Text marginBottom={5}>
                        {item.area ? item.area : "-"}
                      </Text>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box width="30%">
                      <Text marginBottom={5}>Forest Types</Text>
                    </Box>
                    <Box width="70%">
                      <Text marginBottom={5}>
                        {item.forest_types ? item.forest_types : "-"}
                      </Text>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box width="30%">
                      <Text marginBottom={5}>Animals Found</Text>
                    </Box>
                    <Box width="70%">
                      <Text marginBottom={5}>
                        {item.avifauna_found ? item.avifauna_found : "-"}
                      </Text>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box width="30%">
                      <Text marginBottom={5}>Nearest Airport</Text>
                    </Box>
                    <Box width="70%">
                      <Text marginBottom={5}>
                        {item.nearest_airport ? item.nearest_airport : "-"}
                      </Text>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box width="30%">
                      <Text marginBottom={5}>Nearest Railway Station</Text>
                    </Box>
                    <Box width="70%">
                      <Text marginBottom={5}>
                        {item.nearest_railway_station
                          ? item.nearest_railway_station
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box width="30%">
                      <Text marginBottom={5}>Best Season</Text>
                    </Box>
                    <Box width="70%">
                      <Text marginBottom={5}>
                        {item.best_season ? item.best_season : "-"}
                      </Text>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box width="30%">
                      <Text marginBottom={5}>Accommodation</Text>
                    </Box>
                    <Box width="70%">
                      <Text marginBottom={5}>
                        {item.Accommodation ? item.Accommodation : "-"}
                      </Text>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box width="30%">
                      <Text marginBottom={5}>Other Facilities</Text>
                    </Box>
                    <Box width="70%">
                      <Text marginBottom={5}>
                        {item.other_facilities ? item.other_facilities : "-"}
                      </Text>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box width="30%">
                      <Text marginBottom={5}>For Greater Details</Text>
                    </Box>
                    <Box width="70%">
                      <Text marginBottom={5}>
                        {item.for_greater_details
                          ? item.for_greater_details
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                </VStack>
              </Card>
            </VStack>
            <VStack>
              <Box marginLeft="20px" marginTop="50px">
                <img
                  src={item.image}
                  alt={item.protected_area_name}
                />
              </Box>
            </VStack>
          </HStack>
        );
      })}
    </div>
  );
};
export default ProtectedAreaCms;
