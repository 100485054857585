import { Collapse } from "antd";
import React from "react";
import CampaExecutiveCommitteeHTML from "@views/pages/campa/components/campa_executive_committee";
import CampaGoverningBodyHTML from "@views/pages/campa/components/campa_governing_body";
import CampaSteeringCommitteeHTML from "@views/pages/campa/components/campa_steering_committee";
import CampaStateAdvisoryGroupsSaGsHTML from  "@views/pages/campa/components/campa_state_advisory_groups_sa_gs";
import Campa5ComponentsOfStateCampaHTML from "@views/pages/campa/components/campa_5_components_of_state_campa";
import CampaFundingHTML from "@views/pages/campa/components/campa_funding";
const { Panel } = Collapse;
const CAMPACollapsible = () => {
  return (
    <Collapse defaultActiveKey={["1"]} expandIconPosition={"end"} ghost>
      <Panel header="State Advisory Groups(SAGs)" key="1">
        <div>{<CampaStateAdvisoryGroupsSaGsHTML/>}</div>
      </Panel>
      <Panel header="Governing Body" key="2">
        <div>{<CampaGoverningBodyHTML/>}</div>
      </Panel>
      <Panel header="Steering Committee" key="3">
        <div>{<CampaSteeringCommitteeHTML />}</div>
      </Panel>
      <Panel header="Executive Committee" key="4">
        <div>{<CampaExecutiveCommitteeHTML />}</div>
      </Panel>
      <Panel header="5 components of state CAMPA" key="5">
        <div>{<Campa5ComponentsOfStateCampaHTML/>}</div>
      </Panel>
      <Panel header="CAMPA funding" key="6">
        <div>{<CampaFundingHTML/>}</div>
      </Panel>
    </Collapse>
  );
};
export default CAMPACollapsible;
