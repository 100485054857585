import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";

import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";

import { useTranslation } from "react-i18next";
import SocialForestryHTML from "./components/social_forestry_html/social_forestry";
import SocialForestryDetailsOfForestryExtensionCentresInTamilNaduHTML from "./components/social_forestry_html/social_forestry_details_of_forestry_extension_centres_in_tamil_nadu";
import SocialForestryDemonstrationPlotsInFarmersLandHTML from "./components/social_forestry_html/social_forestry_demonstration_plots_in_farmers_land";
import Header_section from "@views/components/ui/header_section";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import SocialForesytryCollapsible from "./components/social_forestry_collapsible/social_forestry_collapsible";
import ExtensionHTML from "./components/social_forestry_html/extension";

const SocialForestry = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <Header_section
            imageUrl={ForestImageCover}
            title={t("Social_forestry_extension")}
          />
        </Box>
        <Box my="10"  overflowX={"hidden"}>
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="organization" />
                </Box>
              </VStack>
            </Box>
            <Box>
              <Box w={["100%", "100%", "65%"]} >
                <SocialForesytryCollapsible />
                <SocialForestryHTML />
                <ExtensionHTML />
                <SocialForestryDetailsOfForestryExtensionCentresInTamilNaduHTML />
                <SocialForestryDemonstrationPlotsInFarmersLandHTML />
              </Box>
              {/* <Box w={["100%", "100%", "20%"]}>
                <SocialForestryHTML />
              </Box> */}
              {/* <Box w={["100%", "100%", "55%"]}><ExtensionHTML /></Box> */}

              {/* <Box w={["100%", "100%", "60%"]}>
                <SocialForestryDetailsOfForestryExtensionCentresInTamilNaduHTML />
              </Box> */}
              {/* <Box w={["100%", "100%", "60%"]}>
                <SocialForestryDemonstrationPlotsInFarmersLandHTML />
              </Box> */}
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default SocialForestry;
