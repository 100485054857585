import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import SuccessStoryAtrHTML from "./components/success_story_atr";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";

const SuccessStoryAtr = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection
            imageUrl={ForestImageCover}
            title={"Success Story ATR"}
          />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="110%" alignItems="center">
                  <SecondarySiderMenu type="Success_Story" />
                </Box>
              </VStack>
            </Box>
            <Box
              w={["100%", "100%", "65%"]}
              h="100%"
              mt={["10px", "10px", "0px"]}
            >
              <SuccessStoryAtrHTML />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default SuccessStoryAtr;
