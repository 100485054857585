import { gql } from "@apollo/client";

export const get_cms_query = gql`
    query getSystemCmss {
        getSystemCmss {
            items {
                id
                key
                type
                system_cms_names {
                    name
                    system_cms_id
                    system_cms_language_id
                }
                system_cms_fields {
                    id
                    key
                    type
                    system_cms_field_names {
                        id
                        name
                        system_cms_language {
                            id
                            key
                        }
                        system_cms_language_id
                    }
                    system_cms_field_options {
                        id
                        key
                        system_cms_field_option_values {
                            id
                            value
                            system_cms_language_id
                        }
                    }
                }
            }
        }
    }
`;

export const get_one_cms_query = gql`
    query getSystemCms($json: get_system_cms_input) {
        getSystemCms(json: $json) {
            id
            key
            type
            system_cms_names {
                name
                system_cms_id
                system_cms_language_id
            }
            system_cms_fields {
                id
                key
                type
                system_cms_field_names {
                    id
                    name
                    system_cms_language {
                        id
                        key
                    }
                    system_cms_language_id
                }
                system_cms_field_options {
                    id
                    key
                    system_cms_field_option_values {
                        id
                        value
                        system_cms_language_id
                    }
                }
            }
        }
    }
`;

export const deletecmsMutation = gql`
    mutation deleteCMS($id: String!) {
        deleteSystemCms(id: $id) {
            id
            status
            error {
                status_code
                message
            }
        }
    }
`;

export const createcmsMutation = gql`
    mutation createCMS($data: system_cms_input) {
        createSystemCms(data: $data) {
            id
            status
            error {
                status_code
                message
            }
        }
    }
`;

export const updatecmsMutation = gql`
    mutation updateCMS($data: system_cms_input, $id: String!) {
        updateSystemCms(data: $data, id: $id) {
            id
            status
            error {
                status_code
                message
            }
        }
    }
`;

export const fileQuery = gql`
  query getUploadUrl($type: String, $fileName: String) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        message
      }
    }
  }
`;