import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IoNotificationsOutline } from "react-icons/io5";
import { ROUTES } from "@views/routes/my_routes";
const RecruitmentFirstPart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const movepage = () => {
    history.push({
      pathname: `${ROUTES.NOTIFICATIONS}`,
    });
  };
  return (
    <Center>
      <Box>
        <HStack
          space="20"
          flexDirection={{
            base: "column",
            md: "row",
          }}
        >
          <Box width={["100%", "100%", "65%"]}>
            <VStack space="10">
              <Box>
                <Text fontSize="20px">
                  <strong>
                    TAMIL NADU FOREST UNIFORMED SERVICES
                    <br /> RECRUITMENT COMMITTEE (TNFUSRC)
                  </strong>
                </Text>
              </Box>
              <Box>
                <Text
                  textAlign={"justify"}
                  padding={["20px", "20px", "0px"]}
                  marginTop={["-30px", "-30px", "0px"]}
                >
                  ESTABLISHMENT OF TNFUSRC: In G.O.(Ms.) No. 157 E&amp;F (FR-2)
                  Department, dated 29.6.2012 orders were issued for
                  constituting TNFUSRC to recruit Forester, Forest Guard, Forest
                  Guard with Driving Licence and Forest Watcher in the Forest
                  Department / ARC / TANTEA / TAFCORN by Direct Recruitment. The
                  recruitment procedures / modalities were issued in
                  G.O.Ms.No.187, Environment &amp; Forests (FR-2) Department,
                  dated 19.11.2013.
                  <br />
                  <br />
                  In the first instance, 181 candidates have been provisionally
                  selected for the posts of Forester / Field Assistant in Forest
                  Department, ARC and TAFCORN were filled up.
                </Text>
              </Box>
            </VStack>
          </Box>
          <Pressable onPress={movepage}>
            <Box
              width={["100%", "100%", "35%"]}
              alignSelf={"center"}
              marginLeft={["140px", "140", "0px"]}
              marginRight={["0px", "0", "500px"]}
            >
              <Box
                style={{
                  borderRadius: "10px",
                  background: "green",
                  height: "130px",
                  width: "240px",
                  justifyContent: "center",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
                }}
              >
                <Box alignSelf={"center"}>
                  <IoNotificationsOutline color="white" size="50" />
                </Box>

                <Text
                  textAlign="center"
                  color="white"
                  fontSize={"16px"}
                  alignSelf="center"
                  fontWeight={"600"}
                >
                  Recuirments and <br /> Notifications
                </Text>
              </Box>
            </Box>
          </Pressable>
        </HStack>
      </Box>
    </Center>
  );
};
export default RecruitmentFirstPart;
