import { Box, Button, Text, VStack } from "native-base";
import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const AdministrationLinkItem = (props) => {
  const { item, index } = props;
  const contentStyle = {
    height: "230px",
    color: "#283619",
    lineHeight: "160px",
    textAlign: "center",
    background: "#eef5d8",
    borderRadius: "10px",
  };
  const handlePdf = (item) => {
    var a = document.createElement("a");
    var filePath = item;
    a.href = filePath;
    a.target = "_blank";
    a.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <Box style={contentStyle} key={index} justifyContent="center">
      <VStack space={3}>
        <Box px={10}>
          <Text>{item.administration_panel_link.value}</Text>
        </Box>
        <Box w="100%" alignItems={"center"}>
          <Button
            variant="outline"
            width="120px"
            borderColor="#6bb384"
            endIcon={<MdOutlineKeyboardArrowRight />}
            _hover={{
              borderColor: "#6bb384",
              bg: "#026608",
              _text: {
                color: "#ffffff",
              },
            }}
            _light={{
              _text: {
                color: "#026608",
              },
            }}
            onPress={() => {
              handlePdf(item.link.value);
            }}
          >
            {"View More"}
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};
export default AdministrationLinkItem;
