import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import HTMLRenderer from "@views/components/ui/html_renderer";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@views/routes/my_routes";
import { useStorageItem } from "@helpers/storage";
import { AiFillEdit } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import {
  getSystemCMSFieldUserListSelector,
  get_system_cms_user_field_request,
} from "@services/redux";

const SaleOfSeedlingsTable = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Photo, roles, name } = useStorageItem("user");
  const { system_cms_id } = useSelector(getSystemCMSFieldUserListSelector);
  const history = useHistory();
  let current_language_id = localStorage.getItem("current_language_id");
  const language = JSON.parse(localStorage?.getItem("current_language"))?.id;
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({});
  const [loading, setLoading] = useState(false);
  const { items } = useSelector(getSystemCMSFieldUserListSelector);
  const columns = [
    {
      header: t("table:circle"),
      dataIndex: "circle.value",
      key: "circles",
      align: "left",
      type: "label",
      fleX: 1,
    },
    {
      header: t("table:name_of_the_forestry_extension_centre"),
      dataIndex: "name_of_the_forestry_extension_centre.value",
      key: "name_of_the_forestry_extension_centre",
      align: "left",
      type: "label",
      fleX: 1,
    },
    {
      header: t("table:high_value_timber_species"),
      dataIndex: "high_value_timber_species.value",
      key: "high_value_timber_species",
      align: "left",
      type: "label",
      fleX: 1,
    },
    {
      header: t("table:medicinal_plants"),
      dataIndex: "medicinal_plants.value",
      key: "medicinal_plants",
      align: "left",
      type: "label",
      fleX: 1,
    },
    {
      header: t("table:total"),
      dataIndex: "total.value",
      key: "total",
      align: "left",
      type: "label",
      fleX: 1,
    },
    {
      header: t("table:pdf"),
      dataIndex: "pdf.value",
      key: "pdf",
      align: "left",
      type: "label",
      fleX: 1,
    },
  ];
  function filterItemsWithMaxSystemCmsValue(items) {
    const keyToMaxObj = {};

    items.forEach((obj) => {
      const { key, system_cms_values } = obj;

      if (
        !keyToMaxObj[key] ||
        system_cms_values.length > keyToMaxObj[key].system_cms_values.length
      ) {
        keyToMaxObj[key] = obj;
      }
    });

    return Object.values(keyToMaxObj);
  }
  useEffect(() => {
    if (items.length) {
      let cms = items.find((e) => e.key === "sale_of_seedlings");
      if (cms?.field_items?.length) {
        let items = cms.field_items;
        items = filterItemsWithMaxSystemCmsValue(items);
        let filtered_items = items.map((item) => {
          return {
            ...item,

            system_cms_values: item.system_cms_values?.filter(
              (field_item_value) =>
                field_item_value.system_cms_language_id === language
            ),
          };
        });
        let highest_value = 0;
        let object_keys = items.map((field_item) => {
          highest_value =
            field_item.system_cms_values?.length > highest_value
              ? field_item.system_cms_values?.length
              : highest_value;
          return field_item.key;
        });
        let final_list = [];
        for (let index = 0; index < highest_value; index++) {
          let object = {};
          for (let key in object_keys) {
            let value = items
              .find((filter_item) => filter_item.key === object_keys[key])
              .system_cms_values.find(
                (find_item) => find_item.row_number === index
              );
            object[object_keys[key]] = value;
          }
          final_list.push(object);
        }
        setData(final_list);
      }
      if (cms?.loading) setLoading(cms.loading);
    }
  }, [items]);
  useEffect(() => {
    setQuery({
      json: {
        system_cms_key: "sale_of_seedlings",
      },
    });
  }, []);
  return (
    <>
      <Box>
        {name ? (
          <Pressable
            _hover={{
              borderColor: "red.500",
              borderWidth: "6px",
            }}
          >
            <Box
              alignItems={"flex-end"}
              onClick={(e) => {
                history.push({
                  pathname: `${ROUTES.SYSTEM_CMS_VALUE}/${system_cms_id}`,
                  state: { cms: props.cmsKey },
                });
              }}
            >
              <AiFillEdit size={30} color="#409d06" />
            </Box>
            <Wrapper>
              <Table
                width="100%"
                rowKey="id"
                group="sale_of_seedlings"
                loading={loading}
                data={data}
                columns={columns}
                hasPagination={false}
                totalItems={null}
                fetch={get_system_cms_user_field_request}
                query={query}
                headerActions={[]}
                emptyMessage={t("table:empty_page")}
                headerColor={"white"}
              />
            </Wrapper>
          </Pressable>
        ) : (
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="sale_of_seedlings"
              loading={loading}
              data={data}
              columns={columns}
              hasPagination={false}
              totalItems={null}
              fetch={get_system_cms_user_field_request}
              query={query}
              headerActions={[]}
              emptyMessage={t("table:empty_page")}
              headerColor={"white"}
            />
          </Wrapper>
        )}
      </Box>
    </>
  );
};
export default SaleOfSeedlingsTable;
